import { Box } from '@mui/material';
import React from 'react';
import AddContent from './AddContent';
import AddHeader from './AddHeader';

type Props = {};

const AddEmployees = (props: Props) => {
  return (
    <Box style={style.container}>
      <AddHeader />
      <AddContent />
    </Box>
  );
};
const style = {
  container: {
    marginTop: '20px',
  },
};
export default AddEmployees;
