import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEY, getItem, removeItem } from 'utils/localStorage';
import toastConfig from 'utils/toastConfig';
import { FORBIDDEN } from '../../constants/constants.message';
import { PATH } from '../../constants/path';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: { 'X-Custom-Header': 'foobar' },
});

instance.interceptors.request.use((request) => {
  const accessToken = getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  const accessHeader = `Bearer ${accessToken}`;
  request.headers!.Authorization = accessHeader;
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      const urlResetPassword = '/users/reset_password';
      if (err.response.status === 401 && originalConfig.url !== urlResetPassword) {
        window.location.assign(PATH.SESSION_EXPIRED);
        return Promise.reject(err);
      }
      if ([403].includes(err?.response?.status) && !originalConfig._retry) {
        originalConfig._retry = true;
        return toastConfig.toastError(FORBIDDEN);
      }
    }
    return Promise.reject(err);
  },
);
