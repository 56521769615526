export const style = {
  boxGrid: { flexGrow: 1, marginLeft: '15px' },
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  grid: {
    width: '47%',
  },
  startDate: { width: '45%' },
  endDate: { width: '45%' },
  iconClear: { width: 20, height: 20 },
};
