import moment from 'moment';
import { baseCalender } from 'types/formProjectAndUserType';
import ChipBase, { ChipColorsType, KeyColors } from '../../../components/chip';
import { Positions, ProjectStatusEnum, StatusEmployeeEnum } from 'constants/constants.interfaces';
import '../../formProjectAndUser/formItem/style.scss';

export const titleProject = ['Project', 'Project Manager', 'Start Date', 'Status', 'Action'];

export const titleUser = [
  '',
  'Full Name',
  'Email',
  'Phone',
  'Join Date',
  'Position',
  'Status',
  'Action',
];

export const momentDate = (data: baseCalender) => {
  const { date, num, type } = data;

  return moment(new Date(date)).subtract(num, type);
};

type ChipStatus = {
  type: string;
};

export const CustomChipByStatus = (props: ChipStatus) => {
  let color: ChipColorsType;
  switch (props.type) {
    case Positions.HR:
      color = KeyColors.errors;
      break;
    case Positions.FE:
      color = KeyColors.success;
      break;
    case Positions.BE:
      color = KeyColors.pending;
      break;
    case Positions.PM:
      color = KeyColors.warning;
      break;
    case Positions.TESTER:
      color = KeyColors.info;
      break;
    case Positions.ADMIN:
      color = KeyColors.default;
      break;
    case ProjectStatusEnum.DONE:
      color = KeyColors.success;
      break;
    case ProjectStatusEnum.RUNNING:
      color = KeyColors.pending;
      break;
    case ProjectStatusEnum.INCOMING:
      color = KeyColors.warning;
      break;
    case StatusEmployeeEnum.ACTIVE:
      color = KeyColors.success;
      break;
    case StatusEmployeeEnum.INACTIVE:
      color = KeyColors.errors;
      break;
    case StatusEmployeeEnum.DEACTIVATED:
      color = KeyColors.errors;
      break;

    default:
      color = KeyColors.warning;
      break;
  }
  return (
    <ChipBase
      label={`${props.type}`}
      variant="filled"
      customcolor={color}
      className="style-border-chip"
    />
  );
};
