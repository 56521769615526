import { TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { formatDDMMYYYY } from '../../constants/constants.timeFormat';
import './index.scss';

interface IControllerProps {
  name: string;
  rules?: ControllerProps['rules'];
  disableFuture?: boolean;
  defaultDate?: Date;
  isBlurWeekend?: boolean;
}

type IOnchangeProps = {
  (value: Date | null, keyboardInputValue?: string): void;
};
type MobileDatePickerType = Partial<
  Pick<MobileDatePickerProps<string, Date>, 'minDate' | 'maxDate'>
>;

const disableWeekends = (date: Date): boolean => {
  const dateTime = new Date(date.valueOf());
  return !dateTime.getDay() || dateTime.getDay() === 6;
};
export const ControllerDateField = ({
  name,
  rules,
  disableFuture,
  defaultDate,
  isBlurWeekend = false,
  ...textFieldProps
}: IControllerProps & TextFieldProps & MobileDatePickerType) => {
  const methods = useFormContext();
  const { control } = methods;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultDate}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
          const { minDate, maxDate, disabled, onChange: onChangeProps } = textFieldProps;
          return (
            <MobileDatePicker
              {...{
                value: value ?? '',
                disableFuture,
                onChange: (onChangeProps as unknown as IOnchangeProps) ?? onChange,
                minDate: minDate,
                maxDate: maxDate,
                disabled: disabled,
              }}
              showToolbar={false}
              inputFormat={formatDDMMYYYY}
              shouldDisableDate={(data) => isBlurWeekend && disableWeekends(data)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...{ ...params, ...textFieldProps, error: !!error }}
                  value={value}
                  variant="outlined"
                  className="date"
                  helperText={error ? error.message : null}
                />
              )}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default ControllerDateField;
