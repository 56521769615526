import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/store';
import './style.scss';
type Props = {};

const HeaderProject = (props: Props) => {
  const { id } = useParams();
  const { projectName } = useAppSelector((state) => state.projectReducer.projectDetail);
  return (
    <div>
      <Typography variant="h2" component="h2" className="style-title-h2">
        {id ? <>{projectName}</> : 'Add Project'}
      </Typography>
    </div>
  );
};

export default HeaderProject;
