import { TextField, TextFieldProps } from '@mui/material';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import './index.scss';

interface IControllerProps {
  name: string;
  rules?: ControllerProps['rules'];
}
export const ControllerTextFied = ({
  name,
  rules,
  ...textFieldProps
}: IControllerProps & TextFieldProps) => {
  const methods = useFormContext();
  const { control } = methods;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <TextField
          className="inputTextfield"
          size="small"
          {...{ value: value ?? '', onChange, onBlur, error: !!error, ...textFieldProps }}
          variant="outlined"
          helperText={error ? error.message : null}
        />
      )}
    />
  );
};

export default ControllerTextFied;
