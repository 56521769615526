import { TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../../constants';
import { CustomChipByStatus } from '../constant/constant';
import { Link } from 'react-router-dom';
import icons from 'constants/icons/index';
import { IFormProjectItemProps } from '../interface';
import './style.scss';

type DataFromType = IFormProjectItemProps & {
  callback: () => void;
};

const FormProjectItem = (props: DataFromType) => {
  const { name, contact, date, level, idProject } = props;
  const { Visibility } = icons;

  const viewProjectRouter = `/project/view/${idProject}`;

  return (
    <TableRow className="project-row-item">
      <TableCell className="user-cell-item">{name}</TableCell>
      <TableCell className="user-cell-item">{contact}</TableCell>
      <TableCell className="user-cell-item">{formatDate(date)}</TableCell>
      <TableCell className="user-cell-item">
        <CustomChipByStatus type={String(level)} />
      </TableCell>
      <TableCell className="user-cell-item" style={{ paddingLeft: '28px' }}>
        <Link className="stylelinkView" to={viewProjectRouter}>
          <Visibility className="view-icon" />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default FormProjectItem;
