import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}

const AddProjectIcons = (props: StyleProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M2.08386 16V2H9.08386V9H16.0839V10C16.7839 10 17.4539 10.13 18.0839 10.35V6L12.0839 0H2.08386C0.973862 0 0.0838623 0.89 0.0838623 2V16C0.0838623 17.1 0.973862 18 2.08386 18H10.4339C10.2139 17.37 10.0839 16.7 10.0839 16H2.08386ZM11.0839 1.5L16.5839 7H11.0839V1.5ZM20.0839 15V17H17.0839V20H15.0839V17H12.0839V15H15.0839V12H17.0839V15H20.0839Z"
        fill="white"
      />
    </svg>
  );
};

export default AddProjectIcons;
