import icons from 'constants/icons';
import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Positions } from '../../../constants/constants.interfaces';
import RouteGuard from '../../../commons/RouteGuard';
import BasePopup from '../../../components/popup';
import LeaveForm from '../../Dayoff/LeaveForm';
import { ILeaveRequestResponseData } from '../../../types/dayOffType';

interface ButtonProps {
  item: ILeaveRequestResponseData;
  handleApproveOrReject: (ids: string[], isApproval: boolean) => void;
}

const Buttons = ({ handleApproveOrReject, item }: ButtonProps) => {
  const { CheckCircleOutline, HighlightOff, Visibility } = icons;
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<ILeaveRequestResponseData>();

  const handleCancel = () => {
    setOpenPopup(false);
  };

  const handleOpenViewPopup = (item: ILeaveRequestResponseData) => {
    setCurrentItem(item);
    setOpenPopup(true);
  };

  return (
    <Box>
      <RouteGuard listPosition={[Positions.PM]}>
        <>
          <IconButton onClick={() => handleApproveOrReject([item._id], true)}>
            <CheckCircleOutline fontSize="small" style={{ color: '#00C113' }} />
          </IconButton>
          <IconButton onClick={() => handleApproveOrReject([item._id], false)}>
            <HighlightOff fontSize="small" style={{ color: '#DD2C2C' }} />
          </IconButton>
        </>
      </RouteGuard>
      <IconButton onClick={() => handleOpenViewPopup(item)}>
        <Visibility fontSize="small" />
      </IconButton>
      <BasePopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <LeaveForm
          handleCancel={handleCancel}
          textViewModel={'View Leave'}
          cancelText={'Close'}
          defaultValue={currentItem}
        />
      </BasePopup>
    </Box>
  );
};

export default Buttons;
