import { Avatar, Box, TableCell, TableRow, Typography } from '@mui/material';
import { canAcceptPermission, formatDate } from '../../../constants';
import { CustomChipByStatus } from '../constant/constant';
import { Link } from 'react-router-dom';
import icons from 'constants/icons/index';
import { useAppSelector } from '../../../store/store';
import { NameTablesE } from 'types/formProjectAndUserType';
import './style.scss';
import { IFormEmployeeItemProps } from '../interface';
import { Positions } from 'constants/constants.interfaces';

type DataFromType = IFormEmployeeItemProps & {
  callback: () => void;
};

const FormEmployeeItem = (props: DataFromType) => {
  const { avatar, name, contact, phone, date, idUser, namePage, level, status } = props;
  const { positions } = useAppSelector((state) => state.authReducer);
  const { Visibility } = icons;

  const viewEmpolyeeRouter = `/employee/view/${idUser}`;

  return (
    <TableRow className="project-row-item">
      <TableCell className="user-cell-avatar-item">{<Avatar src={avatar} />}</TableCell>
      <TableCell className="user-cell-item">{name}</TableCell>
      <TableCell className="user-cell-item">{contact}</TableCell>
      <TableCell className="user-cell-item">{phone}</TableCell>
      <TableCell className="user-cell-item">{formatDate(date)}</TableCell>
      <TableCell className="user-cell-item">
        {Array.isArray(level) &&
          level.map((item, index) => {
            return (
              <Box>
                <CustomChipByStatus type={item.value} key={index} />
              </Box>
            );
          })}
      </TableCell>

      <TableCell className="user-cell-item">{status}</TableCell>
      {canAcceptPermission(positions, [Positions.ADMIN, Positions.HR]) && (
        <TableCell className="user-cell-item" style={{ paddingLeft: '28px' }}>
          <Link className="stylelinkView" to={viewEmpolyeeRouter}>
            <Visibility className="view-icon" />
          </Link>
        </TableCell>
      )}
    </TableRow>
  );
};

export default FormEmployeeItem;
