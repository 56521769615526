import React from 'react';

interface StyleProps {
  style: React.CSSProperties;
}
const WorkOfficeIcon = (props: StyleProps) => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M9.33334 7.49984C7.49334 7.49984 6.00001 8.99317 6.00001 10.8332C6.00001 12.6732 7.49334 14.1665 9.33334 14.1665C11.1733 14.1665 12.6667 12.6732 12.6667 10.8332C12.6667 8.99317 11.1733 7.49984 9.33334 7.49984ZM10.4333 12.3998L9.00001 10.9665V8.83317H9.66667V10.6932L10.9 11.9265L10.4333 12.3998ZM10 1.49984H7.88C7.60001 0.726504 6.86667 0.166504 6.00001 0.166504C5.13334 0.166504 4.40001 0.726504 4.12001 1.49984H2.00001C1.26667 1.49984 0.666672 2.09984 0.666672 2.83317V12.8332C0.666672 13.5665 1.26667 14.1665 2.00001 14.1665H6.07334C5.68001 13.7865 5.36 13.3332 5.12667 12.8332H2.00001V2.83317H3.33334V4.83317H8.66667V2.83317H10V6.21984C10.4733 6.2865 10.92 6.4265 11.3333 6.61984V2.83317C11.3333 2.09984 10.7333 1.49984 10 1.49984ZM6.00001 2.83317C5.63334 2.83317 5.33334 2.53317 5.33334 2.1665C5.33334 1.79984 5.63334 1.49984 6.00001 1.49984C6.36667 1.49984 6.66667 1.79984 6.66667 2.1665C6.66667 2.53317 6.36667 2.83317 6.00001 2.83317Z"
        fill="#F8991D"
      />
    </svg>
  );
};

export default WorkOfficeIcon;
