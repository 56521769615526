import React from 'react';
import { BaseParamsI } from 'constants/interfaces';
import { titleUser } from 'features/formProjectAndUser/constant/constant';
import FormListEmployees from 'features/formProjectAndUser/formTable/FormEmployeesTable';
import { useAppDispatch, useAppSelector } from 'store/store';
import { NameTablesE } from '../../types/formProjectAndUserType';
import { findByUser } from './redux/actions';
import { Positions } from '../../constants/constants.interfaces';
import { canAcceptPermission, defaultAvatar } from '../../constants';
const Users = () => {
  const dispatch = useAppDispatch();
  const { userList, total, paginateTotal } = useAppSelector((state) => state.userReducer);
  const namePage = NameTablesE.EMPLOYEES;
  const { positions } = useAppSelector((state) => state.authReducer);

  const handleListUser = (params: BaseParamsI) => {
    dispatch(findByUser(params));
  };
  const titleUserByPm = titleUser.filter((index) => index !== 'Action');
  const title = canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])
    ? titleUser
    : titleUserByPm;

  const buttonName = 'Add Employee';
  return (
    <>
      <FormListEmployees
        title={title}
        listEmployees={userList.map((item) => ({
          avatar: item.avatar?.src || defaultAvatar,
          name: item.fullName,
          contact: item.email,
          phone: item.phone,
          date: item.onboardingDate,
          level: item.positions,
          idUser: item._id,
          status: item.status,
        }))}
        namePage={namePage}
        callbackForm={handleListUser}
        totalPage={paginateTotal}
        total={total}
        buttonName={buttonName}
      />
    </>
  );
};

export default Users;
