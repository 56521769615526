import React from 'react';
import { Box, Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';

interface NoDataProps {
  name: string;
}

export default function NotFound(props: NoDataProps & React.CSSProperties) {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40vh',
        flexDirection: 'column',
        color: 'gray',
        ...props,
      }}
    >
      <InboxIcon style={{ fontSize: 70, ...props }} />
      <Typography className="text-no-empty">{props.name}</Typography>
    </Box>
  );
}
