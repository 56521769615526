import {
  DashboardOutlined,
  CalendarMonthOutlined,
  NotificationsNoneOutlined,
  CheckCircleOutline,
  ModeEditOutlined,
  HighlightOff,
  Visibility,
  VisibilityOff,
  GroupsOutlined,
  GroupAddOutlined,
  CakeOutlined,
  ErrorOutlineOutlined,
  HourglassTopOutlined,
  WorkOutlineOutlined,
  EventAvailable,
  BackupTable,
  AddAPhoto,
  FilterList,
  MoreVert,
  GroupAddRounded,
  AddCard,
  ExpandMore,
  KeyboardArrowRight,
  Clear,
  ChevronRight,
  KeyboardArrowDown,
  MoreHoriz,
  CreateOutlined,
  DeleteOutlined,
  KeyboardArrowUp,
  CancelOutlined,
  NavigateNext,
  AccountCircleOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import LeftIcon from './LeftIcon';
import RightIcon from './RightIcon';
import WorkIcon from './WorkIcon';
import CancelIcon from './CancelIcon';
import TimeTracking from './TimeTrackingIcon';
import DeleteActionIcons from './DeleteActionIcons';
import EditActionIcons from './EditActionIcons';
import AddProjectIcons from './AddProjectIcons';
import AddEmployeeIcons from './AddEmployeeIcons';
import InboxIcon from '@mui/icons-material/Inbox';
import IconVatek from './IconVatek';
import ImgBannerLogin from './ImgBannerLogin';
import CheckIcon from './CheckIcon';
import balloons from '../../assets/img/balloons.png';
import confetti from '../../assets/img/confetti.png';
import confettis from '../../assets/img/confettis.png';
import fireworks from '../../assets/img/fireworks.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveMemberIcons from './RemoveMemberIcons';
import KeyIcon from '@mui/icons-material/Key';
import CloudIcons from './CloudIcons';
//icon
const icons = {
  DashboardOutlined,
  EventAvailable,
  GroupsOutlined,
  WorkOutlineOutlined,
  CalendarMonthOutlined,
  NotificationsNoneOutlined,
  CheckCircleOutline,
  ModeEditOutlined,
  HighlightOff,
  Visibility,
  VisibilityOff,
  GroupAddOutlined,
  CakeOutlined,
  ErrorOutlineOutlined,
  HourglassTopOutlined,
  BackupTable,
  AddAPhoto,
  FilterList,
  MoreVert,
  GroupAddRounded,
  AddCard,
  ExpandMore,
  KeyboardArrowRight,
  Clear,
  LeftIcon,
  RightIcon,
  WorkIcon,
  CancelIcon,
  ChevronRight,
  KeyboardArrowDown,
  TimeTracking,
  MoreHoriz,
  CreateOutlined,
  DeleteOutlined,
  DeleteActionIcons,
  EditActionIcons,
  AddProjectIcons,
  AddEmployeeIcons,
  KeyboardArrowUp,
  CancelOutlined,
  InboxIcon,
  IconVatek,
  ImgBannerLogin,
  CheckIcon,
  NavigateNext,
  balloons,
  confetti,
  confettis,
  fireworks,
  AccountCircleOutlined,
  KeyboardArrowDownIcon,
  RemoveMemberIcons,
  SearchOutlined,
  KeyIcon,
  CloudIcons,
};
export default icons;
