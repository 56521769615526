import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import 'features/Login/style.scss';
import { PATH } from 'constants/path';
import { setBreadcrums } from '../../layouts/Sidebar/redux/layoutSlice';
import IconVatek from '../../constants/icons/IconVatek';
import ImgBannerLogin from '../../constants/icons/ImgBannerLogin';
import { validateFieldNameRequired, validationUsernameOrEmailLogin } from '../../constants/rules';
import { ILoginData } from '../../types/loginType';
import icons from '../../constants/icons';
import ControllerTextFied from '../../components/inputTexField';
import { loginAuth } from './redux/actions';

const Auth = () => {
  const form = useForm<ILoginData>({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { handleSubmit } = form;
  const theme = useTheme();
  const { NavigateNext, Visibility, VisibilityOff } = icons;
  const matchBreakPoint = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [handlePassword, setHandlePassword] = useState<boolean>(false);
  const handleChangePasswordIcons = () => {
    setHandlePassword(!handlePassword);
  };
  const onSubmit = handleSubmit((data: ILoginData) => {
    dispatch(loginAuth(data));
    dispatch(setBreadcrums(''));
  });
  useEffect(() => {
    if (token) {
      navigate(PATH.DASHBOARD);
    }
  }, [token, navigate]);

  return (
    <Grid container className="login-page">
      <Grid item xs={6} className="login-column">
        <Box className="login-container">
          <Box className="login-container-content">
            <Box className="login-logo-container">
              <IconVatek style={style.iconVatek_Left} />
            </Box>
            <Grid item className="login-information">
              <Stack alignItems="left" justifyContent="center" spacing={1}>
                <Typography fontSize="36px" fontWeight="700">
                  Sign in
                </Typography>
                <Typography fontSize="14px">Hello, welcome to Vatek</Typography>
              </Stack>
            </Grid>
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <ControllerTextFied
                  name="username"
                  rules={validationUsernameOrEmailLogin}
                  label="Username or email"
                  size="small"
                  className="inputTextfield"
                  inputProps={{ maxLength: 50 }}
                />
                <ControllerTextFied
                  name="password"
                  rules={validateFieldNameRequired('Password')}
                  label="Password"
                  size="small"
                  className="inputTextfield"
                  type={handlePassword ? 'text' : 'password'}
                  InputProps={{
                    className: 'login-input-props',
                    endAdornment: (
                      <InputAdornment className="login-icon-input" position="end">
                        <IconButton onClick={handleChangePasswordIcons}>
                          {handlePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="button-submit"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Let's Go!
                  <NavigateNext />
                </Button>
                <Link to={PATH.RESET_PASSWORD} className="forgot-password-link">
                  Forgot password?
                </Link>
              </form>
            </FormProvider>
          </Box>

          <Box className="login-copyright">
            <Typography className="login-copyright-text">Copyright @Vatek 2022</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} className="login-colum">
        <Box>
          <Box className="login-icon-vatek">
            <IconVatek style={style.iconVatek_Right} />
          </Box>
        </Box>
        <Box className="login-banner-box">
          <Box className="login-banner-img">
            <ImgBannerLogin style={style.banner} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
const style = {
  iconVatek_Left: { width: '140', fill: '#F8A404' },
  iconVatek_Right: { width: '102', height: '73', fill: 'white' },
  banner: { width: '462', height: '567' },
};

export default Auth;
