import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}

const AddEmployeeIcons = (props: StyleProps) => {
  return (
    <svg
      width="24"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M19 12.5V14.5H7V12.5C7 12.5 7 8.5 13 8.5C19 8.5 19 12.5 19 12.5ZM16 3.5C16 2.90666 15.8241 2.32664 15.4944 1.83329C15.1648 1.33994 14.6962 0.955426 14.1481 0.728363C13.5999 0.5013 12.9967 0.44189 12.4147 0.557646C11.8328 0.673401 11.2982 0.959123 10.8787 1.37868C10.4591 1.79824 10.1734 2.33279 10.0576 2.91473C9.94189 3.49667 10.0013 4.09987 10.2284 4.64805C10.4554 5.19623 10.8399 5.66477 11.3333 5.99441C11.8266 6.32405 12.4067 6.5 13 6.5C13.7956 6.5 14.5587 6.18393 15.1213 5.62132C15.6839 5.05871 16 4.29565 16 3.5ZM19.2 8.56C19.7466 9.0643 20.1873 9.67244 20.4964 10.3489C20.8054 11.0254 20.9766 11.7566 21 12.5V14.5H24V12.5C24 12.5 24 9.05 19.2 8.56ZM18 0.500001C17.6979 0.500182 17.3976 0.547414 17.11 0.640001C17.6951 1.47897 18.0087 2.47718 18.0087 3.5C18.0087 4.52282 17.6951 5.52103 17.11 6.36C17.3976 6.45259 17.6979 6.49982 18 6.5C18.7956 6.5 19.5587 6.18393 20.1213 5.62132C20.6839 5.05871 21 4.29565 21 3.5C21 2.70435 20.6839 1.94129 20.1213 1.37868C19.5587 0.816072 18.7956 0.500001 18 0.500001ZM8 5.5H5V2.5H3V5.5H0V7.5H3V10.5H5V7.5H8V5.5Z"
        fill="white"
      />
    </svg>
  );
};

export default AddEmployeeIcons;
