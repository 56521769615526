import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface ItemsState {
  breadcrumbs: string;
}
interface InitialState {
  opened: boolean;
  items: ItemsState;
}

const initialState: InitialState = {
  opened: true,
  items: { breadcrumbs: '' },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setCurrentMenuState: (state, action) => {
      state.opened = action.payload;
    },
    setBreadcrums: (state, action) => {
      state.items = action.payload;
    },
  },
});

export default layoutSlice.reducer;
export const { setCurrentMenuState, setBreadcrums } = layoutSlice.actions;
export const selectCustomization = (state: RootState) => state.layoutReducer;
