import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}

const OfficeIcon = (props: StyleProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M12.3333 3.50016C12.72 3.50016 13.0333 3.6335 13.28 3.8935C13.5333 4.16683 13.6667 4.46683 13.6667 4.8335V12.1668C13.6667 12.5335 13.5333 12.8335 13.28 13.1068C13.0333 13.3668 12.72 13.5002 12.3333 13.5002H1.66668C1.28001 13.5002 0.966677 13.3668 0.72001 13.1068C0.466677 12.8335 0.333344 12.5335 0.333344 12.1668V4.8335C0.333344 4.46683 0.466677 4.16683 0.72001 3.8935C0.966677 3.6335 1.28001 3.50016 1.66668 3.50016H4.33334V2.16683C4.33334 1.78016 4.46668 1.46683 4.72001 1.22016C4.96668 0.966829 5.28001 0.833496 5.66668 0.833496H8.33335C8.72001 0.833496 9.03335 0.966829 9.28001 1.22016C9.53335 1.46683 9.66668 1.78016 9.66668 2.16683V3.50016H12.3333ZM1.66668 4.8335V12.1668H12.3333V4.8335H1.66668ZM8.33335 3.50016V2.16683H5.66668V3.50016H8.33335Z"
        fill="#5F5446"
      />
    </svg>
  );
};

export default OfficeIcon;
