import { createSlice } from '@reduxjs/toolkit';
import { Positions } from 'constants/constants.interfaces';
import { loginAuth } from 'features/Login/redux/actions';
import { LOCAL_STORAGE_KEY, removeItem } from 'utils/localStorage';
import toastConfig from 'utils/toastConfig';
import { ErrorResponse } from '../../Dayoff/redux/dayoffSlice';
import { changePassword } from '../../Users/redux/actions';

interface InitStateI {
  token: string;
  currentLoginUser: string;
  positions: Positions[];
  exp: string | number;
  error: boolean;
  isLogout: boolean;
}

const initialState: InitStateI = {
  token: '',
  currentLoginUser: '',
  positions: [],
  exp: 0,
  error: false,
  isLogout: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutSuccess(state) {
      state.token = '';
      state.currentLoginUser = '';
      state.positions = [];
      state.exp = 0;
      removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
      removeItem(LOCAL_STORAGE_KEY.CURRENT_ID);
      removeItem(LOCAL_STORAGE_KEY.POSITIONS);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAuth.fulfilled, (state, action) => {
      const { access_token, user_id, positions, exp } = action.payload;
      state.token = access_token;
      state.currentLoginUser = user_id;
      state.positions = [].concat(positions);
      state.exp = exp;
      toastConfig.toastSuccess('Login success');
      state.error = false;
      state.isLogout = false;
    });
    builder.addCase(loginAuth.rejected, (state, action) => {
      toastConfig.toastError((action.payload as ErrorResponse).message);
    });
    builder
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLogout = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLogout = false;
        toastConfig.toastError((action.payload as ErrorResponse).message);
      });
  },
});

export const { logoutSuccess } = authSlice.actions;
export default authSlice.reducer;
