import { createSlice } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { caculateWeeklyNow } from '../../../constants';
import { IReportList, NewApprovalTimesheetI } from '../interface';
import toastConfig from '../../../utils/toastConfig';
import { ErrorResponse } from '../../Dayoff/redux/dayoffSlice';
import {
  approvalTimesheetsAsync,
  createTimesheetAsync,
  getTimeSheets,
  getTimeSheetApprovals,
  getTimesheetsAsync,
  getTimesheetsByMonthAsync,
  rejectTimesheetsAsync,
  getMonthlyReport,
  exportTimeSheetReport,
} from './actions';
export interface TimesheetItemState {
  status: string;
  projectName: string;
  type: string;
  hours: number;
  details: string;
}

interface TimesheetProps {
  [key: string]: {
    total: string;
    date: string;
    items: TimesheetItemState[];
  };
}
interface InitState {
  approvalDateMOY: Moment;
  startWeek: Moment;
  endWeek: Moment;
  listTimeSheet: TimesheetProps;
  listTimeSheets: NewApprovalTimesheetI[];
  isRefresh: boolean;
  isLoading: boolean;
  listReport: IReportList[];
}
interface ActionsState {
  payload: number;
}

const dateNow = moment();
const initialState: InitState = {
  approvalDateMOY: dateNow,
  startWeek: caculateWeeklyNow(0, dateNow).startWeek,
  endWeek: caculateWeeklyNow(0, dateNow).endWeek,
  listTimeSheet: {},
  listTimeSheets: [],
  isRefresh: false,
  isLoading: false,
  listReport: [],
};

const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    addMonthOfYear: (state, actions) => {
      const numberOfMonth = actions.payload;
      state.approvalDateMOY = caculateWeeklyNow(numberOfMonth, dateNow, 'months').now;
    },
    addWeekly: (state, actions: ActionsState) => {
      const numberWeek = actions.payload;
      state.startWeek = caculateWeeklyNow(numberWeek, dateNow).startWeek;
      state.endWeek = caculateWeeklyNow(numberWeek, dateNow).endWeek;
    },
    refreshData: (state, actions) => {
      state.isRefresh = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTimesheetAsync.fulfilled, (state, actions) => {
        state.listTimeSheet = actions.payload ?? state.listTimeSheet;
      })
      .addCase(createTimesheetAsync.rejected, (state, actions) => {
        toastConfig.toastError((actions.payload as ErrorResponse).message);
      });

    builder.addCase(getTimesheetsAsync.fulfilled, (state, actions) => {
      state.listTimeSheet = actions.payload;
    });

    builder
      .addCase(getTimeSheets.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getTimeSheets.fulfilled, (state, actions) => {
        state.listTimeSheet = actions.payload;
        state.isLoading = false;
      })
      .addCase(getTimeSheets.rejected, (state, actions) => {
        state.isLoading = false;
      });

    builder.addCase(getTimesheetsByMonthAsync.fulfilled, (state, actions) => {
      state.listTimeSheets = actions.payload;
    });

    builder.addCase(approvalTimesheetsAsync.fulfilled, (state, actions) => {});
    builder.addCase(rejectTimesheetsAsync.fulfilled, (state, actions) => {});

    builder
      .addCase(getTimeSheetApprovals.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getTimeSheetApprovals.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.listTimeSheets = actions.payload;
      })
      .addCase(getTimeSheetApprovals.rejected, (state, actions) => {
        state.isLoading = false;
      });
    builder
      .addCase(getMonthlyReport.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMonthlyReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listReport = action.payload;
      })
      .addCase(getMonthlyReport.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { addWeekly, addMonthOfYear, refreshData } = timesheetSlice.actions;
export default timesheetSlice.reducer;
