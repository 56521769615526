import { createSlice } from '@reduxjs/toolkit';
import { IUserInfo } from 'types/userType';
import toastConfig from 'utils/toastConfig';
import {
  addProjectThunk,
  createProjectThunk,
  deleteProjectThunk,
  getAllProject,
  getProjectById,
  getProjectByPm,
  getProjectByUser,
  removeProjectThunk,
  findByProject,
  findPmProject,
  updateProjectThunk,
} from 'features/Projects/redux/actions';
import { IProject } from '../../../types/projectType';
import { MESSAGES } from '../../../constants/constants.message';

type UserType = Pick<IUserInfo, '_id' | 'email' | 'fullName' | 'avatar'>;

export interface ProjectState {
  _id: string;
  isDeleted: false;
  startDate: Date;
  state: null;
  member: UserType[];
  projectManager: UserType;
  projectName: string;
}

interface InitialState {
  projectList: ProjectState[];
  total: number;
  paginateTotal: number;
  projectDetail: IProject;
  isLoading: boolean;
}

const initialState: InitialState = {
  projectList: [],
  total: 0,
  paginateTotal: 0,
  projectDetail: {
    projectName: '',
    state: '',
    member: [],
    projectManager: {
      fullName: '',
      level: {
        value: '',
        _id: '',
        key: '',
      },
    },
    startDate: new Date(),
    clientName: '',
  },
  isLoading: false,
};

const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProject.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllProject.fulfilled, (state, action) => {
        state.projectList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
      })
      .addCase(getAllProject.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder.addCase(findByProject.fulfilled, (state, action) => {
      state.projectList = action.payload.data;
      state.total = action.payload.total;
      state.paginateTotal = action.payload.paginateTotal;
    });
    builder.addCase(findPmProject.fulfilled, (state, action) => {
      state.projectList = action.payload.data;
      state.total = action.payload.total;
      state.paginateTotal = action.payload.paginateTotal;
    });

    builder
      .addCase(getProjectByPm.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectByPm.fulfilled, (state, action) => {
        state.projectList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
      })
      .addCase(getProjectByPm.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getProjectByUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectByUser.fulfilled, (state, action) => {
        state.projectList = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getProjectByUser.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteProjectThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteProjectThunk.fulfilled, (state, action) => {
        state.projectList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
        toastConfig.toastSuccess(`${MESSAGES.DELETE_SUCCESSFULLY}`);
      })
      .addCase(deleteProjectThunk.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(createProjectThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProjectThunk.fulfilled, (state, action) => {
        state.projectList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
        toastConfig.toastSuccess(`${MESSAGES.CREATE_SUCCESSFULLY}`);
      })
      .addCase(createProjectThunk.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(updateProjectThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProjectThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        toastConfig.toastSuccess(`${MESSAGES.UPDATE_SUCCESSFULLY}`);
      })
      .addCase(updateProjectThunk.rejected, (state, action) => {
        state.isLoading = false;
        toastConfig.toastError(`${MESSAGES.UPDATE_FAILED}`);
      });

    builder.addCase(addProjectThunk.fulfilled, (state, action) => {
      state.projectList = action.payload;
    });

    builder.addCase(removeProjectThunk.fulfilled, (state, action) => {
      state.projectList = action.payload;
    });

    builder
      .addCase(getProjectById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        state.projectDetail = action.payload;
        state.isLoading = false;
      })
      .addCase(getProjectById.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export default ProjectSlice.reducer;
