import { Button, Grid, Stack, Typography, Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import '../forgotPassword/style.scss';
import { PATH } from 'constants/path';
import { validationEmailRules } from '../../constants/rules';
import icons from '../../constants/icons';
import ControllerTextFied from '../../components/inputTexField';
import { sendEmailForgotPasswordAuth } from '../forgotPassword/redux/action';
import ForgotPasswordBase from '../forgotPassword/forgotPasswordBase';
import { IForgotPasswordFormValues } from './interface';

const ForgotPassword = () => {
  const form = useForm<IForgotPasswordFormValues>({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = form;
  const { NavigateNext } = icons;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data: IForgotPasswordFormValues) => {
    const res = await dispatch(sendEmailForgotPasswordAuth(data));
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(PATH.CONFIRM_EMAIL);
    }
  });

  return (
    <ForgotPasswordBase>
      <Grid item className="forgot-password-information">
        <Stack alignItems="left" justifyContent="center" spacing={1}>
          <Typography variant="h1" fontSize="36px" fontWeight="700">
            Reset password
          </Typography>
          <Typography variant="body1" fontSize="14px">
            Please enter your email to reset the password
          </Typography>
        </Stack>
        <Box className="forgot-password-form">
          <FormProvider {...form}>
            <form onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <ControllerTextFied
                    name="email"
                    rules={validationEmailRules}
                    label="Email"
                    size="small"
                    className="inputTextfield"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="button-submit-form"
                    size="large"
                  >
                    <NavigateNext />
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Grid>
    </ForgotPasswordBase>
  );
};

export default ForgotPassword;
