import React from 'react';
import './style.scss';
import laptop from '../../assets/img/laptop.png';
import { Button, Typography } from '@mui/material';
import { useAppDispatch } from '../../store/store';
import { logoutSuccess } from '../../features/Login/redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../constants/path';

const SessionExpired = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(logoutSuccess());
    navigate(PATH.LOGIN);
  };
  return (
    <div className="session-expired">
      <div className="session-expired-container">
        {/* <img src={laptop} alt="laptop" width={150} /> */}
        <Typography variant="h5" fontWeight="bold" className="session-expired-header">
          Your session has expired
        </Typography>
        <div className="session-expired-text">
          <Typography>Please login again !</Typography>
        </div>
        <Button className="session-expired-button" onClick={logout}>
          Back to login page
        </Button>
      </div>
    </div>
  );
};

export default SessionExpired;
