import { List, Typography } from '@mui/material';
import NavCollapse from 'layouts/Sidebar/components/NavCollapse';
import { IMenuPropType, IMenuVariables, INavSub } from 'types/navigation';
import { EMenuComponentType } from 'constants/menuConstants';
import NavItem from '../NavItem';
import { useState } from 'react';

const NavGroup = ({ item }: IMenuPropType) => {
  const [activeNav, setActiveNav] = useState<string>('');
  const items = item?.children?.map((menu: IMenuVariables) => {
    switch (menu?.type) {
      case EMenuComponentType.COLLAPSE:
        const variableMenu = menu?.subNav?.some((subNav: INavSub) => {
          return subNav?.url === activeNav;
        });
        return (
          <NavCollapse
            key={menu.id}
            activeMenu={!!variableMenu}
            setActiveNav={setActiveNav}
            item={menu}
            level={1}
            children={menu.id}
          />
        );
      case EMenuComponentType.ITEM:
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List>{items}</List>
    </>
  );
};

export default NavGroup;
