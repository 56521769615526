import { Positions, searchName } from 'constants/constants.interfaces';
import MainLayout from 'layouts/MainLayout';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import { canAcceptPermission } from '../../constants';
import {
  getAllLevel,
  getAllPosition,
  getAllStatus,
  getWorkingTypes,
} from '../../reducers/configs/actions';
import { activeInitConfigs } from '../../reducers/configs/configSlice';
import { getAllMentor } from '../Users/redux/actions';

function Home() {
  const dispatch = useAppDispatch();
  const { isInit } = useAppSelector((state) => state.configReduce);
  const { positions } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR]) && !isInit) {
      dispatch(getAllLevel({ searchByKey: searchName.level }));
      dispatch(getAllPosition({ searchByKey: searchName.position }));
      dispatch(getAllMentor({}));
      dispatch(activeInitConfigs());
    }
    dispatch(getWorkingTypes({ searchByKey: searchName.workingType }));
    dispatch(getAllStatus({ searchByKey: searchName.status }));
  }, [dispatch, isInit, positions]);

  return (
    <>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  );
}

export default Home;
