import Home from 'features/Home';
import Users from 'features/Users';
import Leave from 'features/Dayoff';
import Projects from 'features/Projects';
import { PATH } from 'constants/path';
import Dashboard from 'features/Dashboard';
import TimeScheetScreen from 'features/timesheet';
import ViewEmployees from '../features/Users/ViewEmployees';
import ViewProjects from '../features/Projects/ViewProjects';
import { Navigate } from 'react-router-dom';
import ApprovalsScreen from '../features/timesheet/approvals';
import ApprovalsLeaveScreen from '../features/Dayoff/approvals';
import AddEmployees from '../features/Users/AddEmployees';
import AddProject from '../features/Projects/addProject';
import ChangePassword from '../features/ChangePassword';
import Report from '../features/timesheet/report';
interface IROUTERS {
  id: number;
  navigate: string;
  element: JSX.Element;
}

export const ROUTERS: IROUTERS[] = [
  {
    id: 0,
    navigate: PATH.HOME,
    element: <Navigate to={PATH.DASHBOARD} />,
  },
  {
    id: 1,
    navigate: PATH.DASHBOARD,
    element: <Dashboard />,
  },
  {
    id: 2,
    navigate: PATH.EMPLOYEES,
    element: <Users />,
  },
  {
    id: 3,
    navigate: PATH.PROJECT,
    element: <Projects />,
  },
  {
    id: 4,
    navigate: PATH.CALENDAR,
    element: <Leave />,
  },
  {
    id: 5,
    navigate: PATH.APPROVALDS_LEAVE,
    element: <ApprovalsLeaveScreen />,
  },
  {
    id: 6,
    navigate: PATH.TIMESHEET,
    element: <TimeScheetScreen />,
  },

  {
    id: 7,
    navigate: PATH.APPROVALDS_TIMETRACKING,
    element: <ApprovalsScreen />,
  },
  {
    id: 8,
    navigate: PATH.REPORTS,
    element: <Report />,
  },
  {
    id: 9,
    navigate: PATH.EMPLOYEE_VIEW,
    element: <ViewEmployees />,
  },
  {
    id: 10,
    navigate: PATH.PROJECT_VIEW,
    element: <ViewProjects />,
  },
  {
    id: 11,
    navigate: PATH.EMPLOYEE_CREATE,
    element: <AddEmployees />,
  },
  {
    id: 12,
    navigate: PATH.PROJECT_CREATE,
    element: <AddProject />,
  },
  {
    id: 13,
    navigate: PATH.PROJECT_UPDATE,
    element: <AddProject />,
  },
  {
    id: 14,
    navigate: PATH.EMPLOYEE_UPDATE,
    element: <AddEmployees />,
  },
  {
    id: 15,
    navigate: PATH.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
];
