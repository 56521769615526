import React, { useEffect, useMemo, useState } from 'react';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ERequestStatus, getAllRequest, getRequestByTeam } from './redux/actions';
import { ILeaveRequestResponseData } from '../../types/dayOffType';
import { Box, Button, Typography } from '@mui/material';
import './style.scss';
import BasePopup from '../../components/popup';
import LeaveForm from './LeaveForm';
import { canAcceptPermission } from '../../constants';
import { Positions } from '../../constants/constants.interfaces';
import moment from 'moment';

const Leave = () => {
  const dispatch = useAppDispatch();
  const { positions } = useAppSelector((state) => state.authReducer);
  const { requestList } = useAppSelector((state) => state.requestReducer);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState('');

  useEffect(() => {
    if (!currentMonth) return;
    canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])
      ? dispatch(getAllRequest(currentMonth))
      : dispatch(getRequestByTeam(currentMonth));
  }, [dispatch, currentMonth]);

  const getTitle = (leaveRequest: ILeaveRequestResponseData, value: string) => {
    if (leaveRequest.isMorning) {
      return `${value} (Morning)`;
    }
    if (leaveRequest.isAfternoon) {
      return `${value} (Afternoon)`;
    }
    return `${value} (Full day)`;
  };

  const handleCancel = () => {
    setOpenPopup(false);
  };

  const eventList = useMemo<EventSourceInput>(
    () =>
      requestList.map((item) => {
        const {
          fromDay,
          toDay,
          user: { fullName },
          _id,
          status: { value: status },
          reason,
        } = item;
        const endDate = new Date(toDay as unknown as string);
        const eventEnd = endDate.setDate(endDate.getDate() + 1);
        return {
          title: getTitle(item, fullName),
          start: fromDay,
          end: eventEnd,
          allDay: true,
          color: status === ERequestStatus.PENDING ? 'rgb(253, 80, 104)' : '#3993fa',
          extendedProps: {
            fullName: fullName,
            id: _id,
            status: status.toLowerCase(),
            reason: reason,
          },
        };
      }),
    [requestList],
  );

  return (
    <div className="leave-calendar-container">
      <Box className="leave-calendar-note">
        <Typography className="text-note">Notes</Typography>
        <Box className="leave-calendar-button">
          <Box className="leave-calendar-fullDay">
            <Button className="leave-calendar-button-fullDay"></Button>
            <Typography className="leave-calendar-fullDay-text">Pending</Typography>
          </Box>
          <Box className="leave-calendar-other">
            <Button className="leave-calendar-button-other"></Button>
            <Typography className="leave-calendar-other-text">Approved</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="leave-calendar-table">
        <Box className="leave-calendar-view">
          <BasePopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
            <LeaveForm
              handleCancel={handleCancel}
              textViewModel={'Request Leave'}
              cancelText="Cancel"
              submitText="Submit"
              date={currentMonth}
            />
          </BasePopup>
          <FullCalendar
            customButtons={{
              addLeave: {
                text: 'Request Leave',
                click: () => setOpenPopup(true),
              },
            }}
            height="100%"
            events={eventList}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            showNonCurrentDates={false}
            fixedWeekCount={false}
            headerToolbar={{
              end: 'addLeave',
              center: '',
              start: 'prev,title,next',
            }}
            datesSet={(today) => {
              setCurrentMonth(moment(today.startStr).toISOString());
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Leave;
