import { Box } from '@mui/material';
import LogoSection from 'layouts/Header/components/LogoSection';
import ProfileSection from 'layouts/Header/components/ProfileSection';
import 'layouts/Header/style.scss';
import SearchEmployee from './components/SearchEmployee';
import { Positions } from '../../constants/constants.interfaces';
import RouteGuard from '../../commons/RouteGuard';

interface IHeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: IHeaderProps) => {
  return (
    <Box className="box-shadow">
      <Box className="header-left">
        <Box className="box-logo">
          <LogoSection />
        </Box>
      </Box>
      <Box className="header-right">
        <Box style={{ paddingLeft: '32px' }}>
          <RouteGuard listPosition={[Positions.ADMIN, Positions.HR]}>
            <SearchEmployee />
          </RouteGuard>
        </Box>
        <Box style={{ paddingRight: '32px' }}>
          <ProfileSection />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
