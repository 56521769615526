import { Dialog, PaperProps } from '@mui/material';

interface IPopupProp {
  openPopup: boolean;
  setOpenPopup: (arg0: boolean) => void;
  children: JSX.Element;
  paperProps?: PaperProps;
}

const BasePopup = ({ openPopup, setOpenPopup, children, paperProps }: IPopupProp) => {
  return (
    <Dialog open={openPopup} PaperProps={paperProps} onClose={() => setOpenPopup(false)}>
      {children}
    </Dialog>
  );
};

export default BasePopup;
