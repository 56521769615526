import {
  Autocomplete,
  AutocompleteInputChangeReason,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { IKeyState } from 'constants/interfaces';
import React from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { SyntheticEvent } from 'react';
import './index.scss';

interface IControllerProps {
  name: string;
  rules?: ControllerProps['rules'];
  options: IKeyState[];
  popupIcon?: React.ReactNode;
  getOptionLabel?: (option: string | IKeyState) => string;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    values: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  defaultValues?: IKeyState | IKeyState[];
  callBack?: <T extends unknown>(data: T) => void;
}

type ValueProps = string | IKeyState;

type SelectValueProps = ValueProps | ValueProps[] | null;

const ControllerSelect = ({
  name,
  rules,
  options,
  getOptionLabel,
  onInputChange,
  popupIcon,
  defaultValues,
  multiline,
  callBack,
  ...textFieldProps
}: IControllerProps & TextFieldProps) => {
  const methods = useFormContext();
  const { control, setValue } = methods;
  const [selectDefault, setSelectDefault] = React.useState(defaultValues);
  const positionsOptions = React.useRef(options);

  const handleSingleSelect = (_event: SyntheticEvent, value: SelectValueProps) => {
    const key = (value as IKeyState)?.key;
    setSelectDefault(value as IKeyState);
    setValue(name, key ?? '');
    if (callBack) callBack(key);
  };

  const handleMultipleSelect = (_event: SyntheticEvent, values: SelectValueProps) => {
    const keys = (values as IKeyState[])?.map((value: IKeyState) => value?.key);
    setSelectDefault(values as IKeyState[]);
    setValue(name, keys ?? '');
    if (callBack) callBack(keys);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={setValue(
        name,
        multiline
          ? (selectDefault as IKeyState[])?.map((item) => item.key)
          : (selectDefault as IKeyState)?.key,
      )}
      render={({ field: { onBlur }, fieldState: { error } }) => (
        <Autocomplete
          multiple={multiline}
          filterSelectedOptions
          onBlur={onBlur}
          options={positionsOptions.current}
          getOptionLabel={getOptionLabel}
          popupIcon={popupIcon}
          defaultValue={defaultValues}
          noOptionsText="No result found"
          onInputChange={onInputChange}
          onChange={multiline ? handleMultipleSelect : handleSingleSelect}
          disabled={textFieldProps.disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              error={!!error}
              variant="outlined"
              helperText={error?.message ?? null}
            />
          )}
        />
      )}
    />
  );
};

export default ControllerSelect;
