import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';

import 'layouts/Sidebar/components/NavCollapse/style.scss';
import icons from 'constants/icons';
import NavSub from '../NavSub';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { setCurrentMenuState } from 'layouts/Sidebar/redux/layoutSlice';
import { IMenuPropType, INavSub } from 'types/navigation';
import { useLocation } from 'react-router-dom';

interface IMenuCollapse extends IMenuPropType {
  children?: string;
}

const NavCollapse = ({ item, level, activeMenu, setActiveNav, children }: IMenuCollapse) => {
  const { opened } = useSelector((state: RootState) => state.layoutReducer);
  const disabled = !!item?.disabled;
  const Icon = !disabled ? item?.icon : <></>;
  const itemIcon = <Icon stroke={1.5} size="1.3rem" />;
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState<boolean>(!opened);
  const { ChevronRight, KeyboardArrowDown } = icons;

  const { pathname } = useLocation();
  const newPathName = pathname.slice(1);
  const menu = newPathName.slice(0, newPathName.indexOf('/'));
  const keyMenuCollapse = menu.split('_').join(' ');

  useEffect(() => {
    if (opened) {
      setOpen(false);
      setActiveNav(false);
    }
  }, [opened]);

  useEffect(() => {
    if (children?.toLocaleLowerCase() === keyMenuCollapse) {
      setOpen(true);
    }
  }, [keyMenuCollapse]);

  const handleClick = () => {
    setOpen(!open);
    dispatch(setCurrentMenuState(false));
  };

  return (
    <>
      <ListItemButton
        disabled={disabled}
        className={activeMenu ? 'nav-collapse-open' : 'nav-collapse'}
        onClick={handleClick}
      >
        <ListItemIcon
          style={{ width: '25px', height: '25px' }}
          className={activeMenu ? 'nav-collapse-icon-open' : 'nav-collapse-icon'}
        >
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1" color="inherit" fontWeight={400}>
              {item?.name}
            </Typography>
          }
        />
        {open ? (
          <KeyboardArrowDown className="collapse-menu-icon-base" />
        ) : (
          <ChevronRight className="collapse-menu-icon-base" />
        )}
      </ListItemButton>
      <Collapse in={open}>
        <List component="div" disablePadding className="collapse-menu">
          {item?.subNav?.map((item: INavSub) => {
            return (
              <NavSub setActiveNav={setActiveNav} level={level + 1} item={item} key={item.id} />
            );
          }) ?? <></>}
        </List>
      </Collapse>
    </>
  );
};

export default NavCollapse;
