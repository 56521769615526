import './index.scss';
import icons from '../../constants/icons/index';

interface IProps {
  message: string;
  type: string;
}

const DetailPopup = ({ message, type }: IProps) => {
  return (
    <div className="detail-popup">
      <div className="main-popup">
        <div className="header">
          <div className="type">{type}</div>
        </div>
        <div className="body">
          {<icons.HighlightOff className="icon" />}
          <div className="content">{message}</div>
        </div>
      </div>
    </div>
  );
};

export default DetailPopup;
