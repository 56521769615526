import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}

const RemoveMemberIcons = (props: StyleProps) => {
  return (
    <svg
      width="33"
      style={props.style}
      height="33"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2765_7508)">
        <rect x="9.5" y="15.5" width="14" height="2" fill="#5F5446" />
      </g>
      <rect x="0.5" y="1" width="32" height="32" rx="4.5" stroke="#5F5446" />
      <defs>
        <clipPath id="clip0_2765_7508">
          <rect width="24" height="24" fill="white" transform="translate(4.5 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RemoveMemberIcons;
