import { limitDefault, offsetDefault } from '../../constants';
import { BaseParamsI, ReduxLoadingStateT } from 'constants/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitStateT = BaseParamsI & {
  loading: ReduxLoadingStateT;
};

const initialState: InitStateT = {
  limit: limitDefault,
  offset: offsetDefault,
  search: '',
  loading: 'idle',
};
const paginateSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    updatePaginate: (state, action: PayloadAction<Partial<BaseParamsI>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { actions: paginationActions } = paginateSlice;
export const usePaginationSlice = () => {
  return { actions: paginationActions };
};
export default paginateSlice.reducer;
