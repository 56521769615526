import React from 'react';

interface StyleProps {
  style: React.CSSProperties;
}
const CancelIcon = (props: StyleProps) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M7.00001 0.833496C3.31334 0.833496 0.333344 3.8135 0.333344 7.50016C0.333344 11.1868 3.31334 14.1668 7.00001 14.1668C10.6867 14.1668 13.6667 11.1868 13.6667 7.50016C13.6667 3.8135 10.6867 0.833496 7.00001 0.833496ZM10.3333 9.8935L9.39334 10.8335L7.00001 8.44016L4.60668 10.8335L3.66668 9.8935L6.06001 7.50016L3.66668 5.10683L4.60668 4.16683L7.00001 6.56016L9.39334 4.16683L10.3333 5.10683L7.94001 7.50016L10.3333 9.8935Z"
        fill="#F05161"
      />
    </svg>
  );
};

export default CancelIcon;
