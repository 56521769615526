import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import imgView from 'assets/img/avatar.png';
import { formatDate } from '../../../../constants';
import { useAppSelector } from '../../../../store/store';
import './style.scss';

type Props = {};

const ViewContent = (props: Props) => {
  const {
    username,
    email,
    citizenID,
    dateOfBirth,
    address,
    onboardingDate,
    onboardingMentor,
    level,
    phone,
    positions,
    avatar,
    status,
  } = useAppSelector((state) => state.userReducer.detailUser);

  return (
    <Box className="viewContent">
      <Box className="content-container">
        <Box className="content-left">
          <img className="imgView" src={avatar ? avatar.src : imgView} alt="" />
        </Box>
        <Box className="content-right">
          <Box className="content-right-box">
            <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600 }}>
              Personal details
            </Typography>
            <Box className="rows-content">
              <Grid container style={{ padding: '10px 0px' }} spacing={2}>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Username</Typography>
                  <Typography className="style-data-view">{username}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Email address</Typography>
                  <Typography className="style-data-view">{email}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">National ID</Typography>
                  <Typography className="style-data-view">{citizenID}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Phone Number</Typography>
                  <Typography className="style-data-view">{phone}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Date of Birth</Typography>
                  <Typography className="style-data-view">
                    {formatDate(dateOfBirth as Date)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Address</Typography>
                  <Typography className="style-data-view">{address}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="content-right-box">
            <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600 }}>
              Employment details
            </Typography>
            <Box className="rows-content">
              <Grid container style={{ padding: '10px 0px' }} spacing={2}>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Onboarding Date</Typography>
                  <Typography className="style-data-view">
                    {formatDate(onboardingDate as Date)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Onboarding Mentor</Typography>
                  <Typography className="style-data-view">{onboardingMentor.fullName}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Position</Typography>
                  {positions.map((item) => (
                    <Typography className="style-data-view">{item.value}</Typography>
                  ))}
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Level</Typography>
                  <Typography className="style-data-view">{level.value}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="style-title-view">Status</Typography>
                  <Typography className="style-data-view">{status}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewContent;
