import { Typography } from '@mui/material';
import { EMenuComponentType } from 'constants/menuConstants';
import { IMenuVariables } from 'types/navigation';
import NavGroup from 'layouts/Sidebar/components/NavGroup';

interface IMenusListProps {
  menuItem: Array<IMenuVariables>;
}

const MenuList = ({ menuItem }: IMenusListProps) => {
  const navItems = menuItem?.map((item: IMenuVariables) => {
    switch (item?.type) {
      case EMenuComponentType.GROUP:
        return <NavGroup key={item.id} item={item} level={1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
