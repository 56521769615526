import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}

const EditActionIcons = (props: StyleProps) => {
  return (
    <div>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
      >
        <path
          d="M2.28247 12.389L11.4749 3.19666L14.3033 6.02508L5.11089 15.2175H2.28247V12.389Z"
          stroke="white"
        />
        <path
          d="M12.889 1.7825L13.5961 1.07539C13.9866 0.684869 14.6198 0.684869 15.0103 1.07539L16.4245 2.48961C16.8151 2.88013 16.8151 3.5133 16.4245 3.90382L15.7174 4.61093L12.889 1.7825Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </div>
  );
};

export default EditActionIcons;
