import { Button, capitalize, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Icons from 'constants/icons';
import { formatDate } from '../../../constants';
import React, { useCallback, useEffect, useState } from 'react';
import ControllerSelect from '../../../components/inputFromSelect';
import { formatMMMMYYYY, formatYYYYMMDD } from '../../../constants/constants.timeFormat';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addMonthOfYear, refreshData } from '../redux/dayoffSlice';
import { styleHeader } from './styles';
import icons from 'constants/icons';
import { IKeyState } from '../../../constants/interfaces';
import { ERequestStatus, getUsersMonthlyLeave } from '../redux/actions';
import * as moment from 'moment';

export interface SearchLeaveByMonthI {
  status: string;
  date: string | Date | moment.Moment;
}

export interface ApproveOrRejectI extends SearchLeaveByMonthI {
  ids: string[];
}

interface ApprovalHeaderPropsI {
  setCurrentStatus: React.Dispatch<React.SetStateAction<string>>;
}

const ApprovalHeaderComponent = ({ setCurrentStatus }: ApprovalHeaderPropsI) => {
  const dispatch = useAppDispatch();
  const { approvalDateMOY } = useAppSelector((state) => state.requestReducer);
  const configsState = useAppSelector((state) => state.configReduce);
  const listStatusCustom = configsState.listStatus.map((item) => ({
    key: item.value,
    value: capitalize(item.value.toLowerCase()),
  }));
  const [numberMOY, setNumberMOY] = useState<number>(0);
  const { KeyboardArrowDownIcon } = icons;
  const defaultStatus = [{ key: '', value: 'All' }] as IKeyState[];
  const itemPending = listStatusCustom.find(
    (item) => item.value.toLowerCase() === ERequestStatus.PENDING.toLowerCase(),
  );

  const useFormState = useForm<SearchLeaveByMonthI>({
    defaultValues: {
      status: itemPending?.key ?? '',
    },
  });

  const { handleSubmit } = useFormState;
  const handleAction = useCallback(
    (value: number) => {
      dispatch(addMonthOfYear(value));
      setNumberMOY(value);
    },
    [dispatch],
  );

  const handleSearchLeaveRequest = handleSubmit((data: SearchLeaveByMonthI) => {
    setCurrentStatus(data.status);
    dispatch(refreshData(true));
    dispatch(getUsersMonthlyLeave({ ...data, date: formatDate(approvalDateMOY, formatYYYYMMDD) }));
  });

  useEffect(() => {
    handleAction(0);
  }, [handleAction]);

  const btnAction = (isNext: boolean) => {
    if (isNext) {
      handleAction(numberMOY + 1);
      return;
    }
    handleAction(numberMOY - 1);
  };

  return (
    <FormProvider {...useFormState}>
      <Grid container sx={styleHeader.container}>
        <Grid item container xs={10} sx={styleHeader.gridItem}>
          <Grid item>
            <Button variant="outlined" sx={styleHeader.button} onClick={() => btnAction(false)}>
              <Icons.LeftIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" className="buttonTime" sx={styleHeader.buttonWeekly}>
              <Typography className="buttonText" textTransform={'none'}>
                {formatDate(approvalDateMOY, formatMMMMYYYY)}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              sx={styleHeader.button}
              size="small"
              onClick={() => btnAction(true)}
            >
              <Icons.RightIcon />
            </Button>
          </Grid>
          <Grid item>
            <ControllerSelect
              name="status"
              options={([] as IKeyState[]).concat(defaultStatus, listStatusCustom)}
              defaultValues={itemPending}
              sx={styleHeader.controllerSelect}
              size="small"
              getOptionLabel={(option) => (option as IKeyState).value || ''}
              label="Status"
              popupIcon={<KeyboardArrowDownIcon />}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className="buttonShowToday"
            style={styleHeader.buttonApply}
            onClick={handleSearchLeaveRequest}
          >
            <Typography className="buttonTextTitle-color-paper">Apply</Typography>
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
export default ApprovalHeaderComponent;
