/* eslint-disable indent */
import { Avatar, Box, Button, Collapse, Grid, IconButton, styled, Typography } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { ApprovalLeaveDataProps } from '.';
import ChipBase, { ChipColorsType } from '../../../components/chip';
import NotFound from '../../../components/notFound';
import { canAcceptPermission, formatDate } from '../../../constants';
import { Positions } from '../../../constants/constants.interfaces';
import { formatDDMMYYYY } from '../../../constants/constants.timeFormat';
import icons from '../../../constants/icons';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ERequestStatus } from '../redux/actions';
import { refreshData } from '../redux/dayoffSlice';
import { styleList } from './styles';

interface ListApprovalProps {
  itemsCB: ApprovalLeaveDataProps[];
  isParent: boolean;
  countRender: number;
  updateLeaveStatusCallback: (ids: string[], isApproval: boolean) => void;
}

const ListApprovalTreeComponent: React.FC<ListApprovalProps> = (props) => {
  const { positions } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState<Map<string, boolean>>(new Map());
  const { isRefresh } = useAppSelector((state) => state.timesheetReduce);

  const { KeyboardArrowDown, CheckCircleOutline, CancelOutlined, KeyboardArrowRight } = icons;

  const items = props.itemsCB;

  let countRender: number = props.countRender;
  const { isParent } = props;

  useEffect(() => {
    if (isRefresh) {
      setOpen(new Map());
      dispatch(refreshData(false));
    }
  }, [dispatch, isRefresh]);

  const handleTextStatus = (text?: string) => text ?? '0h';
  countRender++;
  const handleDisplayLeaveType = (item: ApprovalLeaveDataProps) => {
    if (item.isAfternoon) {
      return 'Afternoon';
    } else if (item.isMorning) {
      return 'Morning';
    }
    return 'Full day';
  };

  const RenderChipStatus = (props: { label: string; color: ChipColorsType }) => {
    return (
      <ChipBase
        sx={styleList.chipContainer}
        label={props.label}
        variant="filled"
        customcolor={props.color}
      />
    );
  };

  return (
    <Box>
      {items.length ? (
        items.map((item: ApprovalLeaveDataProps, index: number) => {
          const name: string = item._id ?? '';
          const getBox = open.get(name);
          const pendingLeaveIds = item.children
            ?.filter((element) => element.status.value === ERequestStatus.PENDING)
            .map((element) => element._id);
          return (
            <Fragment key={`${index}.${item}`}>
              <Grid
                container
                sx={{
                  ...styleList.container,
                  alignItems: 'center',
                  border: '1px solid #DAE1F5',
                }}
              >
                <Grid item xs={9}>
                  <Grid item container xs={12} sx={styleList.container}>
                    <Grid item xs={4} display="flex" alignSelf="center">
                      <Grid item container xs={12} sx={styleList.container}>
                        {countRender === 1 ? (
                          <Grid
                            item
                            sx={{
                              '& > :not(style)': {
                                ml: countRender > 1 ? 1 + countRender : 0,
                                mt: 2,
                                mb: 2,
                              },
                            }}
                            xs={2}
                            textAlign="center"
                            alignSelf={'center'}
                            onClick={() => setOpen(new Map(open.set(name, !getBox ?? true)))}
                          >
                            <Box>
                              {getBox ? (
                                <KeyboardArrowDown style={styleList.arrow} />
                              ) : (
                                <KeyboardArrowRight style={styleList.arrow} />
                              )}
                            </Box>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid item xs={2} alignSelf="center">
                          {isParent && <Avatar src={item.avatar} sx={styleList.avatar} />}
                        </Grid>
                        <Grid item xs={8} alignSelf="center">
                          <Grid
                            item
                            container
                            sx={{
                              ...(item?.fromDay ? { justifyContent: 'start' } : {}),
                            }}
                          >
                            {isParent && (
                              <Typography
                                sx={{
                                  fontSize: '14',
                                  fontWeight: '700',
                                }}
                              >
                                {item.fullName}
                              </Typography>
                            )}

                            {item?.fromDay && (
                              <Typography
                                sx={{
                                  fontSize: '14',
                                  ...(countRender > 1 ? { fontWeight: '700' } : {}),
                                }}
                              >
                                {formatDate(moment(item.fromDay), formatDDMMYYYY)}
                              </Typography>
                            )}

                            {item?.toDay && (
                              <>
                                <Typography whiteSpace={'pre-wrap'}> - </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    fontSize: '14',
                                  }}
                                >
                                  {formatDate(moment(item.toDay), formatDDMMYYYY)}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8} alignSelf="center">
                      {countRender > 1 && (
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{
                            alignItems: 'center',
                            minHeight: '50px',
                            padding: '10px 0',
                          }}
                        >
                          <Grid item sx={{ width: '50%', paddingLeft: '30%' }}>
                            <Typography sx={styleList.typographyTotal}>
                              {handleDisplayLeaveType(item)}
                            </Typography>
                          </Grid>
                          <Grid item sx={{ width: '50%' }}>
                            <Typography sx={styleList.typographyTotal}>
                              {handleTextStatus(item?.reason)}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3} textAlign="end" alignSelf={'center'}>
                  <Grid item container xs={12} flexDirection="row" justifyContent="center">
                    {countRender > 1 && item.status?.value === ERequestStatus.PENDING && (
                      <>
                        {canAcceptPermission(positions, [Positions.ADMIN, Positions.PM]) ? (
                          <>
                            <Grid item>
                              <IconButton
                                sx={styleList.iconBtnApproval}
                                onClick={() => props.updateLeaveStatusCallback([item._id], true)}
                              >
                                <CheckCircleOutline />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                sx={styleList.iconBtnReject}
                                onClick={() => props.updateLeaveStatusCallback([item._id], false)}
                              >
                                <CancelOutlined />
                              </IconButton>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={getBox} timeout="auto" unmountOnExit>
                <Box>
                  <ListApprovalTreeComponent
                    key={index}
                    itemsCB={(item.children ?? []) as unknown as ApprovalLeaveDataProps[]}
                    isParent={false}
                    countRender={countRender}
                    updateLeaveStatusCallback={props.updateLeaveStatusCallback}
                  />
                </Box>
              </Collapse>
            </Fragment>
          );
        })
      ) : (
        <Grid container sx={styleList.gridItemNoData}>
          <Grid item>
            <NotFound name="No Data" minHeight={'0px'} fontSize="20px" />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default ListApprovalTreeComponent;
