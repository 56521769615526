export const setItem = (key: string, value: Object) =>
  localStorage.setItem(key, JSON.stringify(value));
export const getItem = (key: string) => JSON.parse(localStorage.getItem(key) || 'null');
export const removeItem = (key: string) => localStorage.removeItem(key);

export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: 'TOKEN',
  CURRENT_ID: 'CURRENT_ID',
  POSITIONS: 'POSITIONS',
};
