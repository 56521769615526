import { createSlice } from '@reduxjs/toolkit';
import { configStateI } from '../../constants/constants.interfaces';
import { getAllLevel, getAllPosition, getWorkingTypes, getAllStatus } from './actions';

interface ConfigState {
  isInit: boolean;
  listLevel: configStateI[];
  listPostion: configStateI[];
  listWorkingType: configStateI[];
  listStatus: configStateI[];
}

const initialState: ConfigState = {
  isInit: false,
  listLevel: [],
  listPostion: [],
  listStatus: [],
  listWorkingType: [],
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    activeInitConfigs: (state) => {
      state.isInit = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllLevel.fulfilled, (state, action) => {
      state.listLevel = action.payload;
    });
    builder.addCase(getAllPosition.fulfilled, (state, action) => {
      state.listPostion = action.payload;
    });
    builder.addCase(getWorkingTypes.fulfilled, (state, action) => {
      state.listWorkingType = action.payload;
    });
    builder.addCase(getAllStatus.fulfilled, (state, action) => {
      state.listStatus = action.payload;
    });
  },
});

export default configSlice.reducer;

export const { activeInitConfigs } = configSlice.actions;
