import { createTheme } from '@mui/material/styles';
import palette from '../assets/style/scss/_themes-var.module.scss';

export const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': palette.variableBlack,
          },
        },
      },
    },
  },
});
