import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import ViewContent from './viewContent';
import ViewHeader from './viewHeader';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getProjectById } from '../redux/actions';
import BaseLoading from '../../../components/Loading';

type Props = {};

const ViewProjects = (props: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.projectReducer.isLoading);
  useEffect(() => {
    id && dispatch(getProjectById(id));
  }, [dispatch, id]);

  return (
    <>
      {isLoading ? (
        <Box className="container-loading">
          <BaseLoading size={60} />
        </Box>
      ) : (
        <Box className="viewContainer">
          <ViewHeader />
          <ViewContent />
        </Box>
      )}
    </>
  );
};

export default ViewProjects;
