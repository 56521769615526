import React from 'react';
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import icons from 'constants/icons';
import '../userTask/style.scss';
import '../style.scss';
import { useState } from 'react';
import Buttons from '../buttons';
import BasePopup from '../../../components/popup';
import LeaveForm from '../../Dayoff/LeaveForm';
import { useAppSelector } from '../../../store/store';
import { canAcceptPermission, formatDate, handleAvatarSrc } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../constants/path';
import { ILeaveRequestResponseData } from '../../../types/dayOffType';
import { Positions } from '../../../constants/constants.interfaces';

interface IUserTask {
  isEdit?: boolean;
  state?: string;
  updateLeaveStatusCallback: (ids: string[], isApproval: boolean) => void;
}

const UserTask = (props: IUserTask) => {
  const { isEdit, state } = props;
  const { Visibility } = icons;
  const pendingLeaveRequest = useAppSelector((state) => state.requestReducer.pendingRequestList);
  const { positions } = useAppSelector((state) => state.authReducer);
  const [currentItem, setCurrentItem] = useState<ILeaveRequestResponseData>();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOpenViewPopup = (item: ILeaveRequestResponseData) => {
    setCurrentItem(item);
    setOpenPopup(true);
  };

  const handleCancel = () => {
    setOpenPopup(false);
  };

  const handleViewMoreClick = () => {
    canAcceptPermission(positions, [Positions.ADMIN, Positions.PM])
      ? navigate(PATH.APPROVALDS_LEAVE)
      : navigate(PATH.CALENDAR);
  };

  return (
    <Box className="dashboard-item-container">
      {pendingLeaveRequest.total != 0 ? (
        pendingLeaveRequest.data.map((item, index) => (
          <Box className="dashboard-my-user" key={index}>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Avatar
                  alt="Hoang Nguyen"
                  src={handleAvatarSrc(item)}
                  className="dashboard-my-task-avatar"
                  sx={{ width: 62, height: 62 }}
                />
              </Box>
              <Box className="dashboard-text">
                <Typography className="dashboard-user-name">{item.user?.fullName}</Typography>
                <Box className="dashboard-user-time">
                  <Typography className="dashboard-user-time-text">
                    {`Holiday on 
                  ${formatDate(item.fromDay as Date)} 
                  - 
                  ${formatDate(item.toDay as Date)} (pending approvals)`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              {isEdit ? (
                <Buttons handleApproveOrReject={props.updateLeaveStatusCallback} item={item} />
              ) : (
                <>
                  <BasePopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <LeaveForm
                      handleCancel={handleCancel}
                      textViewModel={'View Leave'}
                      cancelText={'Close'}
                      defaultValue={currentItem}
                    />
                  </BasePopup>
                  <Box className="dashboard-icon">
                    <IconButton onClick={() => handleOpenViewPopup(item)}>
                      <Visibility fontSize="small" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Box className="dashboard-no-leave-text">
          <Box className="dashboard-no-upcoming-leave-text">
            <Typography className="dashboard-user-name">No tasks</Typography>
          </Box>
        </Box>
      )}
      {pendingLeaveRequest?.total > 5 && (
        <Box className="dashboard-view-more">
          <Button
            variant="text"
            onClick={handleViewMoreClick}
            className="dashboard-view-more-button"
          >
            View more
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UserTask;
