import { createAsyncThunk } from '@reduxjs/toolkit';
import { BaseParamsI } from 'constants/interfaces';
import { instance } from 'services/api/api';
import { handleParamsUrl } from '../../constants';

const getAllConfig = (url: string, name: string) =>
  createAsyncThunk(name, async (params: BaseParamsI) => {
    const response = await instance.get(
      handleParamsUrl({
        baseUrl: url,
        searchName: 'search',
        searchNameByKey: 'searchKey',
        options: params,
      }),
    );
    return response.data;
  });

export const getAllLevel = getAllConfig('/configs/findValuesByKey', 'level/getAllLevel');
export const getAllPosition = getAllConfig('/configs/findValuesByKey', 'position/getAllPosition');
export const getWorkingTypes = getAllConfig('/configs/findValuesByKey', 'position/getWorkingTypes');
export const getAllStatus = getAllConfig('/configs/findValuesByKey', 'position/getAllStatus');
