import React from 'react';
const RightIcon = () => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.718048 11.08L5.29805 6.5L0.718048 1.91L2.12805 0.5L8.12805 6.5L2.12805 12.5L0.718048 11.08Z"
        fill="#13296F"
      />
    </svg>
  );
};

export default RightIcon;
