import { PATH } from 'constants/path';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from 'features/Login/redux/authSlice';
import { RootState } from 'store/store';
import { getItem, LOCAL_STORAGE_KEY } from '../utils/localStorage';

interface IProps {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: IProps) => {
  const dispatch = useDispatch();
  const auth = getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);

  let isAuthorized = true;
  if (!auth) {
    dispatch(logoutSuccess());
    isAuthorized = false;
  }
  return isAuthorized ? children : <Navigate to={PATH.LOGIN} replace={true} />;
};

export default PrivateRoute;
