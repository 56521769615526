import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'services/api/api';
import { IForgotPasswordFormValues, IResetPasswordData } from '../interface';
import { IException } from 'constants/constants.interfaces';

export const sendEmailForgotPasswordAuth = createAsyncThunk(
  'auth/sendEmailForgotPasswordAuth',
  async ({ email }: IForgotPasswordFormValues, { rejectWithValue }) => {
    try {
      const response = await instance.post(`/users/forgot_password`, {
        email,
      });
      return response.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);

export const resetPasswordAuth = createAsyncThunk(
  'auth/resetPasswordAuth',
  async ({ newPassword, token }: IResetPasswordData, { rejectWithValue }) => {
    try {
      const response = await instance.patch(`/users/reset_password`, {
        newPassword,
        token,
      });
      return response.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);
