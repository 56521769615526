import React from 'react';

// material-ui
import { Box } from '@mui/material';
import 'layouts/Header/components/LogoSection/style.scss';

//assets
import logo from 'assets/img/logo.png';

// ==============================|| MAIN LOGO ||============================== //

const logoText = 'HRM';

const LogoSection = () => (
  <Box>
    <div className="logo-section-box">
      <img src={logo} alt="Internal" height="50" width="50" className="logo-section-icon" />
      <h1 className="logo-section-text">{logoText}</h1>
    </div>
  </Box>
);

export default LogoSection;
