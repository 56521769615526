import { Chip, ChipProps } from '@mui/material';
import React from 'react';

export enum KeyColors {
  success = 'success',
  pending = 'pending',
  info = 'info',
  warning = 'warning',
  errors = 'errors',
  default = 'default',
}

export type ChipColorsType = keyof typeof KeyColors;

type ChipCustomProps = ChipProps & {
  customcolor: ChipColorsType;
};

const ChipBase: React.FC<ChipCustomProps> = (props: ChipCustomProps) => {
  const switchColor = () => {
    let sx = {};
    switch (props.customcolor) {
      case KeyColors.success:
        sx = {
          ...sxCustom.success,
        };
        break;
      case KeyColors.pending:
        sx = {
          ...sxCustom.pending,
        };
        break;
      case KeyColors.info:
        sx = {
          ...sxCustom.info,
        };
        break;
      case KeyColors.errors:
        sx = {
          ...sxCustom.errors,
        };
        break;
      case KeyColors.default:
        sx = {
          ...sxCustom.default,
        };
        break;

      default:
        sx = {
          ...sxCustom.warning,
        };
        break;
    }
    return sx;
  };
  return (
    <Chip
      {...props}
      sx={{
        ...props.sx,
        ...sxCustom.text400,
        ...switchColor(),
      }}
    />
  );
};

export default ChipBase;

const sxCustom = {
  success: {
    bgcolor: '#EBFAEB',
    color: '#279425',
  },
  pending: {
    bgcolor: '#ECF4FF',
    color: '#0A6BFC',
  },
  info: {
    bgcolor: '#E8DEF8',
    color: '#A500F2',
  },
  warning: {
    bgcolor: '#FFF4DF',
    color: '#CE740F',
  },
  text400: {
    fontSize: '14px',
    fontWeight: '400',
  },
  errors: {
    bgcolor: '#FFEDED',
    color: '#DD2C2C',
  },
  default: {
    color: '#F8A404',
    bgcolor: '#FFFFCC',
  },
};
