import React, { useEffect, useMemo, useState } from 'react';
import ListApprovalTreeComponent from './ListApprovalTreeComponent';
import ApprovalHeaderComponent from './ApprovalHeaderComponent';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import {
  approvalTimesheetsAsync,
  getTimesheetsByMonthAsync,
  rejectTimesheetsAsync,
} from '../redux/actions';
import BaseLoading from '../../../components/Loading';
import { TimesheetStatus } from '../../../constants/constants.interfaces';

export const CustomButton = styled(Button)({
  minWidth: '104px',
  height: '33px',
  borderRadius: '5px',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: '700',
  gap: '8px',
  textTransform: 'none',
});
interface ApprovalProps {}
const ApprovalsScreen: React.FC<ApprovalProps> = () => {
  const dispatch = useAppDispatch();
  const configsState = useAppSelector((state) => state.configReduce);
  const { listTimeSheets, approvalDateMOY } = useAppSelector((state) => state.timesheetReduce);
  const isLoading = useAppSelector((state) => state.timesheetReduce.isLoading);
  const [status, setStatus] = useState<string>('');

  const timeSheetData = useMemo(() => listTimeSheets, [listTimeSheets]);
  const pendingId =
    configsState.listStatus.find((item) => item.value === TimesheetStatus.PENDING)?._id ?? '';

  useEffect(() => {
    dispatch(getTimesheetsByMonthAsync({ date: new Date(), status: pendingId, workingType: '' }));
  }, [dispatch, pendingId]);

  const handleSubmitTimesheet = async (ids: string[], isApproval: boolean) => {
    if (isApproval) {
      await dispatch(approvalTimesheetsAsync(ids));
    } else {
      await dispatch(rejectTimesheetsAsync(ids));
    }

    return dispatch(
      getTimesheetsByMonthAsync({ date: approvalDateMOY, status: status, workingType: '' }),
    );
  };

  const handleCallbackState = (status: string) => {
    return setStatus(status);
  };

  const countRender = 0;
  return (
    <>
      {isLoading ? (
        <Box className="container-loading">
          <BaseLoading size={60} />
        </Box>
      ) : (
        <>
          <ApprovalHeaderComponent callBack={handleCallbackState} />
          <ListApprovalTreeComponent
            itemsCB={timeSheetData}
            isParent={true}
            countRender={countRender}
            submitTimesheetCallback={handleSubmitTimesheet}
          />
        </>
      )}
    </>
  );
};

export default ApprovalsScreen;
