import { Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { Item } from '.';
import { numberToHours } from '../../constants';
interface WeekItemProps {
  weekName: string;
  hours: string;
  date: string;
}
const ListWeekScreen: React.FC<WeekItemProps> = (props: WeekItemProps) => {
  return (
    <Fragment>
      <Grid item container direction="column">
        <Item sx={{ p: 1 }}>
          <Typography className="buttonTextTitle-color-black">{props.weekName}</Typography>
          <Typography className="text-title-date-color-black">{props.date}</Typography>
        </Item>
        <Item sx={{ height: 23, marginTop: '4px' }}>
          <Typography className="buttonTextTitle-child-center">
            Total: {numberToHours(Number(props.hours) * 60)}
          </Typography>
        </Item>
      </Grid>
    </Fragment>
  );
};

export default ListWeekScreen;
