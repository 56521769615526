import { createAsyncThunk } from '@reduxjs/toolkit';
import { BaseParamsI } from 'constants/interfaces';
import { instance } from 'services/api/api';
import { IChangeAvatarType, IUserUpdateType } from 'types/userType';
import { handleParamsUrl, limitDefault, offsetDefault } from '../../../constants';
import { IChangePassword } from '../../ChangePassword';
import { IException } from '../../Dayoff/redux/actions';
import { IFormEmployees } from '../interfaces';

const handleUserUrl = (url: string, name: string) =>
  createAsyncThunk(name, async (params: BaseParamsI) => {
    const response = await instance.get(
      handleParamsUrl({
        baseUrl: url,
        searchName: 'name',
        options: params,
        positionsName: 'positions',
      }),
    );
    return response.data;
  });

export const getAllUser = handleUserUrl('users/get-all-users', 'user/allUser');
export const findByUser = handleUserUrl('users/get-all-users', 'user/findByUser');
export const findPmByUser = handleUserUrl('users/get-all-users', 'user/findPmByUser');
export const searchByUser = handleUserUrl('users/get-all-users', 'user/searchByUser');

export const getOwnProfile = createAsyncThunk('user/getProfile', async () => {
  const response = await instance.get(`users/get-own-profile`);
  return response.data;
});
export const deleteUserThunk = createAsyncThunk('users/delete', async (_id: string) => {
  await instance.delete(`users/delete/${_id}`);
  const response = await instance.get(
    handleParamsUrl({
      baseUrl: 'users/get-all-users',
      searchName: 'name',
      options: {},
    }),
  );
  return response.data;
});

export const creatUser = createAsyncThunk(
  'user/creatUser',
  async ({ image, ...body }: IFormEmployees, { dispatch, rejectWithValue }) => {
    try {
      const signupResponse = await instance.post(`users/sign-up`, body);
      if (signupResponse && image) {
        dispatch(uploadAvatar({ id: signupResponse.data.id as unknown as string, image }));
      }
      const response = await instance.get(
        handleParamsUrl({
          baseUrl: 'users/get-all-users',
          searchName: 'name',
          options: {
            limit: limitDefault,
            offset: offsetDefault,
          },
        }),
      );
      return response.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ _id, ...body }: IFormEmployees) => {
    await instance.patch(`users/update-user/${_id}`, body);
    const response = await instance.get(
      handleParamsUrl({
        baseUrl: 'users/get-all-users',
        searchName: 'name',
        options: {},
      }),
    );
    return response.data;
  },
);
export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async ({ _id, ...body }: IUserUpdateType) => {
    await instance.patch(`users/update-user/${_id}`, body);
    const response = await instance.get(`users/get-own-profile`);
    return response.data;
  },
);

export const changeStatusEmployee = createAsyncThunk(
  'user/changeStatusEmployee',
  async (id: string) => {
    const response = await instance.patch(`users/offboarding/${id}`);
    return response.data;
  },
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (body: IChangePassword, { rejectWithValue }) => {
    try {
      const response = await instance.patch(`users/change-password`, body);
      return response.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);

export const getUserOnBirthDay = createAsyncThunk('user/getUserOnBirthDay', async () => {
  const response = await instance.get(`users/get-birthday`);
  return response.data;
});

export const getAllMentor = createAsyncThunk('users/getallMentor', async (params: BaseParamsI) => {
  const response = await instance.get(
    handleParamsUrl({
      baseUrl: '/users/get-onboarding-mentor',
      searchName: 'name',
      options: params,
    }),
  );
  return response.data;
});

export const getUserDetails = createAsyncThunk('user/getUserDetails', async (id: string) => {
  const response = await instance.get(`users/get-user/${id}`);
  return response.data;
});

export const uploadAvatar = createAsyncThunk(
  'users/uploadAvatar',
  async ({ id, image }: IChangeAvatarType, { dispatch, rejectWithValue }) => {
    try {
      const data = new FormData();
      data.append('avatar', image, image.name);
      const response = await instance.post(`users/change-avatar/${id}`, data);
      dispatch(getUserAvatar());
      return response.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);

export const getUserAvatar = createAsyncThunk('users/getUserAvatar', async () => {
  const response = await instance.get(`users/user-avatar`);
  return response.data;
});
