import { Routes, Route, Navigate } from 'react-router-dom';
import Auth from 'features/Login';
import ResetPasswordPage from 'features/forgotPassword';
import ConfirmEmailPage from 'features/forgotPassword/cormfirmEmailPage';
import ChangePasswordPage from 'features/forgotPassword/changePassword';
import ResetPasswordSuccessPage from '../features/forgotPassword/resetPasswordSuccess';
import PrivateRoute from 'routes/privateRoute';
import Home from 'features/Home';
import { PATH } from 'constants/path';
import { ROUTERS } from 'constants/router';
import { IRouter } from 'types/globalType';
import SessionExpired from '../components/sessionExpired';

const rootRouter = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate to={PATH.LOGIN} />} />
        <Route path={PATH.LOGIN} element={<Auth />} />
        <Route path={PATH.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={PATH.CONFIRM_EMAIL} element={<ConfirmEmailPage />} />
        <Route path={PATH.RESET_PASSWORD_TOKEN} element={<ChangePasswordPage />} />
        <Route path={PATH.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccessPage />} />
        <Route path={PATH.SESSION_EXPIRED} element={<SessionExpired />} />
        <Route
          path={PATH.HOME}
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        >
          {ROUTERS.map((item: IRouter) => (
            <Route key={item.id} path={item.navigate} element={item.element as JSX.Element} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default rootRouter;
