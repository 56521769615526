import { Box, Typography } from '@mui/material';
import React from 'react';
import 'features/formProjectAndUser/formTotal/style.scss';
import { useAppSelector } from '../../../store/store';
import { canAcceptPermission } from '../../../constants';
import { Positions } from '../../../constants/constants.interfaces';
import { NameTablesE } from '../../../types/formProjectAndUserType';

interface ITotalList {
  namePage?: string;
  total: number;
}

const TotalList = (props: ITotalList) => {
  const { namePage, total } = props;
  const { positions } = useAppSelector((state) => state.authReducer);
  const isRoleForTotal =
    (canAcceptPermission(positions, [Positions.ADMIN]) && namePage === NameTablesE.PROJECTS) ||
    (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR]) &&
      namePage === NameTablesE.EMPLOYEES);

  return (
    <>
      {isRoleForTotal ? (
        <Box className="box-total">
          <Box className="box-total-container">
            <Box className="box-total-title">
              <Typography className="box-total-title-text">Total {namePage}</Typography>
            </Box>
            <Box className="box-total-content">
              <Typography className="box-total-content-length">{total}</Typography>
              <Typography className="box-total-content-name">{namePage}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default TotalList;
