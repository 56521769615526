import { createAsyncThunk } from '@reduxjs/toolkit';
import { Positions } from 'constants/constants.interfaces';
import moment from 'moment';
import { instance } from 'services/api/api';
import { IRequestCreateType, IRequestUpdateType } from 'types/dayOffType';
import { canAcceptPermission, formatDate, formatISODefault } from '../../../constants';
import { getItem, LOCAL_STORAGE_KEY } from '../../../utils/localStorage';
import { SearchLeaveByMonthI } from '../approvals/ApprovalHeaderComponent';

export enum ERequestStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export interface IException {
  response: { [key: string]: string };
}

export interface ICreateRequestLeave extends IRequestCreateType {
  date: string;
}

export const getAllRequest = createAsyncThunk('request/get-all-request', async (date: string) => {
  const response = await instance.get(`request/get-all-request?date=${date}`);
  return response.data;
});

export const getRequestByTeam = createAsyncThunk(
  'request/get-request-by-team',
  async (date: string) => {
    const response = await instance.get(`request/get-members-request-leave-by-pm?date=${date}`);
    return response.data;
  },
);

export const createRequestLeave = createAsyncThunk(
  'request/create-request',
  async (body: ICreateRequestLeave, { rejectWithValue }) => {
    try {
      const positions = getItem(LOCAL_STORAGE_KEY.POSITIONS);
      await instance.post(`request/create-request`, body);
      const getLeaveResponse = !canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])
        ? await instance.get(`request/get-members-request-leave-by-pm?date=${body.date}`)
        : await instance.get(`request/get-all-request?date=${body.date}`);
      return getLeaveResponse.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);

export const updateRequestStatus = createAsyncThunk(
  'request/update-request',
  async ({ _id, status }: IRequestUpdateType) => {
    await instance.patch(`request/update-request/${_id}`, { status });
    const response = await instance.get(`request/get-all-request`);
    return response.data;
  },
);

export const getTodayLeave = createAsyncThunk('request/get-today-leave', async () => {
  const response = await instance.get(`request/get-today-leaves`);
  return response.data;
});

export const getUsersMonthlyLeave = createAsyncThunk(
  `request/get-users-monthly-leave`,
  async ({ date, status }: SearchLeaveByMonthI) => {
    let url = `date=${date}`;
    if (status) url += `&status=${status}`;
    const response = await instance.get(`request/get-users-monthly-leave?${url}`);
    return response.data;
  },
);

export const getDashboardMyTaskByUser = createAsyncThunk(`dashboard/my-task-user`, async () => {
  const date = formatDate(moment(), formatISODefault);
  const response = await instance.get(`request/dashboard-get-user-request?date=${date}`);
  return response.data;
});

export const getDashboardMyTaskByPM = createAsyncThunk(`dashboard/my-task-pm`, async () => {
  const date = formatDate(moment(), formatISODefault);
  const response = await instance.get(`request/dashboard-get-members-request-leave?date=${date}`);
  return response.data;
});

export const getUserUpcomingLeave = createAsyncThunk(`dashboard/user-upcoming-leave`, async () => {
  const date = formatDate(moment(), formatISODefault);
  const response = await instance.get(`request/dashboard-get-upcoming-leave?date=${date}`);
  return response.data;
});

export const approveLeaveAsync = createAsyncThunk('leave/approve', async (ids: string[]) => {
  const response = await instance.patch('request/approve-leave-request', { ids });
  return response.data;
});

export const rejectLeaveAsync = createAsyncThunk('leave/reject', async (ids: string[]) => {
  const response = await instance.patch('request/reject-leave-request', { ids });
  return response.data;
});

export const deleteRequestThunk = createAsyncThunk('request/delete', async (_id: string) => {
  // eslint-disable-next-line no-undef
  if (_id) await instance.delete(`request/remove-request/${_id}`);
  return _id;
});

export const getAllPendingRequest = createAsyncThunk('pending/pending-request', async () => {
  const response = await instance.get(`request/get-all-pending-request`);
  return response.data;
});
