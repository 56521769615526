import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from '@mui/material';
import BaseButton from 'components/button';
import icons from 'constants/icons';
import React, { useEffect, useState } from 'react';
import { canAcceptPermission, limitDefault, offsetDefault } from '../../../constants';
import useDebounce from 'hooks/useDebounce';
import FormProjectItem from '../formItem/FormProjectItem';
import TotalList from '../formTotal';
import TitleRow from '../titleRow/titleRow';
import './style.scss';
import { NameTablesE } from 'types/formProjectAndUserType';
import NotFound from 'components/notFound';
import { RootState, useAppSelector } from '../../../store/store';
import BaseLoading from 'components/Loading';
import { Link } from 'react-router-dom';
import { Positions } from 'constants/constants.interfaces';
import { IFormProjectItemProps, IFormProjectsTableProps } from '../interface';
import { PATH } from 'constants/path';

const FormProjectsTable = ({
  title,
  listProjects,
  namePage,
  callbackForm,
  totalPage,
  total,
  buttonName,
}: IFormProjectsTableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(offsetDefault);
  const [textSearch, setSearchState] = useState<string>('');
  const { AddProjectIcons } = icons;
  const isLoadingTableEmployees = useAppSelector((state: RootState) => state.userReducer.isLoading);
  const isLoadingTableProject = useAppSelector(
    (state: RootState) => state.projectReducer.isLoading,
  );
  const { positions } = useAppSelector((state) => state.authReducer);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage);
    callbackForm({ search: debouncedTextSearch, limit: limitDefault, offset: newPage });
  };
  const debouncedTextSearch = useDebounce<string>(textSearch, 500);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchState(event.target.value);
  };
  const resetState = () => {
    setCurrentPage(offsetDefault);
  };

  useEffect(() => {
    if (debouncedTextSearch) {
      callbackForm({
        search: debouncedTextSearch,
      });
      resetState();
    } else
      callbackForm({
        search: '',
      });
  }, [debouncedTextSearch]);

  const isSearch =
    !canAcceptPermission(positions, [Positions.ADMIN, Positions.HR]) &&
    namePage === NameTablesE.PROJECTS
      ? false
      : true;
  const isAddEmployees =
    (canAcceptPermission(positions, [Positions.HR]) && namePage === NameTablesE.PROJECTS) ||
    canAcceptPermission(positions, [Positions.PM])
      ? false
      : true;

  return (
    <>
      {isLoadingTableProject || isLoadingTableEmployees ? (
        <Box className="container-loading">
          <BaseLoading size={60} />
        </Box>
      ) : (
        <>
          <TotalList namePage={namePage} total={total} />
          <Box className="box-project">
            <TableContainer>
              {isSearch ? (
                <Box className="box-tablecontainer-search">
                  <Box>
                    <TextField
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      size="small"
                      type="search"
                      value={textSearch}
                      onChange={handleSearch}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  </Box>
                  <Box>
                    {isAddEmployees ? (
                      <Link to={PATH.PROJECT_CREATE} className="style-button-add">
                        <BaseButton
                          name={buttonName}
                          onClick={() => {}}
                          icons={<AddProjectIcons />}
                        />
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              {listProjects.length > 0 ? (
                <Table>
                  <TableHead>
                    <TitleRow title={title} />
                  </TableHead>
                  <TableBody>
                    {listProjects.map((item: IFormProjectItemProps, index: number) => (
                      <FormProjectItem
                        key={index}
                        name={item.name}
                        contact={item.contact}
                        date={item.date}
                        idProject={item.idProject}
                        namePage={namePage}
                        callback={resetState}
                        level={item.level}
                      />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <NotFound name={'No Data'} />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[limitDefault]}
              component="div"
              count={totalPage}
              rowsPerPage={limitDefault}
              page={currentPage}
              onPageChange={handleChangePage}
              labelDisplayedRows={({ page }) => `Page ${page + 1}`}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default FormProjectsTable;
