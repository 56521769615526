import { createSlice } from '@reduxjs/toolkit';
import toastConfig from 'utils/toastConfig';
import { toast } from 'react-toastify';
import {
  createRequestLeave,
  deleteRequestThunk,
  getAllPendingRequest,
  getAllRequest,
  getUsersMonthlyLeave,
  getRequestByTeam,
  getDashboardMyTaskByUser,
  getDashboardMyTaskByPM,
  getTodayLeave,
  getUserUpcomingLeave,
} from 'features/Dayoff/redux/actions';
import { ILeaveRequestResponseData } from 'types/dayOffType';
import { LeaveStateI } from '../interfaces';
import moment from 'moment';
import { caculateWeeklyNow } from '../../../constants';
import { MESSAGES } from '../../../constants/constants.message';

const dateNow = moment();
const initialState: LeaveStateI = {
  requestList: [],
  usersMonthlyLeave: [],
  pendingRequestList: {
    data: [],
    total: 0,
  },
  approvedRequestList: {
    data: [],
    total: 0,
  },
  todayLeave: {
    allDay: [],
    morning: [],
    afternoon: [],
  },
  approvalDateMOY: dateNow,
  isRefresh: false,
  isLoading: false,
};

export interface ErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}

const requestSlice = createSlice({
  name: 'dayoff',
  initialState,
  reducers: {
    addMonthOfYear: (state, actions) => {
      const numberOfMonth = actions.payload;
      state.approvalDateMOY = caculateWeeklyNow(numberOfMonth, dateNow, 'months').now;
    },
    refreshData: (state, actions) => {
      state.isRefresh = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRequest.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllRequest.fulfilled, (state, action) => {
        state.requestList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllRequest.rejected, (state, action) => {
        state.isLoading = false;
        toast.error('Cannot get all requests');
      })
      .addCase(getUsersMonthlyLeave.fulfilled, (state, action) => {
        state.usersMonthlyLeave = action.payload;
      })
      .addCase(getAllPendingRequest.fulfilled, (state, action) => {
        state.requestList = action.payload;
      })

      .addCase(createRequestLeave.fulfilled, (state, action) => {
        state.requestList = action.payload;
        toastConfig.toastSuccess(`${MESSAGES.CREATE_SUCCESSFULLY}`);
      })
      .addCase(createRequestLeave.rejected, (state, action) => {
        state.isLoading = false;
        toastConfig.toastError((action.payload as ErrorResponse).message);
      })
      .addCase(deleteRequestThunk.fulfilled, (state, action) => {
        state.pendingRequestList.data = state.pendingRequestList.data.filter(
          (user: ILeaveRequestResponseData) => user._id !== action.payload,
        );
        toastConfig.toastSuccess('Delete successfully');
      })
      .addCase(deleteRequestThunk.rejected, () => {
        toastConfig.toastError('Cannot delete request');
      })
      .addCase(getDashboardMyTaskByUser.fulfilled, (state, action) => {
        state.pendingRequestList = action.payload;
      })
      .addCase(getDashboardMyTaskByPM.fulfilled, (state, action) => {
        state.pendingRequestList = action.payload;
      })
      .addCase(getUserUpcomingLeave.fulfilled, (state, action) => {
        state.approvedRequestList = action.payload;
      });

    builder
      .addCase(getRequestByTeam.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRequestByTeam.fulfilled, (state, action) => {
        state.requestList = action.payload;
        state.isLoading = false;
      })
      .addCase(getRequestByTeam.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTodayLeave.fulfilled, (state, action) => {
        state.todayLeave = action.payload;
      });
  },
});

export const { addMonthOfYear, refreshData } = requestSlice.actions;
export default requestSlice.reducer;
