import { Avatar, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IUserInfo } from 'types/userType';
import '../userEvent/style.scss';
import '../style.scss';
import avatarUser from 'assets/img/avatar.png';
import { formatDate } from '../../../constants';
import icons from '../../../constants/icons';
const UserEvents = () => {
  const listUserOnBirthday = useSelector(
    (state: RootState) => state.userReducer.listUserOnBirthday,
  );
  const randomIcon = (listIconBirthDay: string[]) => {
    return listIconBirthDay[Math.floor(Math.random() * listIconBirthDay.length)];
  };
  const listIconBirthDay = [icons.balloons, icons.confetti, icons.confettis, icons.fireworks];
  return (
    <Box>
      {listUserOnBirthday.length > 0 ? (
        <Box className="dashboard-item-container">
          {listUserOnBirthday.map((item: IUserInfo, index: number) => (
            <Box className="dashboard-event" key={index}>
              <Box className="dashboard-on-event-user">
                <Box className="dashboard-on-event-user-content">
                  <Avatar
                    src={item.avatar ? item.avatar.src : avatarUser}
                    className="dashboard-on-event-user-avatar"
                    style={style.avatar}
                  />
                  <Box>
                    <Typography className="dashboard-on-event-userName">{item.fullName}</Typography>
                    <Typography className="dashboard-on-event-brithday">
                      {formatDate(item.dateOfBirth as Date)}
                    </Typography>
                  </Box>
                </Box>
                <img src={randomIcon(listIconBirthDay)} alt="avatar" />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className="dashboard-my-user-event">
          <Typography className="dashboard-event-text">No upcoming events !</Typography>
        </Box>
      )}
    </Box>
  );
};

const style = {
  avatar: {
    width: '42px',
  },
};

export default UserEvents;
