import { Avatar, Box, Grid, Typography } from '@mui/material';
import ChipBase, { ChipColorsType, KeyColors } from '../../../../components/chip';
import { defaultAvatar, formatDate } from '../../../../constants';
import { ProjectStatusEnum } from '../../../../constants/constants.interfaces';
import { useAppSelector } from '../../../../store/store';
import './style.scss';

type Props = {};
type ChipStatus = {
  status: string;
};

const ViewContent = (props: Props) => {
  const { clientName, state, member, projectManager, endDate, startDate } = useAppSelector(
    (state) => state.projectReducer.projectDetail,
  );
  const CustomChipByStatus = (props: ChipStatus) => {
    let color: ChipColorsType;
    switch (props.status) {
      case ProjectStatusEnum.DONE:
        color = KeyColors.success;
        break;
      case ProjectStatusEnum.RUNNING:
        color = KeyColors.pending;
        break;
      case ProjectStatusEnum.INCOMING:
        color = KeyColors.warning;
        break;

      default:
        color = KeyColors.warning;
        break;
    }
    return (
      <ChipBase
        label={`${props.status}`}
        sx={style.chipStatus}
        variant="filled"
        customcolor={color}
      />
    );
  };
  return (
    <Box className="viewContentProject">
      <Box className="style-content">
        <Typography variant="h6" className="typographyH6">
          Project Information
        </Typography>
        <Box className="rows-content">
          <Grid container style={style.gridContainer} spacing={2}>
            <Grid item xs={4}>
              <Typography className="style-title-view">Client Name</Typography>
              <Typography className="style-data-view">{clientName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="style-title-view">Start Date</Typography>
              <Typography className="style-data-view">{formatDate(startDate as Date)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="style-title-view">End Date</Typography>
              <Typography className="style-data-view">
                {endDate ? <>{formatDate(endDate as Date)}</> : <>N/A</>}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="style-title-view">Status</Typography>
              <CustomChipByStatus status={state} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="style-content-manager">
        <Box className="style-box-manager">
          <Typography variant="h6" className="typographyH6">
            Project Manager
          </Typography>
          <Box className="style-project-manager">
            <Box className="style-div-manager">
              <Box className="style-name-manager">
                <Avatar
                  style={style.avatar}
                  alt=""
                  src={projectManager.avatar?.src || defaultAvatar}
                />
                <Typography style={style.fullName}>{projectManager.fullName}</Typography>
              </Box>
              <Box>
                {projectManager.level ? (
                  <ChipBase
                    label={projectManager.level.value}
                    sx={style.chipLevel}
                    variant="filled"
                    customcolor="warning"
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="style-content-member">
          <Typography variant="h6" className="typographyH6">
            Project Members
          </Typography>
          <Box>
            {member.map((item, index) => {
              return (
                <Box className="style-div-member" key={index}>
                  <Box className="style-name-member">
                    <Avatar style={style.avatar} alt="" src={item.avatar?.src || defaultAvatar} />
                    <Typography style={style.fullName}>{item.fullName}</Typography>
                  </Box>
                  <Box>
                    {item.level ? (
                      <ChipBase
                        label={item.level.value}
                        sx={style.chipLevel}
                        variant="filled"
                        customcolor="warning"
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const style = {
  chipLevel: {
    background: '#FFE7D1',
    color: '#F87700',
    borderRadius: '8px',
    marginRight: '16px',
  },
  chipStatus: {
    borderRadius: '8px',
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  fullName: {
    paddingLeft: '16px',
  },
  gridContainer: {
    padding: '10px 0px',
  },
};
export default ViewContent;
