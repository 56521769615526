import { Button, capitalize, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Icons from 'constants/icons';
import { formatDate } from '../../../constants';
import React, { useCallback, useEffect, useState } from 'react';
import ControllerSelect from '../../../components/inputFromSelect';
import { formatMMMMYYYY } from '../../../constants/constants.timeFormat';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { IKeyState } from '../../../constants/interfaces';
import { addMonthOfYear, refreshData } from '../redux/timesheetSlice';
import { getTimesheetsByMonthAsync } from '../redux/actions';
import * as moment from 'moment';
import { styles } from '../styles';
import icons from 'constants/icons';
import { TimesheetStatus } from '../../../constants/constants.interfaces';

export interface SearchTimesheetStateI {
  workingType: string;
  status: string;
  date: Date | moment.Moment;
}

type Props = {
  callBack: (status: string) => void;
};

const ApprovalHeaderComponent = ({ callBack }: Props) => {
  const configsState = useAppSelector((state) => state.configReduce);
  const dispatch = useAppDispatch();
  const { approvalDateMOY } = useAppSelector((state) => state.timesheetReduce);
  const listStatusCustom = configsState.listStatus.map((item) => ({
    key: item._id,
    value: capitalize(item.value.toLowerCase()),
  }));
  const itemPending = listStatusCustom.find(
    (item) => item.value.toLowerCase() === TimesheetStatus.PENDING.toLowerCase(),
  );

  const useFormState = useForm<SearchTimesheetStateI>({
    defaultValues: {
      workingType: '',
      status: itemPending?.key ?? '',
    },
  });

  const { KeyboardArrowDownIcon } = icons;
  const [numberMOY, setNumberMOY] = useState<number>(0);

  const { handleSubmit } = useFormState;

  const handleAction = useCallback(
    (value: number) => {
      dispatch(addMonthOfYear(value));
      setNumberMOY(value);
    },
    [dispatch],
  );

  useEffect(() => {
    handleAction(0);
  }, [handleAction]);

  const handleSearchTimesheet = handleSubmit((data: SearchTimesheetStateI) => {
    callBack(data.status);
    dispatch(refreshData(true));
    dispatch(getTimesheetsByMonthAsync({ ...data, date: approvalDateMOY }));
  });

  const btnAction = (isNext: boolean) => {
    if (isNext) {
      handleAction(numberMOY + 1);
      return;
    }
    handleAction(numberMOY - 1);
  };

  const defaultStatus = [{ key: '', value: 'All' }] as IKeyState[];

  const handleCapitalize = (string: string) => {
    return capitalize(string.toLocaleLowerCase());
  };

  const defaultStatusApprovals = configsState.listStatus
    .map((status) => {
      const value = handleCapitalize(status.value);
      return {
        key: status._id,
        value: value,
      };
    })
    .find((item) => item.value === handleCapitalize(TimesheetStatus.PENDING));

  useEffect(() => {
    defaultStatusApprovals && callBack(defaultStatusApprovals.key);
  }, []);

  return (
    <FormProvider {...useFormState}>
      <Grid container sx={styles.headerContainer}>
        <Grid item container xs={10} sx={styles.headerGridItem}>
          <Grid item>
            <Button variant="outlined" sx={styles.button} onClick={() => btnAction(false)}>
              <Icons.LeftIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" className="buttonTime" sx={styles.buttonWeekly}>
              <Typography className="buttonText" textTransform={'none'}>
                {formatDate(approvalDateMOY, formatMMMMYYYY)}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              sx={styles.button}
              size="small"
              onClick={() => btnAction(true)}
            >
              <Icons.RightIcon />
            </Button>
          </Grid>
          <Grid item>
            <ControllerSelect
              name="workingType"
              options={([] as IKeyState[]).concat(
                defaultStatus,
                configsState.listWorkingType.map((item) => ({
                  key: item._id,
                  value: item.value,
                })),
              )}
              popupIcon={<KeyboardArrowDownIcon />}
              getOptionLabel={(option) => (option as IKeyState).value || ''}
              sx={{
                ...styles.controllerSelect,
                margin: '0 10px',
              }}
              size="small"
              label="Working Type"
              defaultValues={{ key: '', value: 'All' }}
            />
          </Grid>
          <Grid item>
            <ControllerSelect
              name="status"
              options={([] as IKeyState[]).concat(defaultStatus, listStatusCustom)}
              defaultValue={itemPending ?? {}}
              popupIcon={<KeyboardArrowDownIcon />}
              sx={styles.controllerSelect}
              size="small"
              getOptionLabel={(option) => (option as IKeyState).value || ''}
              label="Status"
              defaultValues={defaultStatusApprovals}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className="buttonShowToday"
            style={styles.buttonApply}
            onClick={handleSearchTimesheet}
          >
            <Typography className="buttonTextTitle-color-paper">Apply</Typography>
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
export default ApprovalHeaderComponent;
