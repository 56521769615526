export const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  avatar: { width: 24, height: 24 },
  arrow: { color: '#5F5446' },
  chipContainer: {
    borderRadius: '8px',
    minWidth: '124px',
    height: '32px',
  },
  typography400: {
    fontSize: '14px',
    fontWeight: '400',
  },
  gridItemNoData: {
    border: '1px solid #DAE1F5',
    height: '60px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    padding: '8px 0 8px 0',
    flexDirection: 'row',
    border: '1px solid #DAE1F5',
    backgroundColor: '#FFFFFF',
  },
  gridItemTextDetail: {
    color: '#5F5446',
    minWidth: '342px',
    maxWidth: '422px',
    fontSize: '14px',
    textAlign: 'justify',
    lineHeight: 1.5,
    workWrap: 'break-word',
    overFlowWrap: 'break-word',
  },
  typographyTotal: {
    borderRadius: '8px',
    minWidth: '80px',
    fontSize: '14px',
    fontWeight: '700',
  },
  btnReject: {
    bgcolor: '#FF2626',
    '&:hover': {
      backgroundColor: '#FF2626',
    },
  },
  btnApproval: {
    bgcolor: '#2AAA8A',
    '&:hover': {
      backgroundColor: '#2AAA8A',
    },
  },
  iconBtnReject: {
    color: '#DD2C2C',
  },
  iconBtnApproval: {
    color: '#2AAA8A',
  },

  headerContainer: {
    width: '100%',
    height: '104px',
    borderRadius: '6px',
    border: '2px solid #ECEEF7',
    backgroundColor: '#FFFFFF',
    padding: '0 24px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headerGridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: {
    width: '55px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  buttonWeekly: {
    height: '40px',
  },
  buttonApply: {
    width: '109px',
    height: '40px',
  },
  controllerSelect: {
    width: '164px',
    height: '40px',
  },
  projectSelect: {
    width: '290px',
    height: '40px',
  },
  listProjectByReport: {
    width: '490px',
    marginLeft: '10px',
    position: 'absolute',
    zIndex: 3,
    marginTop: '-20px',
  },
  buttonNormal: {
    height: '5px',
    display: 'flex',
    alignSelf: 'center',
    marginRight: '10px',
    background: '#ECF4FF',
  },
  buttonBonus: {
    height: '5px',
    display: 'flex',
    alignSelf: 'center',
    marginRight: '10px',
    background: '#FFF4DF',
  },
  buttonOT: {
    height: '5px',
    display: 'flex',
    alignSelf: 'center',
    marginRight: '10px',
    background: '#E8DEF8',
  },
};
