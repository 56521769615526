import React, { useMemo } from 'react';
import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import { default as Icons, default as icons } from 'constants/icons';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { formatDate, canAcceptPermission } from '../../../constants';
import { formatMMMMYYYY } from '../../../constants/constants.timeFormat';
import { BaseParamsI, IKeyState } from '../../../constants/interfaces';
import { Positions } from '../../../constants/constants.interfaces';
import { requiredFieldRules, validateFieldNameRequired } from '../../../constants/rules';
import useDebounce from '../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { findByProject, findPmProject } from '../../Projects/redux/actions';
import { getMonthlyReport } from '../redux/actions';
import { addMonthOfYear } from '../redux/timesheetSlice';
import { styles } from '../styles';

export interface IFormReport {
  project: string[];
  date: string;
  params: BaseParamsI;
}
type Props = {
  callBack: (data: string[]) => void;
};

const ReportHeaderComponent = ({ callBack }: Props) => {
  const { approvalDateMOY } = useAppSelector((state) => state.timesheetReduce);
  const { projectList } = useAppSelector((state) => state.projectReducer);
  const { positions } = useAppSelector((state) => state.authReducer);
  const user = useAppSelector((state) => state.userReducer);
  const [numberMOY, setNumberMOY] = useState<number>(0);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [searchProject, setSearchProject] = useState<string[]>([]);

  const debounceTextSearch = useDebounce<string>(valueSearch, 1000);
  const today = new Date().toISOString();

  const configProjectList = useMemo(
    () =>
      projectList
        .filter((item) => {
          if (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])) {
            return item;
          } else if (canAcceptPermission(positions, [Positions.PM])) {
            return item?.projectManager?._id === user.currentProfile?._id;
          }
        })
        .map((item) => {
          return {
            key: item?._id,
            value: item?.projectName,
          };
        }),
    [projectList],
  );

  const dispatch = useAppDispatch();
  const methods = useForm<IFormReport>({
    defaultValues: { project: [], date: today },
  });
  const { handleSubmit, control } = methods;

  const handleAction = useCallback(
    (value: number) => {
      dispatch(addMonthOfYear(value));
      setNumberMOY(value);
    },
    [dispatch],
  );

  useEffect(() => {
    handleAction(0);
  }, [handleAction]);

  useEffect(() => {
    callBack(searchProject);
  }, [searchProject]);

  const handleSearchReport = handleSubmit((data: IFormReport) => {
    setSearchProject(data.project);
    const limitReport = data.project.length;
    const date = moment(approvalDateMOY).toISOString();
    dispatch(getMonthlyReport({ ...data, date, params: { limit: limitReport } }));
  });

  const btnAction = (isNext: boolean) => {
    if (isNext) {
      handleAction(numberMOY + 1);
      return;
    }
    handleAction(numberMOY - 1);
  };

  useEffect(() => {
    if (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])) {
      dispatch(findByProject({ search: debounceTextSearch }));
    } else if (canAcceptPermission(positions, [Positions.PM])) {
      dispatch(findPmProject({ search: debounceTextSearch }));
    }
  }, [dispatch, debounceTextSearch]);

  return (
    <FormProvider {...methods}>
      <Grid container sx={styles.headerContainer}>
        <Grid item container xs={10} sx={styles.headerGridItem}>
          <Grid item>
            <Button variant="outlined" sx={styles.button} onClick={() => btnAction(false)}>
              <Icons.LeftIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" className="buttonTime" sx={styles.buttonWeekly}>
              <Typography className="buttonText" textTransform={'none'}>
                {formatDate(approvalDateMOY, formatMMMMYYYY)}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              sx={styles.button}
              size="small"
              onClick={() => btnAction(true)}
            >
              <Icons.RightIcon />
            </Button>
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="project"
              rules={validateFieldNameRequired('Project')}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Autocomplete
                  {...{ onChange }}
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={configProjectList}
                  size="small"
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...{ ...params, error: !!error }}
                      style={{ height: '40px' }}
                      label="Project"
                      variant="outlined"
                      helperText={error ? error.message : null}
                    />
                  )}
                  onChange={(_event, value: IKeyState[]) => {
                    const keys = value.map((item) => item.key);
                    onChange(keys);
                  }}
                  onInputChange={(_, value: string) => {
                    setValueSearch(value);
                  }}
                  sx={styles.listProjectByReport}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className="buttonShowToday"
            style={styles.buttonApply}
            onClick={handleSearchReport}
          >
            <Typography className="buttonTextTitle-color-paper">Apply</Typography>
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
export default ReportHeaderComponent;
