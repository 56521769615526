import { Button } from '@mui/material';
import './index.scss';
interface IButton {
  name: string;
  icons?: JSX.Element;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}
const BaseButton = ({ name, onClick, icons, className, disabled }: IButton) => {
  return (
    <Button
      className={`box-tablecontainer-add ${className}`}
      onClick={onClick}
      type="submit"
      startIcon={icons}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};

export default BaseButton;
