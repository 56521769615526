import { Box, Grid, Typography } from '@mui/material';
import './style.scss';
import IconVatek from '../../../constants/icons/IconVatek';
import ImgBanner from '../../../constants/icons/ImgBannerLogin';

interface Props {
  children: React.ReactNode;
}

const ForgotPasswordBase = ({ children }: Props) => {
  return (
    <Grid container className="forgot-password-base-container">
      <Grid item xs={6} className="column-left">
        <Box className="forgot-password-base-content">
          <Box className="icon-vatek-left-wrapper">
            <IconVatek className="icon-vatek-left" />
          </Box>
          {children}
          <Box className="copyright">
            <Typography className="copyright-text">Copyright @Vatek 2022</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} className="colum-right">
        <Box className="icon-vatek-right-wrapper">
          <IconVatek className="icon-vatek-right" />
        </Box>
        <Box className="forgot-password-base-banner">
          <ImgBanner className="banner-img" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordBase;
