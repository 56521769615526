import React from 'react';
import { Collapse, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Box } from '@mui/system';
import './style.scss';
import ChipBase from 'components/chip';
import icons from '../../../constants/icons';
import { Avatar, Button, Typography } from '@mui/material';
import avatar from 'assets/img/avatar.png';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import moment, { Moment } from 'moment';
import NotFound from '../../../components/notFound';
import BaseLoading from '../../../components/Loading';
import { exportTimeSheetReport } from '../redux/actions';
import { formatDate, formatDateDDMM } from '../../../constants';
import { styles } from '../styles';

type Props = {
  listProjectSearch: string[];
};

interface IDataTable {
  key: React.Key;
  name: string;
  normal: number;
  ot: number;
  bonus: number;
}

const ListReportComponent = ({ listProjectSearch }: Props) => {
  const { Panel } = Collapse;
  const { WorkOutlineOutlined, CloudIcons, KeyboardArrowDownIcon, KeyboardArrowRight } = icons;
  const { listReport } = useAppSelector((state) => state.timesheetReduce);
  const { isLoading } = useAppSelector((state) => state.timesheetReduce);
  const { approvalDateMOY } = useAppSelector((state) => state.timesheetReduce);

  const dispatch = useAppDispatch();

  const convertMinuteToHour = (minute: number) => {
    return minute / 60;
  };

  const handleColumnTable = (column: ColumnsType<IDataTable>) => {
    return column.splice(
      0,
      0,
      {
        title: '',
        dataIndex: 'avatar',
        key: 'avatar',
        fixed: 'left',
        render: (record) => (
          <Box style={{ display: 'flex', alignSelf: 'center' }}>
            <Avatar src={record} alt="" style={{ width: '32px', height: '32px' }} />
          </Box>
        ),
      },

      {
        title: 'Full Name',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: (record) => (
          <Box style={{ display: 'flex', alignSelf: 'center' }}>
            <Box style={{ display: 'flex', alignSelf: 'center' }}>{record}</Box>
          </Box>
        ),
      },
      {
        title: 'TWH',
        dataIndex: 'normal',
        key: 'normal',
        fixed: 'left',
        render: (record) => (
          <ChipBase
            className="style-border-chip"
            label={convertMinuteToHour(record)}
            variant="filled"
            customcolor="pending"
          />
        ),
      },
      {
        title: 'TBH',
        dataIndex: 'bonus',
        key: 'bonus',
        fixed: 'left',
        render: (record) => (
          <ChipBase
            className="style-border-chip"
            label={convertMinuteToHour(record)}
            variant="filled"
            customcolor="warning"
          />
        ),
      },
      {
        title: 'TOH',
        dataIndex: 'ot',
        key: 'ot',
        fixed: 'left',
        className: 'border-right',
        render: (record) => (
          <ChipBase
            className="style-border-chip"
            label={convertMinuteToHour(record)}
            variant="filled"
            customcolor="info"
          />
        ),
      },
    );
  };

  const handleExportExcel = (date: Moment, project: { id: string; projectName: string }) => {
    const convertDate = moment(date).toISOString();
    return dispatch(exportTimeSheetReport({ date: convertDate, project: project }));
  };

  return (
    <Box>
      {listProjectSearch.length !== 0 ? (
        <>
          {isLoading ? (
            <Box className="container-loading">
              <BaseLoading size={60} />
            </Box>
          ) : (
            <>
              <Box className="box-container">
                <Typography className="box-text-notes">Notes</Typography>
                <Box className="box-notes">
                  <Box className="box-notes-left">
                    <Typography>TWH - Total Working Hours</Typography>
                    <Typography>TBH - Total Bonus Hours</Typography>
                    <Typography>TOH - Total Overtime Hours</Typography>
                  </Box>
                  <Box className="box-notes-right">
                    <Box className="box-flex">
                      <Button style={styles.buttonNormal}></Button>
                      <Typography>Normal Working Hours</Typography>
                    </Box>
                    <Box className="box-flex">
                      <Button style={styles.buttonBonus}></Button>
                      <Typography>Bonus Working Hours</Typography>
                    </Box>
                    <Box className="box-flex">
                      <Button style={styles.buttonOT}></Button>
                      <Typography>Overtime Working Hours</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                {listReport.map((item, index) => {
                  const children = item.children;

                  const listDateReport = children.map((item) =>
                    item.children.map((item) => formatDate(item.date, formatDateDDMM)),
                  );
                  const convertDataTable = children.map((item) => {
                    let obj = {};
                    item.children.forEach(({ date, bonus, normal, ot }) => {
                      const convertDate = formatDate(date, formatDateDDMM);
                      obj = {
                        ...obj,
                        [convertDate]: {
                          normal,
                          ot,
                          bonus,
                        },
                      };
                      return obj;
                    });
                    return {
                      avatar: item.avatar,
                      name: item.name,
                      normal: item.normal,
                      bonus: item.bonus,
                      ot: item.ot,
                      ...obj,
                    };
                  });

                  const columnReportTable = listDateReport[0].map((item, index) => {
                    return {
                      title: item,
                      dataIndex: item,
                      key: item,
                      render: (record: IDataTable) => {
                        return (
                          <>
                            <ChipBase
                              className="style-border-chip"
                              label={convertMinuteToHour(record.normal)}
                              variant="filled"
                              customcolor="pending"
                            />
                            {record.bonus !== 0 ? (
                              <ChipBase
                                className="style-border-chip"
                                label={convertMinuteToHour(record.bonus)}
                                variant="filled"
                                customcolor="warning"
                                style={{
                                  margin: '0 7px',
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            {record.ot !== 0 ? (
                              <ChipBase
                                className="style-border-chip"
                                label={convertMinuteToHour(record.ot)}
                                variant="filled"
                                customcolor="info"
                                style={{
                                  margin: '0 7px',
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    };
                  });

                  handleColumnTable(columnReportTable);

                  return (
                    <Collapse
                      collapsible="header"
                      style={{ marginTop: '30px' }}
                      expandIcon={({ isActive }) => (
                        <Box style={{ marginTop: '16px' }}>
                          {isActive ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                        </Box>
                      )}
                      key={index}
                    >
                      <Panel
                        header={
                          <Box className="style-box-title">
                            <WorkOutlineOutlined style={{ fontSize: '27px' }} />
                            <Box className="style-title-project">{item.projectName}</Box>
                          </Box>
                        }
                        extra={
                          <Button
                            startIcon={<CloudIcons />}
                            onClick={() =>
                              handleExportExcel(approvalDateMOY, {
                                id: item._id,
                                projectName: item.projectName,
                              })
                            }
                            className="style-button-export"
                          >
                            <Typography className="buttonTextTitle-color-paper">Export</Typography>
                          </Button>
                        }
                        key="1"
                      >
                        <Box className="table-antd">
                          <Table
                            columns={columnReportTable}
                            dataSource={convertDataTable}
                            scroll={{ x: 'max-content' }}
                          />
                        </Box>
                      </Panel>
                    </Collapse>
                  );
                })}
              </Box>
            </>
          )}
        </>
      ) : (
        <NotFound name="No data" />
      )}
    </Box>
  );
};

export default ListReportComponent;
