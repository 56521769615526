import { Avatar, Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { CustomButton } from '.';
import RouteGuard from '../../../commons/RouteGuard';
import ChipBase, { ChipColorsType, KeyColors } from '../../../components/chip';
import NotFound from '../../../components/notFound';
import { formatDate, numberToHours } from '../../../constants';
import { Positions, TimesheetStatus, WorkingType } from '../../../constants/constants.interfaces';
import { formatDDMMYYYY } from '../../../constants/constants.timeFormat';
import icons from '../../../constants/icons';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  ApprovalChildrenProps,
  ApprovalChildrenStatusEnum,
  NewApprovalTimesheetI,
  UserChildrenBase,
} from '../interface';
import { refreshData } from '../redux/timesheetSlice';
import { styles } from '../styles';
import { ERequestStatus } from '../../Dayoff/redux/actions';

interface ListApprovalProps {
  itemsCB: NewApprovalTimesheetI[];
  isParent: boolean;
  countRender: number;
  submitTimesheetCallback: (ids: string[], isApproval: boolean) => void;
}

const ListApprovalTreeComponent: React.FC<ListApprovalProps> = (props) => {
  const [open, setOpen] = React.useState<Map<string, boolean>>(new Map());
  const { isRefresh } = useAppSelector((state) => state.timesheetReduce);
  const dispatch = useAppDispatch();

  const {
    KeyboardArrowDown,
    KeyboardArrowRight,
    WorkOutlineOutlined,
    CheckCircleOutline,
    CancelOutlined,
  } = icons;

  let countRender: number = props.countRender;
  const { isParent } = props;
  useEffect(() => {
    if (isRefresh) {
      setOpen(new Map());
      dispatch(refreshData(false));
    }
  }, [dispatch, isRefresh]);

  const handleChipByType = (type: string) => {
    let color: ChipColorsType = 'warning';
    switch (type) {
      case WorkingType.BONUS:
        type = ApprovalChildrenStatusEnum.Bonus;
        color = 'warning';
        break;
      case WorkingType.NORMAL:
        type = ApprovalChildrenStatusEnum.Normal;
        color = 'pending';
        break;
      case TimesheetStatus.APPROVED:
        color = 'success';
        break;
      case TimesheetStatus.REJECTED:
        color = 'errors';
        break;

      default:
        color = 'info';
        type = ApprovalChildrenStatusEnum.OT;
        break;
    }
    return <RenderChipStatus label={type} color={color} />;
  };

  const RenderChipStatus = (props: { label: string; color: ChipColorsType }) => {
    return (
      <Grid item>
        <ChipBase
          sx={styles.chipContainer}
          label={props.label}
          variant="filled"
          customcolor={props.color}
        />
      </Grid>
    );
  };
  const items = props.itemsCB;

  const handleSubmitOne = (items: ApprovalChildrenProps[] | string[], isApproval: boolean) => {
    const ids = items.map((item) => ((item = item as ApprovalChildrenProps), item._id)) as string[];
    props.submitTimesheetCallback(ids, isApproval);
  };
  countRender++;

  const HandleChipByStatus = (item: NewApprovalTimesheetI) => {
    return (
      <>
        <RenderChipStatus
          label={`Normal: ${numberToHours(item.normal ?? 0)}`}
          color={KeyColors.pending}
        />
        {item.ot !== 0 ? (
          <RenderChipStatus label={`OT: ${numberToHours(item.ot ?? 0)}`} color={KeyColors.info} />
        ) : (
          <></>
        )}
        {item.bonus !== 0 ? (
          <RenderChipStatus
            label={`Bonus: ${numberToHours(item.bonus ?? 0)}`}
            color={KeyColors.warning}
          />
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <>
      {items.length ? (
        items.map((item: NewApprovalTimesheetI, index: number) => {
          const nameProject: string = `${item.date} - ${
            (item?.projectName ?? '') || (item?.name ?? '')
          }`;
          const getBox = open.get(nameProject);
          return item?.children ? (
            <Fragment key={`${index}.${item}`}>
              <Grid
                container
                sx={{
                  ...styles.container,
                  alignItems: 'center',
                  border: '1px solid #DAE1F5',
                  ...(isParent ? { borderTopRightRadius: '8px', borderTopLeftRadius: '8px' } : {}),
                  ...(isParent && !getBox
                    ? { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }
                    : {}),
                  ...(item.projectName ? { margin: '32px 0 0px 0', height: '80px' } : {}),
                }}
              >
                <Grid item xs={9}>
                  <Grid item container xs={12} sx={styles.container}>
                    <Grid item xs={4}>
                      <Grid item container xs={12} sx={styles.container}>
                        <Grid
                          item
                          sx={{
                            '& > :not(style)': {
                              ml: countRender > 2 ? 2 + countRender : 0,
                              mt: 2,
                              mb: 2,
                            },
                          }}
                          xs={2}
                          textAlign="center"
                          alignSelf={'center'}
                          onClick={() => setOpen(new Map(open.set(nameProject, !getBox ?? true)))}
                        >
                          <Box>
                            {getBox ? (
                              <KeyboardArrowDown style={styles.arrow} />
                            ) : (
                              <KeyboardArrowRight style={styles.arrow} />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={2} alignSelf="center" display={'flex'}>
                          {isParent && <WorkOutlineOutlined />}
                          {countRender == 2 && <Avatar src={item.avatar} sx={styles.avatar} />}
                        </Grid>
                        <Grid item xs={8} alignSelf="center">
                          <Grid
                            item
                            container
                            sx={{
                              ...styles.container,
                              ...(item?.date ? { justifyContent: 'start' } : {}),
                            }}
                          >
                            <Typography
                              sx={{
                                ...(isParent
                                  ? { fontSize: 18, fontWeight: 700 }
                                  : { fontSize: 14, fontWeight: countRender > 2 ? 700 : 400 }),
                              }}
                            >
                              {item.projectName || item.name}
                            </Typography>

                            {item?.date && (
                              <>
                                <Typography whiteSpace={'pre-wrap'}> - </Typography>
                                <Typography
                                  sx={{
                                    ...styles.typography400,
                                    textAlign: 'center',
                                  }}
                                >
                                  {formatDate(item.date, formatDDMMYYYY)}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8} alignSelf="center">
                      {countRender > 1 && (
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{
                            ...styles.container,
                            alignItems: 'center',
                          }}
                        >
                          <Grid item>
                            <Typography sx={styles.typographyTotal}>
                              Total: {numberToHours(item.total ?? 0)}
                            </Typography>
                          </Grid>

                          <Box
                            style={{
                              display: 'flex',
                              width: '80%',
                              justifyContent: 'space-around',
                            }}
                          >
                            {HandleChipByStatus(item)}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {!isParent && (
                  <Grid item xs={3} textAlign="end" alignSelf={'center'}>
                    <Grid
                      item
                      container
                      xs={12}
                      flexDirection="row"
                      justifyContent={countRender > 2 ? 'center' : 'space-evenly'}
                    >
                      {
                        <RouteGuard listPosition={[Positions.PM, Positions.ADMIN]}>
                          <>
                            {countRender > 2 && (
                              <Grid
                                item
                                container
                                xs={12}
                                flexDirection="row"
                                justifyContent="space-around"
                                alignItems="center"
                              >
                                {item?.status !== ERequestStatus.APPROVED && (
                                  <>
                                    {item?.status !== ERequestStatus.PENDING && item?.status ? (
                                      <Grid xs={6} textAlign="center">
                                        {handleChipByType(item.status)}
                                      </Grid>
                                    ) : (
                                      <></>
                                    )}
                                    <Grid xs={6} textAlign="center">
                                      <IconButton
                                        sx={styles.iconBtnApproval}
                                        onClick={() =>
                                          handleSubmitOne(
                                            item.children as ApprovalChildrenProps[],
                                            true,
                                          )
                                        }
                                      >
                                        <CheckCircleOutline />
                                      </IconButton>
                                    </Grid>
                                  </>
                                )}
                                {item?.status !== ERequestStatus.REJECTED && (
                                  <>
                                    {item?.status !== ERequestStatus.PENDING && item?.status ? (
                                      <Grid xs={6} textAlign="center">
                                        {handleChipByType(item.status)}
                                      </Grid>
                                    ) : (
                                      <></>
                                    )}
                                    <Grid xs={6} textAlign="center">
                                      <IconButton
                                        sx={styles.iconBtnReject}
                                        onClick={() =>
                                          handleSubmitOne(
                                            item.children as ApprovalChildrenProps[],
                                            false,
                                          )
                                        }
                                      >
                                        <CancelOutlined />
                                      </IconButton>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            )}
                          </>
                        </RouteGuard>
                      }
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Collapse in={getBox} timeout="auto" unmountOnExit>
                <Box>
                  <ListApprovalTreeComponent
                    key={index}
                    itemsCB={(item.children ?? []) as unknown as NewApprovalTimesheetI[]}
                    isParent={false}
                    countRender={countRender}
                    submitTimesheetCallback={props.submitTimesheetCallback}
                  />
                </Box>
              </Collapse>
            </Fragment>
          ) : (
            <Fragment key={`${item}.${item._id}`}>
              <Grid container key={countRender} sx={styles.gridItem}>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      ...styles.typography400,
                      ...styles.gridItemTextDetail,
                    }}
                  >
                    {item.details}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={styles.typography400}>
                    {numberToHours(item?.hours ?? 0)}
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="center">
                  {handleChipByType((item?.type ?? WorkingType.BONUS) as WorkingType)}
                </Grid>
              </Grid>
            </Fragment>
          );
        })
      ) : (
        <Grid container sx={styles.gridItemNoData}>
          <Grid item>
            <NotFound name="No Data" minHeight={'0px'} fontSize="20px" />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ListApprovalTreeComponent;
