import { BaseParamsI } from './interfaces';
import moment, { Moment } from 'moment';
import { ILeaveRequestResponseData } from '../types/dayOffType';

export const limitDefault: number = 10;
export const offsetDefault: number = 0;

interface DefaultParamsI {
  baseUrl: string;
  options: BaseParamsI;
  searchName?: string;
  positionsName?: string;
  searchNameByKey?: string;
}

export const handleLimitOffset = (limit?: number, offset?: number) => {
  return `limit=${limit ?? limitDefault}&offset=${offset ?? offsetDefault}`;
};

export const handleUrlReport = (url: string, options: BaseParamsI) => {
  return `${url}?${handleLimitOffset(options.limit, options.offset)}&${options.reports}`;
};

export const handleParamsUrl = ({
  baseUrl,
  searchName,
  options,
  positionsName,
  searchNameByKey,
}: DefaultParamsI) => {
  let url: string = `${baseUrl}?`;
  if (options.search) {
    url = `${url}${searchName}=${options.search}&`;
  }
  if (options.position) {
    options.position.forEach((e: string) => {
      url = `${url}${positionsName}=${e}&`;
    });
  }
  if (options.searchByKey) {
    url = `${url}${searchNameByKey}=${options.searchByKey}&`;
  }
  if (options.reports) {
    url = `${url}${options.reports}&`;
  }
  url = `${url}${handleLimitOffset(options.limit, options.offset)}`;
  return url;
};

export const listWeeks = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const convertDate = (date: Date) => {
  const dateNow = new Date(date);
  const formatDate = moment(dateNow).format('DD/MM/YYYY');
  return {
    weekName: listWeeks[dateNow.getDay()],
    dateDDMMYYYY: formatDate,
  };
};

const formatDefault = 'DD/MM/YYYY';
export const formatDateDDMM = 'DD/MM';
export const formatDateMMYYYY = 'MM/YYYY';
export const formatISODefault = 'YYYY-MM-DD';

export const formatDate = (date: Moment | number | Date, format?: string) =>
  moment(date).format(format ?? formatDefault);

export const caculateWeeklyNow = (
  number: number,
  date: Moment | number | string,
  unit?: moment.unitOfTime.DurationConstructor,
) => {
  const momentNow = (num: number) => moment(date).add(num, unit ?? 'weeks');
  const startWeek = momentNow(number).startOf('isoWeek');
  const endWeek = momentNow(number).endOf('isoWeek');

  return { startWeek, endWeek, now: momentNow(number) };
};

export const numberToHours = (value: number) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  return value ? `${hours}h${minutes ? `${minutes}m` : ''}` : '0h';
};

export function isDecimal(input: string) {
  const result = +input - Math.floor(+input) !== 0;

  if (result) return true;
  return false;
}
export const regexNumber = /[0-9]/;

export const canAcceptPermission = (position: string[], listPermissions: string[]) => {
  const checkPermission = position.some((item) => listPermissions.includes(item));
  return checkPermission;
};

export const defaultAllowedImageSize = 3 * 1024 * 1024;

export const defaultAvatar = require('assets/img/avatar.png');
export const handleAvatarSrc = (item: ILeaveRequestResponseData) => {
  return item.user.avatar ? item.user.avatar.src : defaultAvatar;
};
