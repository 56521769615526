import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { setCurrentMenuState, setBreadcrums } from 'layouts/Sidebar/redux/layoutSlice';
import { AppDispatch } from 'store/store';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { IMenuPropType } from 'types/navigation';
import { useEffect, useState } from 'react';
import './style.scss';

const NavItem = ({ item, level }: IMenuPropType) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const disabled = !!item?.disabled;
  const Icon = !disabled ? item?.icon : <></>;
  const itemIcon = <Icon stroke={1.5} size="1.3rem" />;
  const [open, setOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const linkUrl = item?.url;
  const [currentUrl, setCurrentUrl] = useState<string[]>([pathname]);

  const itemHandler = () => {
    setOpen(!open);
    navigate(`${item?.url}`);
    dispatch(setCurrentMenuState(true));
    dispatch(setBreadcrums(item));
  };

  useEffect(() => {
    return setCurrentUrl([pathname]);
  }, [pathname]);

  const setFocus = currentUrl.includes(linkUrl as string);

  return (
    <List>
      <ListItemButton
        disabled={disabled}
        className={setFocus ? 'nav-item-open' : 'nav-item'}
        onClick={itemHandler}
      >
        <ListItemIcon className={setFocus ? 'nav-item-icon-open' : 'nav-item-icon'}>
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography variant="body1" color="inherit" className="nav-item-text">
                {item?.name}
              </Typography>
            </>
          }
        />
      </ListItemButton>
    </List>
  );
};
export default NavItem;
