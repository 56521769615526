import { useState } from 'react';
import { Box, Button } from '@mui/material';
import BasePopup from '../../../../components/popup';
import { Link, useParams } from 'react-router-dom';
import BaseButton from '../../../../components/button';
import { Positions } from '../../../../constants/constants.interfaces';
import EditActionIcons from '../../../../constants/icons/EditActionIcons';
import LogoutIcon from '../../../../constants/icons/LogoutIcon';
import { useAppSelector, useAppDispatch } from '../../../../store/store';
import './style.scss';
import { changeStatusEmployee, getUserDetails } from '../../redux/actions';
import { canAcceptPermission } from '../../../../constants';

type Props = {};

const ViewHeader = (props: Props) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const detailUser = useAppSelector((state) => state.userReducer.detailUser);
  const { positions } = useAppSelector((state) => state.authReducer);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const handleOffboarding = async () => {
    if (id) {
      await dispatch(changeStatusEmployee(id));
      await dispatch(getUserDetails(id));
    }
    setOpenPopup(false);
  };

  const isEdit = canAcceptPermission(positions, [Positions.ADMIN, Positions.HR]);

  const PopupConfirmOffboarding = () => {
    return (
      <BasePopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Box className="styeBoxModal">
          <Box className="titleModalDelete"> Offboarding</Box>
          <Box className="titleContentDelete">
            This is one-time action, do you sure you want to proceed?
          </Box>
          <Box className="styleButtonModal">
            <Box>
              <Button className="sizeButton styleButtonDelete" onClick={handleOffboarding}>
                Offboarding
              </Button>
            </Box>
            <Box>
              <Button className="sizeButton styleButtonCancel" onClick={() => setOpenPopup(false)}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </BasePopup>
    );
  };

  return (
    <Box className="viewHeader">
      <Box className="headerTitle">{detailUser.fullName}</Box>
      <Box className="headerButton">
        <Link to={`/employee/update/${id}`} style={{ textDecoration: 'none' }}>
          <BaseButton
            name="Edit Employee"
            icons={<EditActionIcons style={{ color: 'white' }} />}
            onClick={() => {}}
          />
        </Link>
        {isEdit && (
          <Box onClick={() => setOpenPopup(true)}>
            <BaseButton
              className="offboarding"
              name="Offboarding"
              icons={<LogoutIcon style={{ color: 'white' }} />}
              onClick={() => {}}
            />
          </Box>
        )}
        <PopupConfirmOffboarding />
      </Box>
    </Box>
  );
};

export default ViewHeader;
