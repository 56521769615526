import { ControllerProps } from 'react-hook-form';
import { isDecimal } from '.';
import { BAD_REQUEST, FIELD_REQUIRED } from './constants.message';

const validationNumber = {
  value: /^[0-9]+$/,
  message: '⚠Wrong ID format',
};
export const validationEmail = {
  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  message: BAD_REQUEST.INCORRECT_EMAIL_FORMAT,
};
const validationPhoneNumber = {
  value: /((09|03|07|08|05)+([0-9]{8})\b)/g,
  message: '⚠Wrong phone number format',
};
const validationUsername = {
  value: /^[a-zA-Z]+-+[a-zA-Z]+$/,
  message: BAD_REQUEST.ERROR_USERNAME_OR_EMAIL,
};
const validationAddress = {
  value: /^[a-zA-Z0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF\.\,\/ ]*$/,
  message: BAD_REQUEST.INCORRECT_FORMAT,
};

export const validateFieldNameRequired = (fieldName?: string) => {
  const required: ControllerProps['rules'] = {
    ...baseRules,
    required: {
      value: true,
      message: fieldName ? `${fieldName} is required` : FIELD_REQUIRED,
    },
  };
  return required;
};

const baseRules: ControllerProps['rules'] = {};
export const requiredFieldRules: ControllerProps['rules'] = {
  ...baseRules,
  required: {
    value: true,
    message: FIELD_REQUIRED,
  },
};

export const validationInputField = (fieldName?: string) => {
  const validate: ControllerProps['rules'] = {
    ...validateFieldNameRequired(fieldName),
    validate: (value) => {
      const txt = value.trim();
      return txt.length ? true : `${fieldName} is required`;
    },
  };
  return validate;
};

const requiredFieldBase = (message: string) =>
  ({
    ...baseRules,
    required: {
      value: true,
      message,
    },
  } as ControllerProps['rules']);

export const validationUsernameOrEmailLogin: ControllerProps['rules'] = {
  ...requiredFieldRules,
  validate: (value) => {
    if (!validationEmail.value.test(value) && !validationUsername.value.test(value))
      return BAD_REQUEST.ERROR_USERNAME_OR_EMAIL;
    return true;
  },
};

export const canAddWorkingHours = (value: number) => {
  return !((isDecimal(`${value}`) && value % 1 !== 0.5) || value > 8 || value <= 0);
};

export const requiredWorkingHoursRules: ControllerProps['rules'] = {
  ...baseRules,
  required: {
    value: true,
    message: BAD_REQUEST.WORKING_HOURS_REQUIRED,
  },
  validate: (value) => {
    if (!value) return BAD_REQUEST.WORKING_HOURS_REQUIRED;
    if (!canAddWorkingHours(value)) return BAD_REQUEST.WORKING_HOURS_ACCEPTABLE;
    return true;
  },
};

export const requiredProjectRules: ControllerProps['rules'] = {
  ...requiredFieldBase('Please choose a project'),
};

export const requiredWorkingTypeRules: ControllerProps['rules'] = {
  ...requiredFieldBase(BAD_REQUEST.WORKING_TYPE_REQUIRED),
};

export const requiredDetailsRules: ControllerProps['rules'] = {
  validate: (value: string) => {
    const txt = value.trim();
    return txt.length > 10 ? true : 'Please fill more than 10 characters';
  },
  minLength: {
    value: 10,
    message: 'Please fill more than 10 characters',
  },
  ...requiredFieldBase('Please fill the description of your task'),
};

export const requiredLeaveType: ControllerProps['rules'] = {
  ...requiredFieldBase(BAD_REQUEST.LEAVE_TYPE_REQUIRED),
};

export const requiredDate: ControllerProps['rules'] = {
  ...requiredFieldBase(BAD_REQUEST.DATE_REQUIRED),
};

export const requiredLeaveReasonRules: ControllerProps['rules'] = {
  validate: (value: string) => {
    const txt = value.trim();
    return txt.length > 10 ? true : BAD_REQUEST.NOT_ENOUGH_CHARACTERS;
  },
  minLength: {
    value: 10,
    message: BAD_REQUEST.NOT_ENOUGH_CHARACTERS,
  },
  ...requiredFieldBase(BAD_REQUEST.LEAVE_REASON_REQUIRED),
};

export const validationNumberRules: ControllerProps['rules'] = {
  ...validateFieldNameRequired('National ID'),
  pattern: validationNumber,
};

export const validationPhoneNumberRules: ControllerProps['rules'] = {
  ...validateFieldNameRequired('Phone Number'),
  pattern: validationPhoneNumber,
};

export const validationEmailRules: ControllerProps['rules'] = {
  ...validateFieldNameRequired('Email Address'),
  pattern: validationEmail,
};

export const validationUsernameRules: ControllerProps['rules'] = {
  ...validateFieldNameRequired('Username'),
  validate: (value) => {
    if (!validationUsername.value.test(value)) return BAD_REQUEST.INCORRECT_FORMAT;
    return true;
  },
};
export const validationAddresssRules: ControllerProps['rules'] = {
  ...validateFieldNameRequired('Address'),
  validate: (value) => {
    if (!validationAddress.value.test(value)) return BAD_REQUEST.INCORRECT_FORMAT;
    return true;
  },
};
