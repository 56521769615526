import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ControllerDateField from '../../../../components/inputDate';
import ControllerSelect from '../../../../components/inputFromSelect';
import ControllerTextFied from '../../../../components/inputTexField';
import { Positions, ProjectStatusEnum } from '../../../../constants/constants.interfaces';
import icons from '../../../../constants/icons';
import { IKeyState } from '../../../../constants/interfaces';
import { validateFieldNameRequired, validationInputField } from '../../../../constants/rules';
import useDebounce from '../../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { IProjectInfo } from '../../../../types/projectType';
import { IUserInfo } from '../../../../types/userType';
import { findPmByUser } from '../../../Users/redux/actions';
import { createProjectThunk, updateProjectThunk } from '../../redux/actions';
import { checkDuplicateProjectMember } from './constants';
import InputMemberProject from './InputMemberProject';
import { style } from './style';
import './style.scss';

type Props = {};

export enum PositionsEnum {
  Member = 'Member',
  ProjectManager = 'Project Manager',
}
export interface DefaultValueI extends Omit<IProjectInfo, 'startDate'> {
  _id?: string;
  endDate: string;
  startDate: string;
  clientName: string;
  state: string;
  [member: `member-${string}`]: string;
}
type IOnchange = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> & Date;

const ContentProject = (props: Props) => {
  const { id: idProject } = useParams();
  const { projectDetail } = useAppSelector((state) => state.projectReducer);
  const { listProjectManages } = useAppSelector((user) => user.userReducer);

  const [valueSearch, setValueSearch] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>('');
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { KeyboardArrowDownIcon } = icons;

  const debounceTextSearch = useDebounce<string>(valueSearch, 1000);
  const limitProjectMember = 5;

  const defaultInputMember = new Map();
  const [inputField, setInputField] = React.useState<Map<number, IKeyState>>(defaultInputMember);

  const listMemberIds = Array.from(inputField.values())
    .map((member) => member.key)
    .filter((item) => !!item);

  useEffect(() => {
    dispatch(
      findPmByUser({
        position: [Positions.PM, Positions.ADMIN],
        search: debounceTextSearch,
        limit: 100,
      }),
    );
  }, [dispatch, debounceTextSearch]);

  const listManages = useMemo(
    () =>
      listProjectManages.map((e: IUserInfo) => ({
        key: e._id,
        value: e.fullName,
      })),
    [listProjectManages],
  );

  const handleBackToPage = () => {
    return navigate(-1);
  };

  // form
  const methods = useForm<DefaultValueI>({
    defaultValues: {
      projectName: '',
      startDate: '',
      endDate: '',
      clientName: '',
      projectManager: '',
      member: [],
      state: '',
    },
  });

  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = (data: DefaultValueI) => {
    const checkDuplicateManagement = listMemberIds.includes(data.projectManager);
    const checkDuplicateMember = checkDuplicateProjectMember(listMemberIds);

    if (checkDuplicateManagement || checkDuplicateMember) return;
    if (idProject) {
      dispatch(
        updateProjectThunk({
          _id: idProject,
          projectName: data.projectName.trim(),
          startDate: data.startDate,
          projectManager: data.projectManager,
          member: listMemberIds,
          state: data.state,
          endDate: data.endDate,
          clientName: data.clientName.trim(),
        }),
      );
    } else {
      dispatch(
        createProjectThunk({
          projectName: data.projectName.trim(),
          startDate: data.startDate,
          projectManager: data.projectManager,
          member: listMemberIds,
          clientName: data.clientName.trim(),
          endDate: data.endDate,
        }),
      );
    }
    handleBackToPage();
    return;
  };

  useEffect(() => {
    if (idProject) {
      reset({
        _id: idProject,
        projectName: projectDetail.projectName,
        clientName: projectDetail.clientName,
        state: projectDetail.state,
        startDate: String(projectDetail.startDate),
        endDate: String(projectDetail.endDate ?? ''),
        projectManager: projectDetail.projectManager.fullName,
      });
      setIdProjectManager(projectDetail.projectManager._id);
    }
  }, [idProject]);

  const defaultProjectManager = () => {
    if (idProject)
      return {
        key: projectDetail.projectManager._id ?? '',
        value: projectDetail.projectManager.fullName ?? '',
      };
  };

  const defaultStatus = () => {
    if (idProject) {
      return {
        key: projectDetail.state,
        value: projectDetail.state,
      };
    }
  };
  // convert enum to IKeyState
  const projectStatus = new Map();
  Object.keys(ProjectStatusEnum).forEach((item, index) => {
    projectStatus.set(index, {
      key: item,
      value: item,
    });
  });
  const listProjectStatus = Array.from(projectStatus.values());

  const [idProjectManager, setIdProjectManager] = React.useState<string>();
  const callBackFunction = (idManage: string) => {
    setIdProjectManager(idManage);
  };

  return (
    <Box className="project">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* project information */}
          <Box className="project-information">
            <Box className="project-information-box">
              <Typography variant="h2" component="h2" className="style-typography-h2">
                Project information
              </Typography>

              <Box className="project-information-box-grid">
                <Box sx={style.boxGrid}>
                  <Grid container spacing={2}>
                    <Grid container sx={style.gridContainer}>
                      <Grid sx={style.grid}>
                        <ControllerTextFied
                          rules={validationInputField('Project Name')}
                          name="projectName"
                          label="Project Name"
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid sx={style.grid}>
                        <ControllerTextFied
                          rules={validationInputField('Client Name')}
                          name="clientName"
                          label="Client Name"
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container sx={style.gridContainer}>
                      <Grid sx={style.gridContainer} style={{ width: '47%' }}>
                        <Grid sx={style.startDate}>
                          <ControllerDateField
                            rules={validateFieldNameRequired('Start Date')}
                            name="startDate"
                            label="Start Date"
                            onChange={(value: IOnchange) => {
                              if (value) {
                                setStartDate(value);
                                setValue('startDate', String(value), { shouldValidate: true });
                              }
                            }}
                            maxDate={idProject ? projectDetail.endDate : endDate}
                          />
                        </Grid>
                        <Grid sx={style.endDate}>
                          <ControllerDateField
                            name="endDate"
                            label="End Date"
                            onChange={(value: IOnchange) => {
                              if (value) {
                                setEndDate(value);
                                setValue('endDate', String(value));
                              }
                            }}
                            minDate={idProject ? projectDetail.startDate : startDate}
                          />
                        </Grid>
                      </Grid>
                      <>
                        {idProject ? (
                          <>
                            <Grid sx={style.grid}>
                              <ControllerSelect
                                name="state"
                                rules={validateFieldNameRequired('Status')}
                                options={listProjectStatus}
                                getOptionLabel={(option) => (option as IKeyState).value ?? ''}
                                label="Status"
                                size="small"
                                popupIcon={<KeyboardArrowDownIcon />}
                                defaultValues={defaultStatus()}
                              />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* project manager, member */}
          <Box className="project-manage">
            <Box>
              <Typography variant="h2" component="h2" className="style-typography-h2">
                Project manager
              </Typography>
              <Box>
                <ControllerSelect
                  name="projectManager"
                  rules={validateFieldNameRequired('Select Project Manager')}
                  onFocus={() => {
                    setValueSearch('');
                    return setStatus(PositionsEnum.ProjectManager);
                  }}
                  options={listManages}
                  getOptionLabel={(option) => (option as IKeyState).value ?? ''}
                  label="Select Project Manager"
                  onInputChange={(_, values: string) => {
                    setValueSearch(values);
                  }}
                  size="small"
                  popupIcon={<KeyboardArrowDownIcon />}
                  defaultValues={defaultProjectManager()}
                  callBack={(idManage) => callBackFunction(idManage as string)}
                />
              </Box>
            </Box>
            <Box className="project-manage-member">
              <Typography variant="h2" component="h2" className="style-typography-h2">
                Project member
              </Typography>

              <InputMemberProject
                inputField={inputField}
                setInputField={setInputField}
                idManager={idProjectManager}
              />

              <Box className="box-button">
                <Button className="box-button-cancel" onClick={() => handleBackToPage()}>
                  Cancel
                </Button>
                <Button type="submit" className="box-button-save">
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ContentProject;
