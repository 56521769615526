import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { IDetailUser, IUserInfo } from 'types/userType';
import toastConfig from 'utils/toastConfig';
import DetailPopUp from '../../../components/detailPopup';
import { toast, Zoom } from 'react-toastify';
import {
  deleteUserThunk,
  getAllUser,
  creatUser,
  updateUser,
  updateProfile,
  changeStatusEmployee,
  getUserOnBirthDay,
  getAllMentor,
  getUserDetails,
  findByUser,
  findPmByUser,
  searchByUser,
  uploadAvatar,
  getUserAvatar,
  getOwnProfile,
} from 'features/Users/redux/actions';
import { MESSAGES } from '../../../constants/constants.message';
import { ErrorResponse } from '../../Dayoff/redux/dayoffSlice';
import { InitialStatus } from '../../../constants/constants.interfaces';

interface InitState {
  userList: IUserInfo[];
  listProjectManages: IUserInfo[];
  total: number;
  paginateTotal: number;
  currentUser: null;
  userAvatar: string;
  currentProfile: IUserInfo | null;
  listUserOnBirthday: IUserInfo[];
  listMentor: IUserInfo[];
  detailUser: IDetailUser;
  isLoading: boolean;
  employeeList: IUserInfo[];
  listEmployeeBySearch: IUserInfo[];
  initialStatus: InitialStatus;
}

const initialState: InitState = {
  employeeList: [],
  userList: [],
  listProjectManages: [],
  total: 0,
  paginateTotal: 0,
  userAvatar: '',
  currentUser: null,
  currentProfile: null,
  listUserOnBirthday: [],
  listMentor: [],
  detailUser: {
    _id: '',
    address: '',
    citizenID: '',
    email: '',
    fullName: '',
    phone: '',
    username: '',
    positions: [],
    onboardingDate: new Date(),
    level: {
      value: '',
      key: '',
      _id: '',
    },
    onboardingMentor: {
      _id: '',
      fullName: '',
    },
    status: '',
  },
  listEmployeeBySearch: [],
  isLoading: false,
  initialStatus: InitialStatus.IDLE,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    clearInitialStatus: (state, action) => {
      state.initialStatus = InitialStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.userList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
      })
      .addCase(getAllUser.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteUserThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUserThunk.fulfilled, (state, action) => {
        state.userList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
        toastConfig.toastSuccess(`${MESSAGES.DELETE_SUCCESSFULLY}`);
      })
      .addCase(deleteUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        toastConfig.toastError('Delete Error, cannot delete your own account');
      });

    builder
      .addCase(creatUser.pending, (state, action) => {
        state.isLoading = true;
        state.initialStatus = InitialStatus.LOADING;
      })
      .addCase(creatUser.fulfilled, (state, action) => {
        state.userList = action.payload.data;
        state.total = action.payload.total;
        state.paginateTotal = action.payload.paginateTotal;
        state.isLoading = false;
        state.initialStatus = InitialStatus.SUCCEEDED;
        toastConfig.toastSuccess(`${MESSAGES.CREATE_SUCCESSFULLY}`);
      })
      .addCase(creatUser.rejected, (state, action) => {
        state.isLoading = false;
        state.initialStatus = InitialStatus.FAILED;
        toast(
          DetailPopUp({
            message: action?.payload
              ? `${MESSAGES.CREATE_FAILED.DUPLICATED_MESSAGE}`
              : `${MESSAGES.CREATE_FAILED.NORMAL_MESSAGE}`,
            type: 'Warning',
          }),
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true,
            pauseOnHover: false,
            transition: Zoom,
          },
        );
      });

    builder
      .addCase(updateUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userList = action.payload.data;
        state.isLoading = false;
        toastConfig.toastSuccess(`${MESSAGES.UPDATE_SUCCESSFULLY}`);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        toastConfig.toastError(`${MESSAGES.UPDATE_FAILED}`);
      });

    builder
      .addCase(changeStatusEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changeStatusEmployee.fulfilled, (state, action) => {
        state.detailUser.status = action.payload.status;
        state.isLoading = false;
        toastConfig.toastSuccess(`${MESSAGES.UPDATE_SUCCESSFULLY}`);
      })
      .addCase(changeStatusEmployee.rejected, (state, action) => {
        state.isLoading = false;
        toastConfig.toastError(`${MESSAGES.UPDATE_FAILED}`);
      });
    builder
      .addCase(getOwnProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOwnProfile.fulfilled, (state, action) => {
        state.currentProfile = action.payload;
        state.isLoading = false;
      })
      .addCase(getOwnProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.currentProfile = action.payload;
        toastConfig.toastSuccess('Update successfully');
      })
      .addCase(updateProfile.rejected, () => {
        toastConfig.toastError('Update failed');
      });
    builder.addCase(getUserOnBirthDay.fulfilled, (state, action) => {
      state.listUserOnBirthday = action.payload;
    });

    builder.addCase(getAllMentor.fulfilled, (state, action) => {
      state.listMentor = action.payload;
    });

    builder
      .addCase(getUserDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.detailUser = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder.addCase(findByUser.fulfilled, (state, action) => {
      state.userList = action.payload.data;
      state.total = action.payload.total;
      state.paginateTotal = action.payload.paginateTotal;
    });
    builder.addCase(findPmByUser.fulfilled, (state, action) => {
      state.listProjectManages = action.payload.data;
      state.total = action.payload.total;
      state.paginateTotal = action.payload.paginateTotal;
    });
    builder.addCase(searchByUser.fulfilled, (state, action) => {
      state.listEmployeeBySearch = action.payload.data;
      state.total = action.payload.total;
      state.paginateTotal = action.payload.paginateTotal;
    });
    builder
      .addCase(uploadAvatar.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailUser.avatar = action.payload.avatar;
        toastConfig.toastSuccess('Upload avatar successfully');
      })
      .addCase(uploadAvatar.rejected, (state, action) => {
        state.isLoading = false;
        toastConfig.toastError((action.payload as ErrorResponse).message);
      });
    builder.addCase(getUserAvatar.fulfilled, (state, action) => {
      state.userAvatar = action.payload ?? '';
    });
  },
});

export default userSlice.reducer;
export const { setCurrentUser, clearInitialStatus } = userSlice.actions;
export const selctUser = (state: RootState) => state.userReducer;
