import React from 'react';
import 'layouts/Body/style.scss';
import { Box } from '@mui/material';
import Header from 'components/header';

interface IContent {
  children?: JSX.Element;
}

const Content = ({ children }: IContent) => {
  return (
    <Box className="box-content">
      <Header />
      {children}
    </Box>
  );
};

export default Content;
