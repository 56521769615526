import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import './style.scss';
import { PATH } from 'constants/path';
import imgChangePassword from '../../../assets/img/changePasswordImg.png';
import ForgotPasswordBase from '../../forgotPassword/forgotPasswordBase';

const ResetPasswordSuccess = () => {
  return (
    <ForgotPasswordBase>
      <Box>
        <Box className="reset-password-success-img">
          <img src={imgChangePassword} alt="emai-marketing-img" />
        </Box>
        <Grid item className="reset-password-success-information">
          <Stack alignItems="left" justifyContent="center" spacing={1}>
            <Typography variant="h1" fontSize="36px" fontWeight="700">
              Changed successfully!
            </Typography>
            <Typography variant="body1" fontSize="14px" justifyContent="center">
              You have changed your new password. You can
              <Link to={PATH.LOGIN} className="login-link">
                Login Now
              </Link>
            </Typography>
          </Stack>
        </Grid>
      </Box>
    </ForgotPasswordBase>
  );
};

export default ResetPasswordSuccess;
