import React from 'react';
import { canAcceptPermission } from '../constants';
import { useAppSelector } from '../store/store';

interface IPermissionProp {
  listPosition: string[];
  children: JSX.Element;
}

const RouteGuard = ({ children, listPosition }: IPermissionProp) => {
  const { positions } = useAppSelector((state) => state.authReducer);

  return <>{canAcceptPermission(positions, listPosition) ? <>{children}</> : <></>}</>;
};

export default RouteGuard;
