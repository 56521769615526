import { Box } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import RouteGuard from '../../../../commons/RouteGuard';
import BaseButton from '../../../../components/button';
import { canAcceptPermission } from '../../../../constants';
import { Positions } from '../../../../constants/constants.interfaces';
import EditActionIcons from '../../../../constants/icons/EditActionIcons';
import { useAppSelector } from '../../../../store/store';
import './style.scss';

type Props = {};

const ViewHeader = (props: Props) => {
  const projectDetail = useAppSelector((state) => state.projectReducer.projectDetail);
  const { currentLoginUser } = useAppSelector((state) => state.authReducer);
  const { positions } = useAppSelector((state) => state.authReducer);
  const { id } = useParams();

  const isPm =
    projectDetail.projectManager._id !== currentLoginUser &&
    canAcceptPermission(positions, [Positions.PM])
      ? false
      : true;

  return (
    <Box className="viewHeader">
      <Box className="headerTitle">{projectDetail.projectName}</Box>
      <Box className="headerButton">
        <RouteGuard listPosition={[Positions.ADMIN, Positions.PM]}>
          {isPm ? (
            <Link to={`/project/update/${id}`} style={{ textDecoration: 'none' }}>
              <BaseButton
                name="Edit Project"
                icons={<EditActionIcons style={{ color: 'white' }} />}
                onClick={() => {}}
              />
            </Link>
          ) : (
            <></>
          )}
        </RouteGuard>
      </Box>
    </Box>
  );
};

export default ViewHeader;
