import * as React from 'react';
import { PatternFormat } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const WorkingHoursInput = React.forwardRef(function WorkingHoursInput(props: CustomProps, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      placeholder="3h"
      format="#.#"
      value={''}
      valueIsNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue.trim(),
          },
        });
      }}
    />
  );
});

export default WorkingHoursInput;
