import React, { useState } from 'react';
import ListReportComponent from './ListReportComponent';
import ReportHeaderComponent from './ReportHeaderComponent';
type Props = {};

const Report = (props: Props) => {
  const [projects, setProjects] = useState<string[]>([]);
  const handleCallBack = (data: string[]) => {
    setProjects(data);
  };
  return (
    <>
      <ReportHeaderComponent callBack={handleCallBack} />
      <ListReportComponent listProjectSearch={projects} />
    </>
  );
};

export default Report;
