import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
  className?: string;
}

const IconVatek = (props: StyleProps) => {
  return (
    <svg
      style={props.style}
      className={props.className}
      viewBox="0 0 102 73"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.0949986 51.0872C0.275435 50.4539 0.724996 50.048 1.43757 49.8666C1.618 49.8144 1.83208 49.7867 2.07674 49.7867C3.03703 49.7867 3.73431 50.3862 4.17163 51.5853L10.23 67.3027L16.3037 51.5853C16.741 50.3862 17.4383 49.7867 18.3986 49.7867C18.6341 49.7867 18.8451 49.8144 19.0377 49.8666C19.7503 50.048 20.1693 50.3555 20.2916 50.7829C20.4139 51.2102 20.4751 51.5484 20.4751 51.7944C20.4751 52.2125 20.3742 52.6522 20.1723 53.1134L13.0283 70.8877C12.4962 72.2375 11.5634 72.9108 10.23 72.9108C8.90885 72.9108 7.97608 72.2344 7.43172 70.8877L0.302959 53.1134C0.101115 52.6522 0.000191577 52.2125 0.000191577 51.7944C-0.00286666 51.5454 0.0307756 51.3117 0.0949986 51.0872Z" />
      <path d="M19.7229 70.0637L27.3165 51.8559C27.8395 50.5369 28.7722 49.8789 30.1148 49.8789C31.436 49.8789 32.3749 50.5369 32.9284 51.8559L40.4884 70.0637C40.6596 70.4696 40.7453 70.8508 40.7453 71.2044C40.7453 71.4842 40.6566 71.8132 40.4823 72.1913C40.3079 72.5726 39.8675 72.8094 39.1641 72.9077C39.0051 72.9293 38.8553 72.9385 38.7176 72.9385C37.6962 72.9385 36.9439 72.3451 36.4637 71.1552L34.849 67.2197H25.4021L23.7567 71.1552C23.457 71.8624 23.1206 72.3359 22.7414 72.5788C22.3622 72.8216 21.9615 72.9416 21.5334 72.9416C21.3744 72.9416 21.2123 72.9293 21.0532 72.9108C20.359 72.8155 19.9248 72.5726 19.7413 72.1883C19.5608 71.8039 19.4691 71.4719 19.4691 71.1921C19.466 70.8447 19.5516 70.4696 19.7229 70.0637ZM33.4666 63.7638L30.1087 55.487L26.7507 63.7638H33.4666Z" />
      <path d="M39.1121 51.6807C39.1121 51.0904 39.3017 50.6415 39.6809 50.3309C40.0601 50.0204 40.6045 49.8636 41.3201 49.8636H56.7612C57.4737 49.8636 58.0212 50.0173 58.4004 50.3309C58.7796 50.6415 58.9692 51.0904 58.9692 51.6807C58.9692 52.271 58.7796 52.723 58.4004 53.0397C58.0212 53.3563 57.4768 53.5131 56.7612 53.5131H51.1676V70.7064C51.1676 71.4228 50.9872 71.97 50.6233 72.3451C50.2593 72.7202 49.7333 72.9078 49.0422 72.9078C48.351 72.9078 47.8219 72.7202 47.4611 72.3451C47.1002 71.97 46.9167 71.4228 46.9167 70.7064V53.5131H41.3232C40.6106 53.5131 40.0662 53.3533 39.6931 53.0304C39.3017 52.7199 39.1121 52.271 39.1121 51.6807Z" />
      <path d="M62.5445 70.7064V52.065C62.5445 51.3486 62.731 50.8013 63.1041 50.4262C63.4773 50.0511 64.0216 49.8636 64.7342 49.8636H76.7867C77.4993 49.8636 78.0437 50.0173 78.4168 50.3309C78.7899 50.6415 78.9764 51.0904 78.9764 51.6807C78.9764 52.2802 78.7899 52.7353 78.4168 53.0458C78.0437 53.3563 77.4993 53.5131 76.7867 53.5131H66.7954V59.3795H76.0191C76.7317 59.3795 77.2791 59.5363 77.6583 59.8468C78.0375 60.1574 78.2272 60.6032 78.2272 61.1812C78.2272 61.7592 78.0375 62.2051 77.6583 62.5156C77.2791 62.8261 76.7347 62.9829 76.0191 62.9829H66.7954V69.2674H76.9458C77.6583 69.2674 78.2027 69.4243 78.5758 69.7348C78.9489 70.0453 79.1355 70.4942 79.1355 71.0845C79.1355 71.6841 78.9489 72.1391 78.5758 72.4497C78.2027 72.7602 77.6583 72.917 76.9458 72.917H64.7342C64.0216 72.917 63.4773 72.7295 63.1041 72.3544C62.731 71.97 62.5445 71.4258 62.5445 70.7064Z" />
      <path d="M83.6095 70.7064V52.065C83.6095 51.3486 83.7899 50.8014 84.1539 50.4263C84.5147 50.0511 85.0438 49.8636 85.735 49.8636C86.4261 49.8636 86.9552 50.0511 87.3161 50.4263C87.677 50.8014 87.8605 51.3486 87.8605 52.065V59.6655L98.1392 50.457C98.6071 50.0511 99.124 49.8452 99.6897 49.8452C100.265 49.8452 100.717 50.0389 101.048 50.4232C101.378 50.7768 101.543 51.1949 101.543 51.6776C101.543 51.7514 101.528 51.9543 101.494 52.2802C101.46 52.6061 101.185 52.9966 100.662 53.4455L93.5977 59.7915L101.301 69.7071C101.662 70.1775 101.846 70.6541 101.846 71.1368C101.846 71.1799 101.837 71.3398 101.821 71.6195C101.806 71.8993 101.561 72.2129 101.093 72.5665C100.73 72.8555 100.317 73 99.8457 73C99.1729 73 98.6163 72.7172 98.1667 72.1484L90.5915 62.3926L87.8574 64.8831V70.7002C87.8574 71.4166 87.677 71.9639 87.313 72.339C86.9491 72.7141 86.4231 72.9016 85.7319 72.9016C85.0377 72.9016 84.5117 72.7141 84.1508 72.339C83.7899 71.97 83.6095 71.4258 83.6095 70.7064Z" />
      <path d="M47.8711 23.92L49.1617 14.73L41.8922 20.4518L47.8711 23.92Z" />
      <path d="M48.0115 8.64227C48.6354 8.15956 49.2623 7.84288 49.8892 7.68915L37.2648 0.362353C35.488 -0.667641 33.2677 0.620619 33.2677 2.68368V19.9999L48.0115 8.64227Z" />
      <path d="M67.8811 18.1306L54.85 10.567C55.0304 11.1912 55.0702 11.8952 54.9662 12.6793L52.489 31.708L67.8811 22.7732C69.658 21.7401 69.658 19.1636 67.8811 18.1306Z" />
      <path d="M46.8375 34.8687C46.5562 34.306 46.495 33.5189 46.6479 32.5074L47.1219 28.8609L37.9962 23.5634L35.0939 25.7986C34.4241 26.3244 33.8186 26.6441 33.2742 26.7579V38.2169C33.2742 40.28 35.4945 41.5683 37.2714 40.5383L46.8865 34.9579C46.8681 34.9302 46.8528 34.8994 46.8375 34.8687Z" />
    </svg>
  );
};

export default IconVatek;
