import { ListItemButton, ListItemText, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMenuPropType } from 'types/navigation';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { setBreadcrums } from '../../redux/layoutSlice';
import { useAppDispatch } from '../../../../store/store';

const NavSub = ({ item, level, setActiveNav }: IMenuPropType) => {
  const dispatch = useAppDispatch();
  const disabled = !!item?.disabled;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<boolean>(false);
  const { pathname } = useLocation();
  const linkUrl = item?.url;
  const [currentUrl, setCurrentUrl] = useState<string[]>([pathname]);

  useEffect(() => {
    return setCurrentUrl([pathname]);
  }, [pathname]);
  const setFocus = currentUrl.includes(linkUrl as string);

  const itemHandler = () => {
    setOpen(!open);
    navigate(`${item?.url}`);
    setActiveNav(item?.url);
    dispatch(setBreadcrums(item));
  };

  return (
    <ListItemButton
      disabled={disabled}
      className={setFocus ? 'nav-sub-open' : 'nav-sub'}
      onClick={itemHandler}
    >
      <ListItemText
        primary={
          <>
            <Typography className={setFocus ? 'nav-sub-name-open' : 'nav-sub-name'}>
              {item?.name}
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
};
export default NavSub;
