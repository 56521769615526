/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Box, ClickAwayListener, Fade, Paper, Popper, Typography } from '@mui/material';
import User1 from 'assets/icons/user-round.svg';
import { PATH } from 'constants/path';
import { logoutSuccess } from 'features/Login/redux/authSlice';

import icons from 'constants/icons';
import 'layouts/Header/components/ProfileSection/style.scss';
import LogoutIcon from '../../../../constants/icons/LogoutIcon';
import { getItem, LOCAL_STORAGE_KEY } from '../../../../utils/localStorage';
import { setBreadcrums, setCurrentMenuState } from '../../../Sidebar/redux/layoutSlice';
import { useAppSelector } from '../../../../store/store';

const ProfileSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AccountCircleOutlined, KeyIcon } = icons;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const userAvatar = useAppSelector((state) => state.userReducer.userAvatar);

  const logOut = () => {
    dispatch(logoutSuccess());
    navigate(PATH.LOGIN);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeView = () => {
    navigate(PATH.PROFILE);
    dispatch(setBreadcrums(''));
    dispatch(setCurrentMenuState(true));
    setOpen(false);
  };

  const changePassword = () => {
    navigate(PATH.CHANGE_PASSWORD);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const currentID = getItem(LOCAL_STORAGE_KEY.CURRENT_ID);
  const linkProfile = `/employee/view/${currentID}`;
  return (
    <>
      <Typography component={'span'} className="profile">
        <Avatar
          src={userAvatar ? userAvatar : User1}
          className="profile-chip-avatar"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="inherit"
        />
      </Typography>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box className="profileContainer">
                  <Box className="profileContent">
                    <Link to={linkProfile} style={style.link}>
                      <Box className="profileItem" onClick={changeView}>
                        <AccountCircleOutlined />
                        <Typography style={style.typography}>My Profile</Typography>
                      </Box>
                    </Link>
                  </Box>
                  <Box className="profileContent" onClick={changePassword}>
                    <Box className="profileItem">
                      <KeyIcon />
                      <Typography style={style.typography}>Change Password</Typography>
                    </Box>
                  </Box>
                  <Box className="profileContent" onClick={logOut}>
                    <Box className="profileItem">
                      <LogoutIcon style={{ paddingLeft: '4px' }} />
                      <Typography style={style.typography}>Logout</Typography>
                    </Box>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
const style = {
  typography: {
    paddingLeft: '16px',
    fontSize: '14px',
  },
  link: {
    textDecoration: 'none',
    color: '#000',
  },
};
export default ProfileSection;
