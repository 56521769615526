import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}
const CloudIcons = (props: StyleProps) => {
  return (
    <div>
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
      >
        <path
          d="M19.35 6.54C18.67 3.09 15.64 0.5 12 0.5C9.11 0.5 6.6 2.14 5.35 4.54C2.34 4.86 0 7.41 0 10.5C0 13.81 2.69 16.5 6 16.5H19C21.76 16.5 24 14.26 24 11.5C24 8.86 21.95 6.72 19.35 6.54ZM17 9.5L12 14.5L7 9.5H10V5.5H14V9.5H17Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CloudIcons;
