import { ButtonBase, Paper, Avatar, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import icons from 'constants/icons';
import 'features/Dashboard/style.scss';
import UserTask from './userTask';
import UserEvents from './userEvent';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from '../../store/store';
import { useEffect, useState } from 'react';
import { getOwnProfile, getUserAvatar, getUserOnBirthDay } from '../Users/redux/actions';
import BaseLoading from '../../components/Loading';
import BasePopup from '../../components/popup';
import LeaveForm from '../Dayoff/LeaveForm';
import { canAcceptPermission, handleAvatarSrc } from '../../constants';
import { Positions } from '../../constants/constants.interfaces';
import {
  approveLeaveAsync,
  getDashboardMyTaskByPM,
  getDashboardMyTaskByUser,
  getUserUpcomingLeave,
  rejectLeaveAsync,
} from '../Dayoff/redux/actions';
import { getTodayLeave } from '../Dayoff/redux/actions';
import { ILeaveRequestResponseData } from '../../types/dayOffType';
import UserUpcomingLeave from './userUpcomingLeave';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector((state: RootState) => state.userReducer.currentProfile);
  const isLoading = useAppSelector((state) => state.userReducer.isLoading);
  const { positions } = useAppSelector((state) => state.authReducer);
  const todayLeave = useAppSelector((state: RootState) => state.requestReducer.todayLeave);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const handleCancel = () => {
    setOpenPopup(false);
  };

  const refreshDashboard = () =>
    canAcceptPermission(positions, [Positions.PM, Positions.HR, Positions.ADMIN])
      ? dispatch(getDashboardMyTaskByPM())
      : dispatch(getDashboardMyTaskByUser());

  useEffect(() => {
    dispatch(getOwnProfile());
    dispatch(getUserAvatar());
    dispatch(getTodayLeave());
    dispatch(getUserUpcomingLeave());
    dispatch(getUserOnBirthDay());
    refreshDashboard();
  }, [dispatch]);

  const handleUpdateLeaveStatus = async (ids: string[], isApproval: boolean) => {
    if (isApproval) {
      await dispatch(approveLeaveAsync(ids));
    } else {
      await dispatch(rejectLeaveAsync(ids));
    }

    return refreshDashboard();
  };

  const { CakeOutlined, EventAvailable, HourglassTopOutlined, ErrorOutlineOutlined } = icons;
  return (
    <>
      {isLoading ? (
        <Box className="container-loading">
          <BaseLoading size={60} />
        </Box>
      ) : (
        <Box className="dashboard">
          <Box className="dashboard-left">
            <Typography className="dashboard-welcome-text">
              Welcome, {currentUser?.fullName}
            </Typography>
            <Box>
              <Paper className="dashboard-paper">
                <Box className="dashboard-my-title">
                  <EventAvailable />
                  <Typography className="dashboard-my-task-text">My Tasks</Typography>
                </Box>
                <UserTask
                  isEdit={true}
                  state="pending"
                  updateLeaveStatusCallback={handleUpdateLeaveStatus}
                />
              </Paper>
              <Paper className="dashboard-paper">
                <Box className="dashboard-comming-leave">
                  <Box style={style.boxUpcoming}>
                    <HourglassTopOutlined fontSize="small" />
                    <Typography className="dashboard-comming-leave-text">Upcoming Leave</Typography>
                  </Box>
                  <BasePopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <LeaveForm handleCancel={handleCancel} />
                  </BasePopup>
                  <ButtonBase
                    className="dashboard-comming-leave-button"
                    onClick={() => setOpenPopup(true)}
                  >
                    {' '}
                    + Request Leave
                  </ButtonBase>
                </Box>
                <UserUpcomingLeave />
              </Paper>
            </Box>
          </Box>
          <Box className="dashboard-right">
            <Paper className="dashboard-paper">
              <Box className="dashboard-my-title">
                <CakeOutlined fontSize="small" />
                <Typography className="dashboard-my-task-text"> Events</Typography>
              </Box>
              <UserEvents />
            </Paper>

            <Paper className="dashboard-paper">
              <Box className="dashboard-my-title">
                <ErrorOutlineOutlined />
                <Typography className="dashboard-my-task-text">Out of office today</Typography>
              </Box>
              {Object.values(todayLeave).some((v) => v.length !== 0) ? (
                <Box className="dashboard-out-of-office">
                  {todayLeave.allDay?.length > 0 && (
                    <>
                      <Chip className="dashboard-button" label="All day" />
                      {todayLeave.allDay?.map((item: ILeaveRequestResponseData) => (
                        <Box className="dashboard-account">
                          <CustomAvatar data={item} />
                          <Typography className="dashboard-user">{item.user.fullName}</Typography>
                        </Box>
                      ))}
                    </>
                  )}
                  {todayLeave.morning?.length > 0 && (
                    <>
                      <Chip label="Morning" className="dashboard-button-other" />
                      {todayLeave.morning?.map((item: ILeaveRequestResponseData) => (
                        <Box className="dashboard-account">
                          <CustomAvatar data={item} />
                          <Typography className="dashboard-user">{item.user.fullName}</Typography>
                        </Box>
                      ))}
                    </>
                  )}
                  {todayLeave.afternoon?.length > 0 && (
                    <>
                      <Chip label="Afternoon" className="dashboard-button-other" />
                      {todayLeave.afternoon?.map((item: ILeaveRequestResponseData) => (
                        <Box className="dashboard-account">
                          <CustomAvatar data={item} />
                          <Typography className="dashboard-user">{item.user.fullName}</Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              ) : (
                <Box className="dashboard-my-user-event">
                  <Typography className="dashboard-event-text">
                    Everybody is in the office today!
                  </Typography>
                </Box>
              )}
            </Paper>
          </Box>
        </Box>
      )}
    </>
  );
};
const style = {
  boxUpcoming: { display: 'flex', alignItems: 'center', paddingLeft: '18px' },
};
interface ICustomAvatarProps {
  data: ILeaveRequestResponseData;
}
const CustomAvatar = ({ data }: ICustomAvatarProps) => {
  return (
    <Avatar
      alt="Hoang Nguyen"
      src={handleAvatarSrc(data)}
      className="dashboard-avatar"
      sx={{ width: 42, height: 42 }}
    />
  );
};
export default Dashboard;
