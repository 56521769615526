import { PATH } from 'constants/path';
import icons from '../constants/icons/index';
const {
  DashboardOutlined,
  GroupsOutlined,
  WorkOutlineOutlined,
  BackupTable,
  CalendarMonthOutlined,
  TimeTracking,
} = icons;

export enum EMenuComponentType {
  GROUP,
  COLLAPSE,
  ITEM,
}

export enum MenuEnum {
  DEFAULT = 'Default',
  DASHBOARD = 'Dashboard',
  UTILITIES = 'Utilities',
  EMPLOYEES = 'Employees',
  PROJECTS = 'Projects',
  LEAVE = 'Leave',
  CALENDAR = 'Calendar',
  MYCALENDAR = 'My Calendar',
  APPROVALS_LEAVE = 'For Approvals',
  TIMETRACKING = 'Time Tracking',
  MYTIMESHEET = 'My Timesheet',
  APPROVALS_TIMETRACKING = 'For Approvals',
  REPORTS = 'Reports',
}

const MENU = {
  id: MenuEnum.UTILITIES,
  type: EMenuComponentType.GROUP,
  children: [
    {
      id: MenuEnum.DASHBOARD,
      name: MenuEnum.DASHBOARD,
      type: EMenuComponentType.ITEM,
      url: PATH.DASHBOARD,
      icon: DashboardOutlined,
      active: false,
      breadcrumbs: MenuEnum.DASHBOARD,
    },
    {
      id: MenuEnum.EMPLOYEES,
      name: MenuEnum.EMPLOYEES,
      type: EMenuComponentType.ITEM,
      url: PATH.EMPLOYEES,
      icon: GroupsOutlined,
      active: false,
      breadcrumbs: MenuEnum.EMPLOYEES,
    },
    {
      id: MenuEnum.PROJECTS,
      name: MenuEnum.PROJECTS,
      type: EMenuComponentType.ITEM,
      url: PATH.PROJECT,
      icon: WorkOutlineOutlined,
      active: false,
      breadcrumbs: MenuEnum.PROJECTS,
    },
    {
      id: MenuEnum.LEAVE,
      name: MenuEnum.LEAVE,
      type: EMenuComponentType.COLLAPSE,
      url: PATH.CALENDAR,
      icon: CalendarMonthOutlined,
      breadcrumbs: MenuEnum.LEAVE,
      active: false,
      subNav: [
        {
          id: MenuEnum.CALENDAR,
          name: MenuEnum.MYCALENDAR,
          title: MenuEnum.CALENDAR,
          type: EMenuComponentType.ITEM,
          url: PATH.CALENDAR,
          breadcrumbs: MenuEnum.CALENDAR,
        },
        {
          id: MenuEnum.APPROVALS_LEAVE,
          name: MenuEnum.APPROVALS_LEAVE,
          title: MenuEnum.APPROVALS_LEAVE,
          type: EMenuComponentType.ITEM,
          url: PATH.APPROVALDS_LEAVE,
          breadcrumbs: MenuEnum.APPROVALS_LEAVE,
        },
      ],
    },
    {
      id: MenuEnum.TIMETRACKING,
      name: MenuEnum.TIMETRACKING,
      type: EMenuComponentType.COLLAPSE,
      url: PATH.TIMESHEET,
      icon: TimeTracking,
      active: false,
      breadcrumbs: MenuEnum.TIMETRACKING,
      subNav: [
        {
          id: MenuEnum.MYTIMESHEET,
          name: MenuEnum.MYTIMESHEET,
          title: MenuEnum.MYTIMESHEET,
          type: EMenuComponentType.ITEM,
          url: PATH.TIMESHEET,
          active: false,
          breadcrumbs: MenuEnum.MYTIMESHEET,
        },
        {
          id: MenuEnum.APPROVALS_TIMETRACKING,
          name: MenuEnum.APPROVALS_TIMETRACKING,
          title: MenuEnum.APPROVALS_TIMETRACKING,
          type: EMenuComponentType.ITEM,
          url: PATH.APPROVALDS_TIMETRACKING,
          active: false,
          breadcrumbs: MenuEnum.APPROVALS_TIMETRACKING,
        },
        {
          id: MenuEnum.REPORTS,
          name: MenuEnum.REPORTS,
          title: MenuEnum.REPORTS,
          type: EMenuComponentType.ITEM,
          url: PATH.REPORTS,
          active: false,
          breadcrumbs: MenuEnum.REPORTS,
        },
      ],
    },
  ],
};

const MENU_USER = {
  id: MenuEnum.UTILITIES,
  type: EMenuComponentType.GROUP,
  children: [
    {
      id: MenuEnum.DASHBOARD,
      name: MenuEnum.DASHBOARD,
      type: EMenuComponentType.ITEM,
      url: PATH.DASHBOARD,
      icon: DashboardOutlined,
      active: false,
      breadcrumbs: MenuEnum.DASHBOARD,
    },
    {
      id: MenuEnum.PROJECTS,
      name: MenuEnum.PROJECTS,
      type: EMenuComponentType.ITEM,
      url: PATH.PROJECT,
      icon: WorkOutlineOutlined,
      active: false,
      breadcrumbs: MenuEnum.PROJECTS,
    },
    {
      id: MenuEnum.LEAVE,
      name: MenuEnum.LEAVE,
      type: EMenuComponentType.ITEM,
      url: PATH.CALENDAR,
      icon: CalendarMonthOutlined,
      active: false,
      breadcrumbs: MenuEnum.LEAVE,
    },
    {
      id: MenuEnum.TIMETRACKING,
      name: MenuEnum.TIMETRACKING,
      type: EMenuComponentType.ITEM,
      url: PATH.TIMESHEET,
      icon: TimeTracking,
      active: false,
      breadcrumbs: MenuEnum.TIMETRACKING,
    },
  ],
};

const MAIN_MENU = { MENU, MENU_USER };
export default MAIN_MENU;
