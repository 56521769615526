import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './style.scss';
import emailMarketingImg from '../../../assets/img/emailMaketingImg.png';
import ForgotPasswordBase from '../../forgotPassword/forgotPasswordBase';

const ConfirmEmailPage = () => {
  return (
    <ForgotPasswordBase>
      <Box className="email-img">
        <img src={emailMarketingImg} alt="email-marketing-img" />
      </Box>
      <Grid item className="content-information">
        <Stack alignItems="left" justifyContent="center" spacing={1}>
          <Typography
            variant="h1"
            fontSize="36px"
            fontWeight="700"
            className="content-infomation-header"
          >
            Check your mail
          </Typography>
          <Typography variant="body1" fontSize="14px">
            We have sent a password recover instructions to your email
          </Typography>
        </Stack>
      </Grid>
    </ForgotPasswordBase>
  );
};

export default ConfirmEmailPage;
