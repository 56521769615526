import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { instance } from 'services/api/api';
import {
  caculateWeeklyNow,
  formatDate,
  formatDateMMYYYY,
  handleUrlReport,
} from '../../../constants';
import toastConfig from '../../../utils/toastConfig';
import { SearchTimesheetStateI } from '../approvals/ApprovalHeaderComponent';
import { IFormExport } from '../interface';
import { IFormReport } from '../report/ReportHeaderComponent';
export interface TimeSheetFormState {
  date: Date;
  type: string;
  hours: number;
  project: string;
  details?: string;
}
interface ResponseError {
  message: string;
}
const now = moment();

const handleTimeSheetUrl = (url: string, name: string) =>
  createAsyncThunk(name, async (weekNum: number = 0) => {
    const dateTime = caculateWeeklyNow(weekNum, now);
    const response = await instance.get(
      `${url}?startDate=${dateTime.startWeek}&endDate=${dateTime.endWeek}`,
    );
    return response.data;
  });

export const getTimeSheets = handleTimeSheetUrl(
  'timesheets/get-timesheet-weekly-by-user',
  'get/nextTimesheet',
);

export const getTimesheetsAsync = createAsyncThunk(
  'get/timesheets',
  async (weekNum: number = 0) => {
    const dateTime = caculateWeeklyNow(weekNum, now);
    const response = await instance.get(
      `timesheets/get-timesheet-weekly-by-user?startDate=${dateTime.startWeek}&endDate=${dateTime.endWeek}`,
    );
    return response.data;
  },
);

export const getTimesheetsByMonthAsync = createAsyncThunk(
  'get/timesheets-by-month',
  async (data: SearchTimesheetStateI) => {
    const timeFilter = moment(data.date).format('YYYY-MM-DD');
    let url = `date=${timeFilter}`;
    if (data.status) url += `&status=${data.status}`;
    if (data.workingType) url += `&workingType=${data.workingType}`;
    const response = await instance.get(`timesheets/get-timesheet-by-month?${url}`);
    return response.data;
  },
);
export const getTimeSheetApprovals = createAsyncThunk(
  'get/timesheets-by-approvals',
  async (data: SearchTimesheetStateI) => {
    const timeFilter = moment(data.date).format('YYYY-MM-DD');
    const response = await instance.get(`timesheets/get-timesheet-by-month?date=${timeFilter}`);
    return response.data;
  },
);

export const approvalTimesheetsAsync = createAsyncThunk(
  'approval/timesheets',
  async (ids: string[]) => {
    const response = await instance.patch('timesheets/approval-timesheet', { ids });
    return response.data;
  },
);
export const rejectTimesheetsAsync = createAsyncThunk(
  'reject/timesheets',
  async (ids: string[]) => {
    const response = await instance.patch('timesheets/reject-timesheet', { ids });
    return response.data;
  },
);
export const createTimesheetAsync = createAsyncThunk(
  'create/timesheet',
  async (body: TimeSheetFormState) => {
    body = {
      ...body,
    };
    const dateTime = caculateWeeklyNow(0, now);

    try {
      await instance.post('timesheets/create-timesheet', body);
      const response = await instance.get(
        `timesheets/get-timesheet-weekly-by-user?startDate=${dateTime.startWeek}&endDate=${dateTime.endWeek}`,
      );
      return response.data;
    } catch (errors) {
      const messageErrors = errors as AxiosError<ResponseError>;
      if (messageErrors.response) {
        toastConfig.toastError(`${messageErrors.response.data.message}`);
      }
    }
  },
);

export const getMonthlyReport = createAsyncThunk(
  'report/get-monthly-report',
  async (data: IFormReport) => {
    let url = `date=${data.date}`;
    data.project.forEach((item: string) => {
      return (url = `${url}&projects=${item}`);
    });
    const response = await instance.get(
      handleUrlReport('timesheets/get-monthly-report', { reports: url, ...data.params }),
    );
    return response.data;
  },
);

const handleExportExcelReport = (
  projectName: string,
  date: string,
  data: AxiosResponse<Blob, string>,
) => {
  const url = window.URL.createObjectURL(new Blob([data.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${projectName}-${date}.xlsx`);
  document.body.appendChild(link);
  link.click();
  return;
};

export const exportTimeSheetReport = createAsyncThunk(
  'report/export-time-sheet',
  async (data: IFormExport) => {
    const convertDate = formatDate(moment(data.date), formatDateMMYYYY);

    await instance
      .get(`timesheets/export-timesheets-report?date=${data.date}&project=${data.project.id}`, {
        method: 'GET',
        responseType: 'blob',
      })
      .then((response) => {
        handleExportExcelReport(data.project.projectName, convertDate, response);
      })
      .catch((error: AxiosError) => {
        toastConfig.toastError(error.message);
      });
  },
);
