import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleParamsUrl, limitDefault, offsetDefault } from '../../../constants';
import { BaseParamsI } from 'constants/interfaces';
import { instance } from 'services/api/api';
import { IProjectUpdateType } from 'types/projectType';
import { LOCAL_STORAGE_KEY, getItem } from 'utils/localStorage';
import { Positions } from 'constants/constants.interfaces';
import { ProjectCreateInterface, UpdateProjectInterface } from '../interfaces';

const handleProjectUrl = (url: string, name: string) =>
  createAsyncThunk(name, async (params: BaseParamsI) => {
    const response = await instance.get(
      handleParamsUrl({
        baseUrl: url,
        searchName: 'projectName',
        options: params,
      }),
    );
    return response.data;
  });

export const getAllProject = handleProjectUrl('projects/get-all-projects', 'all/getAll');
export const findByProject = handleProjectUrl('projects/get-all-projects', 'all/findByProject');
export const findPmProject = handleProjectUrl('projects/get-pm-project', 'all/findPmProject');

export const getProjectByPm = createAsyncThunk('all/getAllPm', async () => {
  const response = await instance.get(`projects/get-pm-project`);
  return response.data;
});

export const getProjectByUser = createAsyncThunk('all/getProjectByUser', async () => {
  const response = await instance.get(`projects/get-user-project`);
  return response.data;
});

export const deleteProjectThunk = createAsyncThunk('project/delete', async (_id: string) => {
  await instance.delete(`projects/${_id}`);
  const response = await instance.get(
    handleParamsUrl({
      baseUrl: 'projects/get-all-projects',
      searchName: 'projectName',
      options: {},
    }),
  );
  return response.data;
});

export const createProjectThunk = createAsyncThunk(
  'create/project',
  async (body: ProjectCreateInterface) => {
    await instance.post(`projects/create-project`, body);
    const response = await instance.get(
      handleParamsUrl({
        baseUrl: 'projects/get-all-projects',
        searchName: 'projectName',
        options: {
          limit: limitDefault,
          offset: offsetDefault,
        },
      }),
    );
    return response.data;
  },
);

export const updateProjectThunk = createAsyncThunk(
  'update/project',
  async ({ _id, ...body }: UpdateProjectInterface) => {
    const positions = getItem(LOCAL_STORAGE_KEY.POSITIONS);
    await instance.patch(`projects/update-project/${_id}`, body);
    const checkPosition = positions.includes(Positions.ADMIN);
    if (checkPosition) {
      const response = await instance.get(
        handleParamsUrl({
          baseUrl: 'projects/get-all-projects',
          searchName: 'projectName',
          options: {},
        }),
      );
      return response.data;
    }
    const response = await instance.get(
      handleParamsUrl({
        baseUrl: 'projects/get-pm-project',
        searchName: 'projectName',
        options: {},
      }),
    );
    return response.data;
  },
);

export const addProjectThunk = createAsyncThunk(
  'add/addMember',
  async ({ _id, member }: IProjectUpdateType) => {
    await instance.patch(`projects/add-member/${_id}`, { member });
    const response = await instance.get(`projects/get-all-projects`);

    return response.data;
  },
);

export const removeProjectThunk = createAsyncThunk(
  'remove/removeMember',
  async ({ _id, member }: IProjectUpdateType) => {
    await instance.patch(`projects/remove-member/${_id}`, { member });
    const response = await instance.get(`projects/get-all-projects`);
    return response.data;
  },
);
export const getProjectById = createAsyncThunk('project/getProjectById', async (id: string) => {
  const response = await instance.get(`projects/get-project-by-id/${id}`);
  return response.data;
});
