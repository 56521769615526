export const styleList = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  avatar: { width: 32, height: 32 },
  arrow: { color: '#5F5446' },
  chipContainer: {
    borderRadius: '8px',
    minWidth: '124px',
    height: '32px',
  },
  typography400: {
    fontSize: '14px',
    fontWeight: '400',
  },
  gridItemNoData: {
    border: '1px solid #DAE1F5',
    height: '60px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItem: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    border: '1px solid #DAE1F5',
    backgroundColor: '#FFFFFF',
    height: '52px',
  },
  typographyTotal: {
    borderRadius: '8px',
    minWidth: '80px',
    fontSize: '14px',
    maxWidth: '360px',
  },
  btnReject: {
    bgcolor: '#DD2C2C',
    '&:hover': {
      backgroundColor: '#DD2C2C',
    },
  },
  iconBtnReject: {
    color: '#DD2C2C',
    margin: '0 20px',
  },
  iconBtnApproval: {
    color: '#2AAA8A',
    margin: '0 20px',
  },
};
export const styleHeader = {
  container: {
    width: '100%',
    height: '104px',
    borderRadius: '6px',
    border: '2px solid #ECEEF7',
    backgroundColor: '#FFFFFF',
    padding: '0 24px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '20px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: {
    width: '55px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  buttonWeekly: {
    minWidth: '247px',
    height: '40px',
  },
  buttonApply: {
    width: '109px',
    height: '40px',
  },
  controllerSelect: {
    width: '164px',
    height: '40px',
    margin: '0 10px',
  },
};
