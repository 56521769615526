import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { defaultAllowedImageSize } from '../../../../../constants';
import { BAD_REQUEST } from '../../../../../constants/constants.message';
import { requiredFieldRules } from '../../../../../constants/rules';
import { useAppDispatch } from '../../../../../store/store';
import toastConfig from '../../../../../utils/toastConfig';
import { uploadAvatar } from '../../../redux/actions';
import '../style.scss';

interface IFormAvatar {
  avatar: File | null;
}

interface IFormAvatarProps {
  handleClosePopup: () => void;
  handleSetAvatar: (result: File) => void;
  handlePreviewAvatar: (result: string) => void;
}

const AvatarForm = ({
  handleClosePopup,
  handleSetAvatar,
  handlePreviewAvatar,
}: IFormAvatarProps) => {
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const methods = useForm<IFormAvatar>({
    defaultValues: {
      avatar: null,
    },
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const onSubmit = handleSubmit(() => {
    if (preview) {
      handlePreviewAvatar(preview);
      image && handleSetAvatar(image);
    }
    handleClosePopup();
    return;
  });

  return (
    <>
      <Box className="avatar-form-header">
        <h2>Choose your avatar</h2>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="avatar-form-container">
            {preview ? (
              <Box
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef?.current?.click();
                }}
              >
                <img
                  className="avatar-preview"
                  src={preview}
                  style={{ objectFit: 'cover' }}
                  alt="avatar"
                />
              </Box>
            ) : (
              <button
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef?.current?.click();
                }}
                className="avatar-upload-btn"
              >
                Add Image
              </button>
            )}
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              accept=".jpg,.jpeg,"
              onChange={(event) => {
                const files = event.target.files ?? [];
                if (!files.length) return;
                if (files[0].size > defaultAllowedImageSize)
                  return toastConfig.toastError(BAD_REQUEST.INVALID_IMAGE_SIZE);
                return setImage(files[0]);
              }}
            />
          </div>
          <Box className="footerUser">
            <Button className="btnCancelOrBack" onClick={handleClosePopup}>
              Cancel
            </Button>
            <Box className="btnNextOrSave">
              <Button type="submit" className="btnButton">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default AvatarForm;
