import { CheckCircle } from '@mui/icons-material';
import { capitalize, Grid, Stack, Typography } from '@mui/material';
import { TimesheetStatus, TimeSheetStatusT, WorkingType } from 'constants/constants.interfaces';
import CancelIcon from 'constants/icons/CancelIcon';
import WorkOfficeIcon from 'constants/icons/WorkIcon';
import React, { Fragment } from 'react';
import { Item } from '.';
import { numberToHours } from '../../constants';
import OfficeIcon from '../../constants/icons/OfficeIcon';
import TimerIcon from '../../constants/icons/TimerIcon';
import { ApprovalChildrenStatusEnum } from './interface';
import { TimesheetItemState } from './redux/timesheetSlice';

const handleType = (type: string) => {
  switch (type) {
    case WorkingType.BONUS:
      return ApprovalChildrenStatusEnum.Bonus;
    case WorkingType.OT:
      return ApprovalChildrenStatusEnum.OT;
    default:
      return '';
  }
};

const ListTimeSheetComponent: React.FC<TimesheetItemState> = (props: TimesheetItemState) => {
  const IconByStatus = () => {
    const IconComponent: React.FC<{
      Icon: JSX.Element;
      status: TimeSheetStatusT;
      color: string;
      background: string;
    }> = ({ Icon, status, color, background }) => {
      return (
        <Grid
          item
          container
          bgcolor={background}
          xs={12}
          md={12}
          gap={1}
          alignItems={'center'}
          textAlign={'center'}
          justifyContent="space-around"
          className="status-title"
        >
          <Grid item>{Icon}</Grid>
          <Grid item xs={10} md={8} className="auto-short-content">
            <Typography className="box-status" color={color}>
              {capitalize(status.toLowerCase())}
            </Typography>
          </Grid>
        </Grid>
      );
    };

    switch (props.status) {
      case TimesheetStatus.APPROVED:
        return (
          <IconComponent
            Icon={<CheckCircle style={{ fontSize: 15, color: '#009D10' }} />}
            status={props.status}
            color="#00C113"
            background="#E9FFF3"
          />
        );
      case TimesheetStatus.PENDING:
        return (
          <IconComponent
            Icon={<WorkOfficeIcon style={{ color: '#F8991D', fontSize: 15 }} />}
            status={props.status}
            color="#F8991D"
            background="#FFF4DF"
          />
        );
      default:
        return (
          <IconComponent
            Icon={<CancelIcon style={{ fontSize: 15, color: '#F05161' }} />}
            status={'REJECTED'}
            color="#DD2C2C"
            background="#FFEDED"
          />
        );
    }
  };
  const customType = handleType(props.type);
  return (
    <Fragment>
      <Item className="item-box-timesheet">
        <Stack direction="column" justifyContent="space-around" alignItems="flex-start" spacing={1}>
          <Grid
            item
            container
            bgcolor={'#E4FFEA'}
            xs={12}
            md={12}
            gap={1}
            alignItems={'center'}
            textAlign={'center'}
            justifyContent="space-around"
            className="status-title"
          >
            <IconByStatus />
          </Grid>

          <Grid item container gap={1} xs={12} justifyContent="space-around">
            <Grid item alignSelf="center">
              <OfficeIcon style={{ fontSize: 15 }} />
            </Grid>
            <Grid item xs={10} md={8} className="auto-short-content" alignSelf="center">
              <Typography className="buttonTextTitle-child-justify">{props.projectName}</Typography>
            </Grid>
          </Grid>

          <Grid item container gap={1} xs={12} justifyContent="space-around">
            <Grid item alignSelf="center">
              <TimerIcon style={{ fontSize: 15 }} />
            </Grid>
            <Grid item xs={10} md={8} className="auto-short-content" alignSelf="center">
              <Typography className="buttonTextTitle-child-description">
                {numberToHours(props.hours * 60)}
                {customType ? ` - ${customType}` : ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            // xs={12}
            className="auto-short-content"
            justifyContent="stretch"
          ></Grid>
        </Stack>
      </Item>
    </Fragment>
  );
};

export default ListTimeSheetComponent;
