import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { titleProject } from 'features/formProjectAndUser/constant/constant';
import FormListProjects from 'features/formProjectAndUser/formTable/FormProjectsTable';
import { getAllProject, findByProject, getProjectByUser, getProjectByPm } from './redux/actions';
import { BaseParamsI } from 'constants/interfaces';
import { NameTablesE } from '../../types/formProjectAndUserType';
import { canAcceptPermission } from '../../constants';
import { IFormProjectItemProps } from '../formProjectAndUser/interface';
import { Positions } from '../../constants/constants.interfaces';

const Projects = () => {
  const dispatch = useAppDispatch();
  const { projectList, total, paginateTotal } = useAppSelector((state) => state.projectReducer);
  const { positions } = useAppSelector((state) => state.authReducer);

  const isViewProjectByPM =
    canAcceptPermission(positions, [Positions.PM]) &&
    !canAcceptPermission(positions, [Positions.ADMIN, Positions.HR]);

  useEffect(() => {
    if (isViewProjectByPM) {
      dispatch(getProjectByPm());
    } else if (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])) {
      // dispatch(getAllProject({}));
    } else {
      dispatch(getProjectByUser());
    }
  }, []);

  const handleListProject = (params: BaseParamsI) => {
    if (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])) {
      return dispatch(findByProject(params));
    }
    return;
  };

  const mapProjects = projectList.map((item) => ({
    name: item?.projectName,
    contact: item?.projectManager?.fullName,
    date: item?.startDate,
    level: item?.state,
    idProject: item?._id,
  })) as unknown as IFormProjectItemProps[];

  const buttonName = 'Add Project';
  return (
    <>
      <FormListProjects
        title={titleProject}
        listProjects={mapProjects}
        namePage={NameTablesE.PROJECTS}
        callbackForm={handleListProject}
        totalPage={paginateTotal}
        total={total}
        buttonName={buttonName}
      />
    </>
  );
};

export default Projects;
