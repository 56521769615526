import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import './style.scss';
import avatar from '../../../../assets/img/avatarAddUser.png';
import BaseButton from '../../../../components/button';
import { Button, Grid, Input, Typography } from '@mui/material';
import ControllerTextFied from '../../../../components/inputTexField';
import {
  validationInputField,
  validationEmailRules,
  validationNumberRules,
  validationPhoneNumberRules,
  validationUsernameRules,
  validationAddresssRules,
  validateFieldNameRequired,
} from '../../../../constants/rules';
import { FormProvider, useForm } from 'react-hook-form';
import ControllerDateTeampalte, { ControllerDateField } from 'components/inputDate';
import { BaseParamsI, IKeyState } from '../../../../constants/interfaces';
import { IUserInfo } from '../../../../types/userType';
import {
  Positions,
  InitialStatus,
  searchName,
  StatusSearch,
} from '../../../../constants/constants.interfaces';
import ControllerSelect from '../../../../components/inputFromSelect';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import icons from '../../../../constants/icons';
import { creatUser, getAllMentor, updateUser, uploadAvatar } from '../../redux/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllPosition, getAllLevel } from '../../../../reducers/configs/actions';
import { findByProject } from '../../../Projects/redux/actions';
import { canAcceptPermission, regexNumber } from '../../../../constants';
import { IFormEmployees } from '../../interfaces';
import useDebounce from '../../../../hooks/useDebounce';
import BasePopup from '../../../../components/popup';
import '../../../Dayoff/LeaveForm/style.scss';
import AvatarForm from './AvatarForm';
import { clearInitialStatus } from '../../redux/userSlice';
type Props = {};

type typeList = Partial<IKeyState & IUserInfo>;

const AddContent = (props: Props) => {
  const date = new Date();
  const { detailUser } = useAppSelector((state) => state.userReducer);
  const { level, onboardingMentor, positions } = detailUser;
  const { id } = useParams();
  const methods = useForm<IFormEmployees>({
    defaultValues: {
      fullName: '',
      username: '',
      email: '',
      password: '',
      citizenID: '',
      phone: '',
      address: '',
      level: '',
      onboardingMentor: '',
      positions: [],
      onboardingDate: date,
      dateOfBirth: date,
    },
  });
  const {
    formState: { isDirty },
  } = methods;
  const { handleSubmit, reset, setValue } = methods;
  const [status, setStatus] = React.useState<string>('');
  const [valueSearch, setValueSearch] = React.useState<string>('');
  const configState = useAppSelector((state) => state.configReduce);
  const { positions: currentUserPosition } = useAppSelector((state) => state.authReducer);
  const { listMentor, initialStatus } = useAppSelector((state) => state.userReducer);
  const userAvatar = useAppSelector((state) => state.userReducer.detailUser.avatar);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<string | undefined>(userAvatar?.src);
  const [image, setImage] = React.useState<File | null>(null);
  const debounceTextSearch = useDebounce<string>(valueSearch, 1000);

  const { KeyboardArrowDownIcon } = icons;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBackToPage = () => {
    return navigate(-1);
  };

  const canEdit = canAcceptPermission(currentUserPosition, [Positions.HR, Positions.ADMIN]);

  useEffect(() => {
    const paramApi: BaseParamsI = { search: debounceTextSearch };
    if (status) {
      switch (status) {
        case StatusSearch.mentor:
          dispatch(getAllMentor({ ...paramApi }));
          break;
        case StatusSearch.position:
          dispatch(getAllPosition({ ...paramApi, searchByKey: searchName.position }));
          break;
        case StatusSearch.level:
          dispatch(getAllLevel({ ...paramApi, searchByKey: searchName.level }));
          break;
        case StatusSearch.project:
          dispatch(findByProject({ ...paramApi }));
          break;
        default:
          break;
      }
      setValueSearch('');
    }
  }, [dispatch, status, debounceTextSearch]);
  useEffect(() => {
    if (id) {
      reset({
        ...detailUser,
        level: level.key,
        positions: positions.map((item) => item.key),
        onboardingMentor: onboardingMentor.fullName,
      });
    } else {
      setPreview('');
    }
  }, []);

  const handlePreviewAvatar = (dataUrl: string) => {
    setPreview(dataUrl);
  };

  const handleSetAvatar = (file: File) => {
    setImage(file);
  };

  const handleFormAvatarSrc = () => {
    return preview || avatar;
  };

  const enteringNumberOnly = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!regexNumber.test(event.key)) {
      event.preventDefault();
    }
  };

  const ListData = (listNameData: typeList[]) => {
    const list = listNameData.map((e: typeList) => ({
      key: e._id,
      value: e?.value || e?.fullName,
    }));
    return list as IKeyState[];
  };

  const onSubmit = handleSubmit((data: IFormEmployees) => {
    const dataSubmit = {
      ...data,
      address: data.address.trim(),
      fullName: data.fullName.trim(),
    };
    if (id) {
      dispatch(updateUser(dataSubmit));
      image && dispatch(uploadAvatar({ id: data._id, image }));
      handleBackToPage();
    } else {
      dispatch(creatUser({ ...dataSubmit, image }));
    }
  });

  if (initialStatus === InitialStatus.SUCCEEDED) {
    handleBackToPage();
    dispatch(clearInitialStatus(''));
  }

  const positionDefault = () => {
    if (id) {
      return positions.map((item) => ({
        value: item.value,
        key: item._id,
      }));
    }
  };
  const onboardingMentorDefault = () => {
    if (id) {
      return {
        value: onboardingMentor.fullName,
        key: onboardingMentor._id,
      };
    }
  };
  const levelDefault = () => {
    if (id) {
      return {
        value: level.value,
        key: level._id,
      };
    }
  };
  const nameButton = () => {
    if (id) {
      return 'Change Avatar';
    }
    return 'Upload Avatar';
  };

  const changeAvatarButtonClick = () => {
    setOpenPopup(true);
  };

  return (
    <Box className="body-content">
      <Box className="container-content">
        <Box className="container-content-left">
          <img src={handleFormAvatarSrc()} alt="" className="style-img" />
          <BaseButton name={nameButton()} onClick={changeAvatarButtonClick} />
        </Box>
        <Box className="container-content-right">
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Box>
                <Typography variant="h2" component="h2" className="style-typography">
                  Personal details
                </Typography>
                <Box>
                  <Grid container columnSpacing={{ md: 7 }} columns={16}>
                    <Grid item xs={8}>
                      <ControllerTextFied
                        rules={validationUsernameRules}
                        name="username"
                        label="Username"
                        inputProps={{
                          maxLength: 50,
                        }}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ControllerTextFied
                        rules={validationInputField('Full Name')}
                        name="fullName"
                        label="Full Name"
                        inputProps={{
                          maxLength: 50,
                        }}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ControllerTextFied
                        rules={validationEmailRules}
                        name="email"
                        label="Email Address"
                        inputProps={{
                          maxLength: 50,
                        }}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ControllerTextFied
                        rules={validationPhoneNumberRules}
                        name="phone"
                        label="Phone Number"
                        inputProps={{
                          maxLength: 10,
                        }}
                        onKeyPress={enteringNumberOnly}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ControllerTextFied
                        rules={validationAddresssRules}
                        name="address"
                        label="Address"
                        inputProps={{
                          maxLength: 100,
                        }}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ControllerDateTeampalte
                        rules={validateFieldNameRequired('Date of Birth')}
                        name="dateOfBirth"
                        label="Date of Birth"
                        disableFuture={true}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ControllerTextFied
                        rules={validationNumberRules}
                        name="citizenID"
                        label="National ID"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={enteringNumberOnly}
                        disabled={!canEdit}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="employment-details">
                <Typography
                  variant="h2"
                  component="h2"
                  className="style-typography"
                  style={{ paddingBottom: '10px' }}
                >
                  Employment details
                </Typography>
                <Box>
                  <Grid>
                    <Grid container columnSpacing={{ md: 7 }} columns={16} alignItems="center">
                      <Grid item xs={8}>
                        <ControllerDateField
                          rules={validateFieldNameRequired('Onboarding Date')}
                          name="onboardingDate"
                          label="Onboarding Date"
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ControllerSelect
                          name="level"
                          onFocus={() => setStatus(StatusSearch.level)}
                          rules={validateFieldNameRequired('Level')}
                          options={ListData(configState.listLevel)}
                          getOptionLabel={(option) => (option as IKeyState).value || ''}
                          label="Level"
                          onInputChange={(_, values: string) => {
                            setValueSearch(values);
                          }}
                          size="small"
                          popupIcon={<KeyboardArrowDownIcon />}
                          defaultValues={levelDefault()}
                          disabled={!canEdit}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ md: 7 }} columns={16}>
                      <Grid item xs={8}>
                        <ControllerSelect
                          name="onboardingMentor"
                          onFocus={() => setStatus(StatusSearch.mentor)}
                          rules={validateFieldNameRequired('Onboarding Mentor')}
                          options={ListData(listMentor)}
                          getOptionLabel={(option) => (option as IKeyState).value ?? ''}
                          label="Onboarding Mentor"
                          onInputChange={(_, values: string) => {
                            setValueSearch(values);
                          }}
                          size="small"
                          popupIcon={<KeyboardArrowDownIcon />}
                          defaultValues={onboardingMentorDefault()}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <ControllerSelect
                          name="positions"
                          onFocus={() => setStatus(StatusSearch.position)}
                          rules={validateFieldNameRequired('Position')}
                          options={ListData(configState.listPostion)}
                          getOptionLabel={(option) => (option as IKeyState)?.value ?? ''}
                          label="Position"
                          onInputChange={(_, values: string) => {
                            setValueSearch(values);
                          }}
                          size="small"
                          popupIcon={<KeyboardArrowDownIcon />}
                          defaultValues={positionDefault()}
                          disabled={!canEdit}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="style-box-btn">
                <Button className="btn-cancel" onClick={handleBackToPage}>
                  Cancel
                </Button>
                <Button type="submit" className="btn-save">
                  Save
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
        <BasePopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <AvatarForm
            handleClosePopup={() => {
              setOpenPopup(false);
            }}
            handlePreviewAvatar={handlePreviewAvatar}
            handleSetAvatar={handleSetAvatar}
          />
        </BasePopup>
      </Box>
    </Box>
  );
};

export default AddContent;
