import { toast } from 'react-toastify';

const toastSuccess = (message: string) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
  });
};

const toastError = (message: string) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
  });
};

const toastConfig = { toastError, toastSuccess };

export default toastConfig;
