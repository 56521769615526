import React, { useEffect } from 'react';
import RootRouter from 'routes/rootRouter';
import './App.scss';
import packageJson from '../package.json';

function App() {
  const versionLocal = localStorage.getItem('version') ?? '';

  const clearStorageByVersion = (version?: string) => {
    const packageVersion = packageJson.version;
    if (!version) {
      return localStorage.setItem('version', packageVersion);
    }
    if (version !== packageVersion) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    return;
  };

  useEffect(() => {
    clearStorageByVersion(versionLocal);
  }, [versionLocal]);

  return (
    <div className="App">
      <RootRouter />
    </div>
  );
}

export default App;
