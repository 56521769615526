import { Box, Autocomplete, TextField, Button } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DefaultValueI, PositionsEnum } from '.';
import icons from '../../../../constants/icons';
import { IKeyState } from '../../../../constants/interfaces';
import useDebounce from '../../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { IUserInfo } from '../../../../types/userType';
import { findByUser } from '../../../Users/redux/actions';
import { checkDuplicateProjectMember } from './constants';
import { style } from './style';

type Props = {
  inputField: Map<number, IKeyState>;
  setInputField: (value: Map<number, IKeyState>) => void;
  idManager?: string;
};

const InputMemberProject = (props: Props) => {
  const { inputField, setInputField, idManager } = props;
  const { AddEmployeeIcons, RemoveMemberIcons, KeyboardArrowDownIcon, Clear } = icons;
  const { id: idProject } = useParams();
  const { member } = useAppSelector((state) => state.projectReducer.projectDetail);
  const { userList } = useAppSelector((user) => user.userReducer);

  const defaultInputMember = new Map();
  const [indexMember, setIndexMember] = React.useState<number>(1);
  const [valueSearch, setValueSearch] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>('');

  const debounceTextSearch = useDebounce<string>(valueSearch, 1000);
  const dispatch = useAppDispatch();

  const listMemberProjects = useMemo(() => {
    return Array.from(inputField.entries());
  }, [inputField]);

  const totalEmployees = listMemberProjects.length;

  const listMemberIds = Array.from(inputField.values()).map((member) => member.key);

  const listEmployees = userList.map((employees: IUserInfo) => ({
    key: employees._id,
    value: employees.fullName,
  }));

  const onInitProjectMember = (item: Map<number, IKeyState>, index: number) => {
    return setInputField(new Map(item.set(index, { key: '', value: '', error: false })));
  };

  useEffect(() => {
    onInitProjectMember(defaultInputMember, indexMember);
  }, []);

  useEffect(() => {
    if (status) {
      dispatch(findByUser({ search: debounceTextSearch, limit: 100 }));
    }
  }, [dispatch, status, debounceTextSearch]);

  const handleRemoveMemberByProject = (id: number) => {
    inputField.delete(id);
    setInputField(new Map(inputField));
  };

  const handleAddMemberToProject = (index: number) => {
    const indexEmployees = index + 1;
    setValueSearch('');
    setIndexMember(indexEmployees);
    return onInitProjectMember(inputField, indexEmployees);
  };

  const { control, setValue } = useFormContext<DefaultValueI>();

  const handleSetMemberToMap = (id: number, data: IKeyState) => {
    return inputField.set(id, data);
  };

  const handleSetValueProjectMember = () => {
    return member.map((e, index) => {
      return setInputField(
        new Map(
          handleSetMemberToMap(index, {
            key: e._id ?? '',
            value: e.fullName ?? '',
            error: false,
          }),
        ),
      );
    });
  };

  const handleClearInputValue = (id: number) => {
    return setInputField(
      new Map(
        inputField.set(id, {
          key: '',
          value: '',
          error: false,
        }),
      ),
    );
  };
  useEffect(() => {
    if (idProject) handleSetValueProjectMember();
  }, [idProject]);
  const defaultMemberProject = (key: string, value: string) => {
    if (idProject) {
      return { key, value };
    }
    return;
  };

  const handleErrorProjectMember = (
    errorHook?: FieldError,
    errorMap?: IKeyState,
    idManagerProject?: string,
  ) => {
    if (errorHook) {
      return errorHook.message;
    } else if (errorMap?.error || idManagerProject === errorMap?.key) {
      return (
        <Box style={{ color: '#d32f2f' }}>
          This member has already been selected. Please choose different member.
        </Box>
      );
    }
    return null;
  };
  const setValueMember = (id: number, key: string) =>
    setValue(`member-${id}`, key, { shouldValidate: true });

  return (
    <>
      <>
        {listMemberProjects.map((item, index) => {
          const [idProjectMember, dataProjectMember] = item;

          const duplicateMember = checkDuplicateProjectMember(listMemberIds);

          if (!duplicateMember) {
            const projectMember = inputField.get(idProjectMember);
            projectMember &&
              handleSetMemberToMap(idProjectMember, {
                key: projectMember.key,
                value: projectMember.value,
                error: false,
              });
          }

          return (
            <Box className="project-manage-member-boxSelect" key={idProjectMember}>
              <Box style={{ width: '100%' }}>
                <Controller
                  control={control}
                  name={`member-${idProjectMember}`}
                  defaultValue={dataProjectMember.key}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    return (
                      <Autocomplete
                        options={listEmployees}
                        popupIcon={<KeyboardArrowDownIcon />}
                        getOptionLabel={(option) => (option as IKeyState).value ?? ''}
                        onFocus={() => {
                          setValueSearch('');
                          return setStatus(PositionsEnum.Member);
                        }}
                        onChange={(_event, values: IKeyState | null) => {
                          if (values) {
                            onChange(values.key);
                            const checkMember = listMemberIds.some((item) => item === values.key);
                            handleSetMemberToMap(idProjectMember, {
                              key: values.key,
                              value: values.value,
                              error: checkMember,
                            });
                            setValueMember(idProjectMember, values.key);
                          }
                        }}
                        onInputChange={(_event, value: string) => {
                          if (!value) {
                            handleClearInputValue(idProjectMember);
                            return setValueMember(idProjectMember, '');
                          }
                          return setValueSearch(value);
                        }}
                        clearIcon={
                          <Box
                            onClick={() => {
                              return handleClearInputValue(idProjectMember);
                            }}
                            style={{
                              height: '23px',
                            }}
                          >
                            <Clear style={style.iconClear} />
                          </Box>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...{ ...params, error: !!error }}
                            label="Select Project Member"
                            value={value}
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            size="small"
                            helperText={handleErrorProjectMember(
                              error,
                              inputField.get(idProjectMember),
                              idManager,
                            )}
                          />
                        )}
                        defaultValue={defaultMemberProject(
                          dataProjectMember.key,
                          dataProjectMember.value,
                        )}
                      />
                    );
                  }}
                />
              </Box>
              {listMemberProjects.length > 1 ? (
                <Box
                  style={{ marginLeft: '24px' }}
                  onClick={() => {
                    return handleRemoveMemberByProject(idProjectMember);
                  }}
                >
                  <RemoveMemberIcons style={{ marginTop: '6px' }} />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          );
        })}
      </>
      <>
        <Button
          className="project-manage-member-buttonAddMember"
          startIcon={<AddEmployeeIcons />}
          onClick={() => handleAddMemberToProject(idProject ? totalEmployees : indexMember)}
        >
          Add Project Member
        </Button>
      </>
    </>
  );
};

export default InputMemberProject;
