import { Moment } from 'moment';

export enum ApprovalChildrenStatusEnum {
  Bonus = 'Bonus',
  OT = 'OT',
  Normal = 'Normal',
}

interface KeyItem {
  _id: string;
  value: string;
}

interface ListTimeSheetBase {
  _id: string;
  date: Date;
  details: string;
  hours: number;
  type: KeyItem;
  status: KeyItem;
}
export type TimesheetCustomData = Omit<ListTimeSheetBase, 'type' | 'status'> & {
  type: string;
  status: string;
};

export type DaysInMonthI = Omit<ApprovalChildrenProps, '_id' | 'detail' | 'type'> & {
  children: TimesheetCustomData[];
};

interface ApprovalChildrenBase {
  _id: string;
  name: string;
  avatar: string;
  normal: number;
  ot: number;
  bonus: number;
  total: number;
  status: string;
  hours: number;
}

export type UserChildrenBase = ApprovalChildrenBase & {
  children: DaysInMonthI[];
};
export type NewApprovalTimesheetI = {
  _id: string;
  projectName: string;
  children: UserChildrenBase[];
} & ApprovalChildrenProps;

export type ApprovalChildrenProps = Partial<ApprovalChildrenBase> & {
  date?: Date;
  details?: string;
  type?: string;
};

export interface IChildrenReport
  extends Pick<ApprovalChildrenBase, '_id' | 'name' | 'normal' | 'ot' | 'bonus' | 'avatar'> {
  children: {
    date: Moment;
    normal: number;
    ot: number;
    bonus: number;
  }[];
}

export type IReportList = {
  _id: string;
  projectName: string;
  children: IChildrenReport[];
};

export interface IFormExport {
  date: string;
  project: {
    id: string;
    projectName: string;
  };
}
