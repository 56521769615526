import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import authReducer from 'features/Login/redux/authSlice';
import projectReducer from 'features/Projects/redux/projectSlice';
import userReducer from 'features/Users/redux/userSlice';
import layoutReducer from 'layouts/Sidebar/redux/layoutSlice';
import requestReducer from 'features/Dayoff/redux/dayoffSlice';
import paginateReduce from './slices/paginateSlice';
import timesheetReduce from 'features/timesheet/redux/timesheetSlice';
import configReduce from 'reducers/configs/configSlice';
import forgotPasswordReducer from '../features/forgotPassword/redux/forgotPasswordSlice';

export const rootReducer = combineReducers({
  authReducer,
  timesheetReduce,
  userReducer,
  projectReducer,
  requestReducer,
  layoutReducer,
  paginateReduce,
  configReduce,
  forgotPasswordReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['layoutReducer'],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
