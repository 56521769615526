export const BAD_REQUEST = {
  INVALID_PASSWORD: 'Invalid password',
  INVALID_TOKEN: 'Invalid token session. Please login again.',
  TOKEN_DENIED: 'Token denied',
  INVALID_EMAIL_KEY: 'Invalid email or key',
  EXPIRED_PASSWORD: 'Expired reset password request',
  USERNAME_NOTFOUND: `Username not found. Please contact admin to register.`,
  EXISTED_USERNAME: 'Username has already existed',
  ERROR_USERNAME_OR_EMAIL: 'Enter valid username or email!',
  ERROR_PASSWORD: 'Wrong password. Try again!',
  WORKING_TYPE_REQUIRED: 'Please choose your working type',
  WORKING_HOURS_REQUIRED: 'Please fill your working hours',
  WORKING_HOURS_ACCEPTABLE: 'Only 0,5h - 8h is acceptable',
  INCORRECT_FORMAT: 'Incorrect format',
  NOT_ENOUGH_CHARACTERS: 'You must type more than 10 characters',
  LEAVE_TYPE_REQUIRED: 'Please choose your leave type',
  LEAVE_REASON_REQUIRED: 'Please state your reason',
  DATE_REQUIRED: 'Please fill this date',
  INCORRECT_EMAIL_FORMAT: 'Incorrect email format',
  INVALID_IMAGE_SIZE: 'File too large !',
};
export const FORBIDDEN = 'Forbidden';
export const FIELD_REQUIRED = '⚠This field is required';
export const MESSAGES = {
  DELETE_SUCCESSFULLY: 'Delete successfully',
  DELETE_FAILED: 'Delete failed',
  CREATE_SUCCESSFULLY: 'Create successfully',
  CREATE_FAILED: {
    NORMAL_MESSAGE: 'Create failed',
    DUPLICATED_MESSAGE: 'Duplicated username or email. Please choose different username or email',
  },
  UPDATE_SUCCESSFULLY: 'Update successfully',
  UPDATE_FAILED: 'Update failed',
  SEND_EMAIL_SUCCESSFULLY: 'Send email successfully',
  RESET_PASSWORD_SUCCESSFULLY: 'Reset password successfully',
  RESET_PASSWORD_FAILED: 'You have used an expired link. Please check with HR',
};

export const PASSWORD_INCORRECT = 'Password is not the same. Please retype your new password.';
