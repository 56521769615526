export enum Positions {
  FE = 'FE Developer',
  BE = 'BE Developer',
  TESTER = 'Tester',
  PM = 'Project Manager',
  HR = 'HR',
  ADMIN = 'Admin',
  EMPLOYEE = 'Employee',
}
export enum StatusEmployeeEnum {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DEACTIVATED = 'Deactivated',
}

export enum TimesheetStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum InitialStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum WorkingType {
  NORMAL = 'Normal working hours',
  OT = 'Overtime (OT)',
  BONUS = 'Bonus',
}

export enum ButtonActionsType {
  SAVE = 'Save',
  CANCEL = 'Cancel',
  BACK = 'Back',
  NEXT = 'Next',
}

export enum StatusSearch {
  mentor = 'Mentor',
  level = 'Level',
  position = 'Position',
  project = 'Project',
}

export enum searchName {
  level = 'level',
  position = 'position',
  workingType = 'workingType',
  status = 'status',
}
export type TimeSheetStatusT = keyof typeof TimesheetStatus;

export interface configStateI {
  _id: string;
  value: string;
  key: string;
}
export enum ProjectStatusEnum {
  INCOMING = 'INCOMING',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
}
export interface IException {
  response: { [key: string]: string };
}
export interface ErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}
