import { GridProps, Grid, Stack } from '@mui/material';
import React, { Fragment } from 'react';
import ListTimeSheetComponent from './listTimesheetComponent';
import './style.scss';
import TimeActionScreen from './timeActionScreen';
import ListWeekScreen from './listWeekScreen';
import { caculateWeeklyNow, formatDate } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import NotFound from '../../components/notFound';
export function Item(props: GridProps) {
  const { sx, ...other } = props;
  return (
    <Grid
      item
      xs={12}
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
        borderRadius: 2,
        fontWeight: '700',
        height: 66,
        textAlign: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        ...sx,
      }}
      {...other}
    />
  );
}
const TimeScheetScreen: React.FC = () => {
  const { startWeek, listTimeSheet } = useSelector((state: RootState) => state.timesheetReduce);
  const keys = Object.keys(listTimeSheet);

  const isExistData = !!Object.values(listTimeSheet).filter((r) => !!r).length;

  const ItemsMap = (
    <>
      {keys.map((k) => (
        <Grid item container key={k} direction={'column'}>
          <Fragment>
            {(listTimeSheet[k]?.items ?? []).map((item, index) => {
              return (
                <ListTimeSheetComponent
                  key={index}
                  details={item.details}
                  hours={item.hours}
                  projectName={item.projectName}
                  status={item.status}
                  type={item.type}
                />
              );
            })}
          </Fragment>
        </Grid>
      ))}
    </>
  );

  return (
    <Fragment>
      <Stack className="container-stack" sx={styles.container}>
        <Grid item pt={1} xs={12}>
          <TimeActionScreen />
        </Grid>
        <Grid container direction="row" sx={styles.list_week} alignItems={'center'}>
          {keys.map((k, i) => (
            <ListWeekScreen
              key={k}
              weekName={k}
              date={formatDate(caculateWeeklyNow(i, startWeek, 'day').now, 'DD/MM/YYYY')}
              hours={listTimeSheet[k]?.total ?? 0}
            />
          ))}
        </Grid>
        <Grid
          container
          direction="row"
          sx={{
            ...styles.list_time_sheet,
            ...(isExistData ? { gridTemplateColumns: 'repeat(7, 2fr)' } : { alignItems: 'center' }),
          }}
          alignItems={'center'}
        >
          {isExistData ? ItemsMap : <NotFound name="No data" />}
        </Grid>
      </Stack>
    </Fragment>
  );
};

export default TimeScheetScreen;
const styles = {
  container: {
    '& > *': {
      pl: 3,
      pr: 3,
    },
  },
  list_time_sheet: {
    display: 'grid',
    margin: '9px 0',
    gap: '5px',
    width: '100%',
    maxHeight: '100vh',
    height: '100vh',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.15em',
      maxHeight: '25%',
      height: '25%',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    alignItems: 'flex-start',
  },
  list_week: {
    display: 'grid',
    pt: 2,

    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '5px',
    width: '100%',
    textAlign: 'center',
  },
};
