import { Box, Button, Grid, InputBaseComponentProps, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Icons from 'constants/icons';
import { IconPlus } from '@tabler/icons';
import ControllerDateField from 'components/inputDate';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import ControllerSelect from 'components/inputFromSelect';
import ControllerTextFied from 'components/inputTexField';
import DialogComponent from 'components/dialog';
import { canAcceptPermission, formatDate } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from 'store/store';
import { addWeekly } from './redux/timesheetSlice';
import { IKeyState } from '../../constants/interfaces';
import {
  canAddWorkingHours,
  requiredDetailsRules,
  requiredProjectRules,
  requiredWorkingHoursRules,
  requiredWorkingTypeRules,
  validateFieldNameRequired,
} from '../../constants/rules';
import { createTimesheetAsync, getTimesheetsAsync, TimeSheetFormState } from './redux/actions';
import useDebounce from '../../hooks/useDebounce';
import icons from 'constants/icons';
import WorkingHoursInput from '../../components/input/workingHoursInput';
import { BAD_REQUEST } from '../../constants/constants.message';
import BaseLoading from '../../components/Loading';
import { getProjectByPm, getProjectByUser } from '../Projects/redux/actions';
import { Positions } from '../../constants/constants.interfaces';

const dateNow = new Date();
const DialogComponents = ({
  name,
  isOpen,
  setOpen,
  callback,
}: {
  name: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  callback: () => void;
}) => {
  const methods = useFormContext<TimeSheetFormState>();
  const { setValue, reset, clearErrors, setError, resetField } = methods;
  const configsState = useAppSelector((state) => state.configReduce);
  const projectsState = useAppSelector((state) => state.projectReducer);
  const { positions } = useAppSelector((state) => state.authReducer);
  const [hours, setHours] = useState<number>(0);

  const handleChangeWorkingHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    const decimalValue = +event.target.value;
    setHours(decimalValue);

    if (!event.target.value) {
      resetField('hours', { defaultValue: 0 });
      return setError('hours', { message: BAD_REQUEST.WORKING_HOURS_REQUIRED });
    }

    if (!canAddWorkingHours(decimalValue)) {
      resetField('hours', { defaultValue: decimalValue });
      return setError('hours', { message: BAD_REQUEST.WORKING_HOURS_ACCEPTABLE });
    }

    clearErrors('hours');
    setValue('hours', decimalValue);
  };
  const clearState = () => {
    setOpen(false);
    setHours(0);
    reset();
  };

  const [projectSearch, setSearchProject] = useState<string>('');
  const searchByProject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProject(event.target.value);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    canAcceptPermission(positions, [Positions.PM])
      ? dispatch(getProjectByPm())
      : dispatch(getProjectByUser());
  }, [dispatch, positions]);

  return (
    <DialogComponent
      title={name}
      isOpen={isOpen}
      setOpen={() => {}}
      dialogAction={
        <Box className="style-button-popup">
          <Button
            className="buttonTextTitle-color-black"
            variant="outlined"
            sx={style.cancelButton}
            onClick={() => {
              clearState();
            }}
          >
            Cancel
          </Button>
          <Button
            className="buttonTextTitle-color-paper"
            variant="contained"
            sx={style.submitButton}
            onClick={() => {
              callback();
            }}
          >
            Submit
          </Button>
        </Box>
      }
      dialogBody={
        <Grid container flexDirection={'column'}>
          <Grid item>
            <ControllerDateField
              size="medium"
              name="date"
              label="Date *"
              rules={validateFieldNameRequired('Date')}
            />
          </Grid>
          <Grid item>
            <ControllerSelect
              name="project"
              label="Project *"
              value={projectSearch}
              onChange={searchByProject}
              rules={requiredProjectRules}
              onInputChange={(_, values: string) => {
                setSearchProject(values);
              }}
              options={projectsState.projectList.map((item) => ({
                key: item._id,
                value: item.projectName,
              }))}
              popupIcon={<icons.KeyboardArrowDownIcon />}
              getOptionLabel={(option) => (option as IKeyState).value || ''}
            />
          </Grid>
          <Grid item container flexDirection={'row'} spacing={2} alignItems="center">
            <Grid item xs={6}>
              <ControllerSelect
                name="type"
                label="Working type *"
                rules={requiredWorkingTypeRules}
                options={configsState.listWorkingType.map((item) => ({
                  key: item._id,
                  value: item.value,
                }))}
                popupIcon={<icons.KeyboardArrowDownIcon />}
                getOptionLabel={(option) => (option as IKeyState).value || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <ControllerTextFied
                rules={requiredWorkingHoursRules}
                name="hours"
                label="Working hours *"
                size="medium"
                variant="standard"
                value={hours}
                onChange={handleChangeWorkingHours}
                InputProps={{
                  inputComponent:
                    WorkingHoursInput as unknown as React.ElementType<InputBaseComponentProps>,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item>
            <ControllerTextFied
              name="details"
              label="Description *"
              multiline
              rows={3}
              rules={requiredDetailsRules}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const TimeActionScreen = () => {
  const form = useForm<TimeSheetFormState>({
    defaultValues: {
      date: dateNow,
      type: '',
      project: '',
      hours: 0,
      details: '',
    },
  });
  const dispatch = useAppDispatch();

  const { endWeek, startWeek } = useSelector((state: RootState) => state.timesheetReduce);
  const [numberWeek, setNumberWeek] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);
  const isLoading = useAppSelector((state) => state.timesheetReduce.isLoading);

  const handleAction = useCallback(
    (value: number) => {
      dispatch(addWeekly(value));
      setNumberWeek(value);
    },
    [dispatch],
  );
  const debouncedNumber = useDebounce<number>(numberWeek, 500);

  useEffect(() => {
    handleAction(0);
  }, [handleAction]);

  useEffect(() => {
    if (typeof debouncedNumber === 'number') {
      dispatch(getTimesheetsAsync(debouncedNumber));
    }
  }, [debouncedNumber, dispatch]);

  const btnAction = (isNext: boolean) => {
    if (isNext) {
      handleAction(numberWeek + 1);
      return;
    }
    handleAction(numberWeek - 1);
  };

  const { handleSubmit, reset } = form;
  const onSubmit = handleSubmit((data: TimeSheetFormState) => {
    dispatch(createTimesheetAsync(data));
    setOpen(false);
    reset();
    handleAction(0);
  });

  const debouncedClose = useDebounce<boolean>(isOpen, 500);

  useEffect(() => {
    if (!debouncedClose) {
      reset();
    }
  }, [debouncedClose, reset]);

  return (
    <>
      {isLoading ? (
        <>
          <Box className="container-loading">
            <BaseLoading size={60} />
          </Box>
        </>
      ) : (
        <>
          <FormProvider {...form}>
            <Stack spacing={2} direction="row" padding="16px 0">
              <Grid
                item
                container
                justifyContent={'flex-start'}
                sx={{
                  display: 'flex',
                }}
              >
                <Button variant="outlined" className="button" onClick={() => btnAction(false)}>
                  <Icons.LeftIcon />
                </Button>
                <Button variant="outlined" className="buttonTime">
                  <Typography className="buttonText">
                    {formatDate(startWeek)} - {formatDate(endWeek)}
                  </Typography>
                </Button>
                <Button variant="outlined" className="button" onClick={() => btnAction(true)}>
                  <Icons.RightIcon />
                </Button>
                <Button
                  variant="contained"
                  className="buttonShowToday"
                  onClick={() => handleAction(0)}
                >
                  <Typography className="buttonTextTitle-color-paper">Today</Typography>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                }}
              >
                <Button variant="contained" className="button-add" onClick={() => setOpen(true)}>
                  <IconPlus />
                  <Typography className="button-add-p">Add task</Typography>
                </Button>
              </Grid>
              <DialogComponents
                name="Add task"
                isOpen={isOpen}
                setOpen={setOpen}
                callback={onSubmit}
              />
            </Stack>
          </FormProvider>
        </>
      )}
    </>
  );
};
const style = {
  submitButton: {
    background: '#F8A404',
    marginLeft: '20px',
  },
  cancelButton: { border: '1px solid #949494' },
};
export default TimeActionScreen;
