import { createSlice } from '@reduxjs/toolkit';
import { resetPasswordAuth, sendEmailForgotPasswordAuth } from './action';
import toastConfig from 'utils/toastConfig';
import { ErrorResponse } from 'constants/constants.interfaces';
import { MESSAGES } from '../../../constants/constants.message';

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPasswordAuth.fulfilled, (state, action) => {
      toastConfig.toastSuccess(`${MESSAGES.RESET_PASSWORD_SUCCESSFULLY}`);
    });
    builder.addCase(resetPasswordAuth.rejected, (state, action) => {
      toastConfig.toastError(`${MESSAGES.RESET_PASSWORD_FAILED}`);
    });
    builder.addCase(sendEmailForgotPasswordAuth.fulfilled, (state, action) => {
      toastConfig.toastSuccess(`${MESSAGES.SEND_EMAIL_SUCCESSFULLY}`);
    });
    builder.addCase(sendEmailForgotPasswordAuth.rejected, (state, action) => {
      toastConfig.toastError((action.payload as ErrorResponse).message);
    });
  },
});

export default forgotPasswordSlice.reducer;
