import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../store/store';
import { DefaultLeaveValueI, ILeaveProps } from '../interfaces';
import { createRequestLeave, ICreateRequestLeave } from '../redux/actions';
import BaseButton from 'components/button';
import ControllerDateField from '../../../components/inputDate';
import {
  requiredDate,
  requiredLeaveReasonRules,
  requiredLeaveType,
} from '../../../constants/rules';
import ControllerSelect from '../../../components/inputFromSelect';
import ControllerTextFied from '../../../components/inputTexField';
import { IKeyState } from '../../../constants/interfaces';
import './style.scss';
import { ILeaveRequestResponseData } from '../../../types/dayOffType';
import { formatDate, formatISODefault } from '../../../constants';

const options: IKeyState[] = [
  { key: 'Morning', value: 'Morning' },
  { key: 'Afternoon', value: 'Afternoon' },
  { key: 'Full day', value: 'Full day' },
];

export enum LeaveTypeE {
  MORNING = 'Morning',
  AFTENOON = 'Afternoon',
  FULL_DAY = 'Full day',
  VIEW_LEAVE = 'View Leave',
}
interface LeaveFormI extends ILeaveProps {
  date?: string;
  defaultValue?: ILeaveRequestResponseData;
}
const LeaveForm = ({
  handleCancel,
  textViewModel = 'Request Leave',
  cancelText = 'Cancel',
  submitText = 'Submit',
  date,
  defaultValue,
}: LeaveFormI) => {
  const dispatch = useAppDispatch();
  const [leaveType, setLeaveType] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const toDay = new Date().toISOString();

  const formDefaultValue = {
    defaultValues: {
      type: '',
      fromDay: '',
      toDay: '',
      reason: '',
    },
  };

  const methods = useForm<DefaultLeaveValueI>(formDefaultValue);

  const { handleSubmit, setValue, reset } = methods;
  const handleReset = () => {
    methods.reset();
    handleCancel();
  };

  const handleItemLeaveType = (item?: ILeaveRequestResponseData) => {
    if (item && item.isMorning) return LeaveTypeE.MORNING;
    else if (item && item.isAfternoon) return LeaveTypeE.AFTENOON;
    return LeaveTypeE.FULL_DAY;
  };

  const handleDefaultLeaveType = () => {
    if (defaultValue) return { key: leaveType, value: handleItemLeaveType(defaultValue) };
    return;
  };

  useEffect(() => {
    if (defaultValue) {
      handleItemLeaveType(defaultValue);
      reset({
        reason: defaultValue.reason,
        fromDay: formatDate(defaultValue.fromDay as Date, formatISODefault),
        toDay: formatDate(defaultValue.toDay as Date, formatISODefault),
      });
    }
  }, []);

  const onSubmit = (data: DefaultLeaveValueI) => {
    const payload: ICreateRequestLeave = {
      isMorning: data.type === LeaveTypeE.MORNING,
      isAfternoon: data.type === LeaveTypeE.AFTENOON,
      fromDay: data.fromDay,
      toDay: data.toDay,
      reason: data.reason,
      date: date ?? toDay,
    };
    dispatch(createRequestLeave(payload));
    handleReset();
  };

  const handleChangeLeaveType = (value: string) => {
    setLeaveType(value);
  };

  return (
    <FormProvider {...methods}>
      <Box className="leave-form-header">
        <h2 className="leave-form-title">{textViewModel}</h2>
      </Box>
      <Box className="leave-form-container">
        <ControllerSelect
          name="type"
          label="Type"
          size="small"
          options={options}
          rules={requiredLeaveType}
          getOptionLabel={(options) => (options as IKeyState).value ?? ''}
          onInputChange={(_event: React.SyntheticEvent<Element, Event>, values: string) => {
            handleChangeLeaveType(values);
          }}
          defaultValues={handleDefaultLeaveType()}
          disabled={!!defaultValue}
        />
        <Grid className="inputFirstAndLastName" container spacing={2}>
          <Grid item xs={6}>
            <ControllerDateField
              rules={requiredDate}
              name="fromDay"
              label="Start Date"
              maxDate={endDate}
              onChange={(
                value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & Date,
              ) => {
                setStartDate(value);
                setValue('fromDay', String(value));
              }}
              disabled={!!defaultValue}
              isBlurWeekend={true}
            />
          </Grid>
          <Grid item xs={6}>
            <ControllerDateField
              rules={requiredDate}
              minDate={startDate}
              name="toDay"
              onChange={(
                value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> & Date,
              ) => {
                setEndDate(value);
                setValue('toDay', String(value));
              }}
              label="End Date"
              disabled={!!defaultValue}
              isBlurWeekend={true}
            />
          </Grid>
        </Grid>
        <ControllerTextFied
          rows={6}
          multiline
          rules={requiredLeaveReasonRules}
          name="reason"
          label="Descriptions"
          disabled={!!defaultValue}
        />
      </Box>
      <Box className="footerUser">
        {textViewModel === LeaveTypeE.VIEW_LEAVE ? (
          <Box className="btnNextOrSave">
            <Button className="btnButton" onClick={handleReset}>
              {cancelText}
            </Button>
          </Box>
        ) : (
          <>
            <Button className="btnCancelOrBack" onClick={handleReset}>
              {cancelText}
            </Button>
            <Box className="btnNextOrSave">
              <Button className="btnButton" onClick={handleSubmit(onSubmit)}>
                {submitText}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </FormProvider>
  );
};

export default LeaveForm;
