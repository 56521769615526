import React from 'react';
import '../../layouts/Sidebar/components/NavCollapse/style.scss';

interface StyleProps {
  style: React.CSSProperties;
}

const TimeTracking = (props: StyleProps) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-time-tracking"
      style={{ ...props.style, paddingLeft: '2px' }}
    >
      <path d="M14.9167 12.4523H16.7292V15.9337L19.6775 17.6744L18.7713 19.2793L14.9167 17.0077V12.4523ZM2.83335 0.106812H19.75C20.391 0.106812 21.0056 0.366947 21.4589 0.829991C21.9121 1.29303 22.1667 1.92106 22.1667 2.5759V10.1066C23.665 11.6621 24.5834 13.7979 24.5834 16.1559C24.5834 18.4478 23.6922 20.6459 22.106 22.2666C20.5197 23.8872 18.3683 24.7977 16.125 24.7977C13.8171 24.7977 11.7267 23.8594 10.2042 22.3286H2.83335C2.19241 22.3286 1.57773 22.0685 1.12451 21.6054C0.671299 21.1424 0.416687 20.5144 0.416687 19.8595V2.5759C0.416687 1.92106 0.671299 1.29303 1.12451 0.829991C1.57773 0.366947 2.19241 0.106812 2.83335 0.106812ZM2.83335 16.1559V19.8595H8.47627C7.95669 18.7361 7.66669 17.4769 7.66669 16.1559H2.83335ZM2.83335 7.51408H10.0834V3.81044H2.83335V7.51408ZM19.75 7.51408V3.81044H12.5V7.51408H19.75ZM2.83335 13.6868H8.0171C8.42794 12.2671 9.18919 10.9955 10.2042 9.98317H2.83335V13.6868ZM16.125 10.1683C14.5707 10.1683 13.0801 10.7992 11.9811 11.9221C10.882 13.0449 10.2646 14.5679 10.2646 16.1559C10.2646 19.4645 12.8867 22.1434 16.125 22.1434C16.8946 22.1434 17.6567 21.9886 18.3677 21.6877C19.0787 21.3868 19.7248 20.9457 20.269 20.3897C20.8132 19.8337 21.2448 19.1737 21.5393 18.4472C21.8339 17.7208 21.9854 16.9422 21.9854 16.1559C21.9854 12.8473 19.3634 10.1683 16.125 10.1683Z" />
    </svg>
  );
};
export default TimeTracking;
