import { SearchOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  InputAdornment,
  ClickAwayListener,
  TextField,
  Typography,
} from '@mui/material';
import avatarUser from 'assets/img/avatar.png';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import DeleteIcon from '../../../../constants/icons/DeleteIcon';
import { IKeyState } from '../../../../constants/interfaces';
import { searchByUser } from '../../../../features/Users/redux/actions';
import useDebounce from '../../../../hooks/useDebounce';
import { AppDispatch, useAppSelector } from '../../../../store/store';
import { IUserInfo } from '../../../../types/userType';
import { setBreadcrums, setCurrentMenuState } from '../../../Sidebar/redux/layoutSlice';
import './style.scss';

const SearchEmployee = () => {
  const [valueSearch, setValueSearch] = React.useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const { listEmployeeBySearch } = useAppSelector((state) => state.userReducer);
  const [openDropdown, setOpenDropdown] = React.useState(false);

  const debounceTextSearch = useDebounce<string>(valueSearch, 1000);

  const limitSearchByUserName = 5;

  useEffect(() => {
    dispatch(searchByUser({ limit: limitSearchByUserName, search: debounceTextSearch }));
  }, [dispatch, debounceTextSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
    if (event.target.value !== '') {
      setOpenDropdown(true);
    }
  };

  const handleClearValueSearch = () => {
    setValueSearch('');
    setOpenDropdown(false);
  };
  const handleClearListSearch = () => {
    setOpenDropdown(false);
  };

  const handleNextPageToViewEmployees = useCallback(() => {
    dispatch(setBreadcrums(''));
    dispatch(setCurrentMenuState(true));
    handleClearValueSearch();
  }, [dispatch]);

  const DropdownComponent = () => {
    return (
      <Box className="list-users">
        {listEmployeeBySearch.length === 0 ? (
          <ul>
            <li style={style.li}>No results found</li>
          </ul>
        ) : (
          <>
            {listEmployeeBySearch.map((item: IUserInfo) => (
              <ul style={style.ul_userList} key={item._id}>
                <Link to={`/employee/view/${item._id}`} className="style-link-view">
                  <Box onClick={handleNextPageToViewEmployees}>
                    <li className="user">
                      <Avatar src={item.avatar?.src || avatarUser} style={style.avatar} />
                      <Box>
                        <Typography style={style.Typography}>{item.fullName}</Typography>
                        <Box>
                          {item.positions.map((position: IKeyState, index: number) => (
                            <>
                              {position.value}
                              {index === item.positions.length - 1 ? '' : ', '}
                            </>
                          ))}
                        </Box>
                      </Box>
                    </li>
                  </Box>
                </Link>
              </ul>
            ))}
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <TextField
        style={style.TextField}
        size="small"
        onChange={handleSearch}
        label="Search"
        value={valueSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {openDropdown ? (
                <ClickAwayListener onClickAway={handleClearListSearch}>
                  <Box onClick={handleClearValueSearch} className="action-clear-input">
                    <DeleteIcon />
                  </Box>
                </ClickAwayListener>
              ) : (
                <SearchOutlined />
              )}
            </InputAdornment>
          ),
        }}
      />
      {openDropdown ? <DropdownComponent /> : <></>}
    </>
  );
};

const style = {
  TextField: {
    minWidth: '500px',
  },
  avatar: {
    width: '42px',
    margin: '16px 26px 16px 16px',
  },
  li: {
    listStyleType: 'none',
  },
  Typography: {
    fontWeight: '700',
  },
  ul_userList: { margin: '0', padding: '0' },
};

export default SearchEmployee;
