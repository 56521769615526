import Header from 'layouts/Header';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { DRAWER_WIDTH } from 'constants/layoutConstants';
import Sidebar from 'layouts/Sidebar';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { setCurrentMenuState } from 'layouts/Sidebar/redux/layoutSlice';
import Content from './Body';

interface IMainProps {
  theme: Theme;
  open: boolean;
}

interface IMainLayoutProps {
  children?: JSX.Element;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: IMainProps) => ({
    backgroundColor: 'white',
    marginTop: '20px',
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: -(DRAWER_WIDTH - 20),
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        padding: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        padding: '16px',
        marginRight: '10px',
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    }),
  }),
);

const MainLayout = ({ children }: IMainLayoutProps) => {
  const theme = useTheme();

  // Handle left drawer
  const leftDrawerOpened = useSelector((state: RootState) => state.layoutReducer.opened);

  const dispatch = useDispatch<AppDispatch>();
  const handleLeftDrawerToggle = () => {
    dispatch(setCurrentMenuState(!leftDrawerOpened));
  };

  return (
    <Box>
      <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
      <Box style={{ display: 'inline-flex', width: '-webkit-fill-available' }}>
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
        <Content children={children} />
      </Box>
    </Box>
  );
};

export default MainLayout;
