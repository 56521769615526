import React from 'react';

interface StyleProps {
  style?: React.CSSProperties;
}

const TimerIcon = (props: StyleProps) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      <path
        d="M6.99334 0.833496C3.31334 0.833496 0.333344 3.82016 0.333344 7.50016C0.333344 11.1802 3.31334 14.1668 6.99334 14.1668C10.68 14.1668 13.6667 11.1802 13.6667 7.50016C13.6667 3.82016 10.68 0.833496 6.99334 0.833496ZM7.00001 12.8335C4.05334 12.8335 1.66668 10.4468 1.66668 7.50016C1.66668 4.5535 4.05334 2.16683 7.00001 2.16683C9.94668 2.16683 12.3333 4.5535 12.3333 7.50016C12.3333 10.4468 9.94668 12.8335 7.00001 12.8335Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        d="M7.33334 4.16683H6.33334V8.16683L9.83334 10.2668L10.3333 9.44683L7.33334 7.66683V4.16683Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default TimerIcon;
