import React, { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';

import { NameTablesE } from '../../types/formProjectAndUserType';
import { useLocation } from 'react-router-dom';
import { PATH } from '../../constants/path';

interface HeaderPropsI {
  children?: JSX.Element;
}
const Header: React.FC<HeaderPropsI> = (props: HeaderPropsI) => {
  const { pathname } = useLocation();

  const handleBreadcrumbs = () => {
    let breadcrumb = '';
    switch (pathname) {
      case PATH.CALENDAR:
        breadcrumb = NameTablesE.CALENDAR;
        break;
      case PATH.APPROVALDS_LEAVE:
        breadcrumb = NameTablesE.FOR_APPROVALS;
        break;
      case PATH.APPROVALDS_TIMETRACKING:
        breadcrumb = NameTablesE.FOR_APPROVALS;
        break;
      case PATH.TIMESHEET:
        breadcrumb = NameTablesE.MY_TIMESHEET;
        break;
      case PATH.REPORTS:
        breadcrumb = NameTablesE.REPORTS;
        break;
      default:
        break;
    }
    return (
      <Typography
        style={{
          fontSize: 30,
          fontWeight: 700,
        }}
      >
        {breadcrumb}
      </Typography>
    );
  };
  return (
    <Fragment>
      <Grid container ml={1} mb={2} spacing={2}>
        {handleBreadcrumbs()}
      </Grid>
    </Fragment>
  );
};
export default Header;
