import React, { useEffect, useState } from 'react';
import ListApprovalTreeComponent from './ListApprovalTreeComponent';
import ApprovalHeaderComponent from './ApprovalHeaderComponent';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { Box } from '@mui/material';
import BaseLoading from '../../../components/Loading';
import {
  approveLeaveAsync,
  ERequestStatus,
  getUsersMonthlyLeave,
  rejectLeaveAsync,
} from '../redux/actions';
import { formatDate } from '../../../constants';
import moment from 'moment';
import { formatYYYYMMDD } from '../../../constants/constants.timeFormat';
export interface ApprovalLeaveChildrenProps {
  _id: string;
  status: {
    key: string;
    value: string;
  };
  toDay: string;
  reason: string;
  fromDay: string;
  createdDate: string;
  isAfternoon: boolean;
  isMorning: boolean;
}
export type ApprovalLeaveDataProps = {
  _id: string;
  fullName: string;
  avatar: string;
  children: ApprovalLeaveChildrenProps[];
} & Partial<ApprovalLeaveChildrenProps>;
interface ApprovalProps {}

const ApprovalsLeaveScreen: React.FC<ApprovalProps> = () => {
  const dispatch = useAppDispatch();
  const countRender = 0;
  const { isLoading, usersMonthlyLeave, approvalDateMOY } = useAppSelector(
    (state) => state.requestReducer,
  );

  const converDateToIso = moment(approvalDateMOY).toISOString();

  const [currentStatus, setCurrentStatus] = useState<string>(ERequestStatus.PENDING);
  const handleUpdateLeaveStatus = async (ids: string[], isApproval: boolean) => {
    if (isApproval) {
      await dispatch(approveLeaveAsync(ids));
    } else {
      await dispatch(rejectLeaveAsync(ids));
    }

    return dispatch(getUsersMonthlyLeave({ date: converDateToIso, status: currentStatus }));
  };

  useEffect(() => {
    dispatch(
      getUsersMonthlyLeave({
        date: formatDate(moment(), formatYYYYMMDD),
        status: ERequestStatus.PENDING,
      }),
    );
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Box className="container-loading">
          <BaseLoading size={60} />
        </Box>
      ) : (
        <>
          <ApprovalHeaderComponent setCurrentStatus={setCurrentStatus} />
          <ListApprovalTreeComponent
            itemsCB={usersMonthlyLeave}
            isParent={true}
            countRender={countRender}
            updateLeaveStatusCallback={handleUpdateLeaveStatus}
          />
        </>
      )}
    </>
  );
};

export default ApprovalsLeaveScreen;
