import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ControllerTextFied from '../../components/inputTexField';
import { validateFieldNameRequired } from '../../constants/rules';
import './style.scss';

import { useAppDispatch, useAppSelector } from '../../store/store';
import { changePassword } from '../Users/redux/actions';
import { PATH } from '../../constants/path';
import { logoutSuccess } from '../Login/redux/authSlice';
import { useNavigate } from 'react-router-dom';
import BaseLoading from '../../components/Loading';
import BasePopup from '../../components/popup';
import { PASSWORD_INCORRECT } from '../../constants/constants.message';

type Props = {};

export interface IChangePassword {
  currentPassword: string;
  password: string;
  confirmPassword?: string;
}
const ChangePassword = (props: Props) => {
  const [isCurrentPassword, setIsCurrentPassword] = useState<boolean>(false);
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { isLogout } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<IChangePassword>({
    defaultValues: {
      currentPassword: '',
      password: '',
    },
  });

  const { handleSubmit, setError } = methods;

  useEffect(() => {
    if (isLogout) {
      setLoading(true);
      setTimeout(() => {
        dispatch(logoutSuccess());
        navigate(PATH.LOGIN);
        setLoading(false);
      }, 3000);
    }
  }, [isLogout]);

  const onSubmit = handleSubmit((data: IChangePassword) => {
    if (data.password !== data.confirmPassword) {
      return setError('confirmPassword', {
        type: 'custom',
        message: PASSWORD_INCORRECT,
      });
    }
    return dispatch(
      changePassword({
        currentPassword: data.currentPassword,
        password: data.password,
      }),
    );
  });

  return (
    <div>
      {loading ? (
        <BasePopup
          openPopup={loading}
          paperProps={{
            style: {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          }}
          setOpenPopup={() => {}}
        >
          <div className="box-loading">
            <BaseLoading size={60} />
            <div className="text-loading">Change password successfully</div>
            <div className="text-loading">Give us a seconds, we going to log you out.</div>
          </div>
        </BasePopup>
      ) : (
        <>
          <Typography variant="h3" className="style-title-h2">
            Change Password
          </Typography>
          <Box className="style-content-change-password">
            <Box className="style-content-box-input">
              <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                  <ControllerTextFied
                    name="currentPassword"
                    rules={validateFieldNameRequired('Current Password')}
                    label="Current Password"
                    size="small"
                    className="inputTextfield"
                    type={isCurrentPassword ? 'text' : 'password'}
                    InputProps={{
                      className: 'login-input-props',
                      endAdornment: (
                        <InputAdornment className="login-icon-input" position="end">
                          <IconButton onClick={() => setIsCurrentPassword(!isCurrentPassword)}>
                            {isCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <ControllerTextFied
                    name="password"
                    rules={validateFieldNameRequired('New Password')}
                    label="New Password"
                    size="small"
                    className="inputTextfield"
                    type={isPassword ? 'text' : 'password'}
                    InputProps={{
                      className: 'login-input-props',
                      endAdornment: (
                        <InputAdornment className="login-icon-input" position="end">
                          <IconButton onClick={() => setIsPassword(!isPassword)}>
                            {isPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <ControllerTextFied
                    name="confirmPassword"
                    rules={validateFieldNameRequired('Confirm New Password')}
                    label="Confirm New Password"
                    size="small"
                    className="inputTextfield"
                    type={isConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      className: 'login-input-props',
                      endAdornment: (
                        <InputAdornment className="login-icon-input" position="end">
                          <IconButton onClick={() => setIsConfirmPassword(!isConfirmPassword)}>
                            {isConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <Box className="style-content-box-button">
                    <Button type="submit" className="style-content-button-submit">
                      Change Password
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default ChangePassword;
