import { TableCell, TableRow } from '@mui/material';
import React from 'react';

interface ITitleRow {
  title: string[];
}

const TitleRow = ({ title }: ITitleRow) => {
  return (
    <TableRow className="box-table-head">
      {title.map((title: string, index: number) => {
        return (
          <TableCell className="title-project" key={index}>
            {title}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TitleRow;
