import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BaseLoading from '../../../components/Loading';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getUserDetails } from '../redux/actions';
import './style.scss';
import ViewContent from './viewContent';
import ViewHeader from './viewHeader';

type Props = {};

const ViewEmployees = (props: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.userReducer.isLoading);

  useEffect(() => {
    id && dispatch(getUserDetails(id));
  }, [dispatch, id]);

  return (
    <>
      {isLoading ? (
        <Box className="container-loading">
          <BaseLoading size={60} />
        </Box>
      ) : (
        <Box className="viewContainer">
          <ViewHeader />
          <ViewContent />
        </Box>
      )}
    </>
  );
};

export default ViewEmployees;
