import React from 'react';
import ContentProject from './addContent';
import HeaderProject from './addHeader';

type Props = {};

const AddProject = (props: Props) => {
  return (
    <>
      <HeaderProject />
      <ContentProject />
    </>
  );
};

export default AddProject;
