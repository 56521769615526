import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import MenuList from './components/MenuList';
import { DRAWER_WIDTH } from 'constants/layoutConstants';
import { useMemo } from 'react';
import MAIN_MENU, { EMenuComponentType, MenuEnum } from 'constants/menuConstants';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IMenuVariables } from 'types/navigation';
import 'layouts/Sidebar/style.scss';
import { Positions } from 'constants/constants.interfaces';
import { canAcceptPermission } from '../../constants';

interface ISidebarProps {
  drawerOpen: boolean;
  drawerToggle: () => void;
}

const Sidebar = (_: ISidebarProps) => {
  const positions = useSelector((state: RootState) => state.authReducer.positions);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const MENU_PM = {
    id: MenuEnum.UTILITIES,
    type: EMenuComponentType.GROUP,
    children: MAIN_MENU.MENU.children,
  };

  const menuListItem: IMenuVariables[] = useMemo(() => {
    if (canAcceptPermission(positions, [Positions.ADMIN, Positions.HR])) {
      return [MAIN_MENU.MENU];
    } else if (canAcceptPermission(positions, [Positions.PM])) {
      return [MENU_PM];
    }
    return [MAIN_MENU.MENU_USER];
  }, [positions]);

  return (
    <Box
      className="box"
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? DRAWER_WIDTH : 'auto' }}
      aria-label="mailbox folders"
    >
      <Box component="div" className="sidebar-scrollbar">
        <MenuList menuItem={menuListItem} />
      </Box>
    </Box>
  );
};
export default Sidebar;
