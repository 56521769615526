type DateType = 'days' | 'months' | 'years';
export interface baseCalender {
  date: Date;
  num: number;
  type: DateType;
}
export enum NameTablesE {
  DASHBOARD = 'Dashboard',
  EMPLOYEES = 'Employees',
  PROJECT = 'Project',
  PROJECTS = 'Projects',
  MY_TIMESHEET = 'My Timesheet',
  FOR_APPROVALS = 'For Approvals',
  CALENDAR = 'Calendar',
  NameTablesE = 'NameTablesE',
  REPORTS = 'Reports',
}
