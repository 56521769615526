import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'services/api/api';
import { ILoginData } from 'types/loginType';
import { LOCAL_STORAGE_KEY, setItem } from 'utils/localStorage';
import { IException } from '../../Dayoff/redux/actions';

export const loginAuth = createAsyncThunk(
  'auth/loginAuth',
  async ({ username, password }: ILoginData, { rejectWithValue }) => {
    try {
      const response = await instance.post(`auth/login`, {
        username,
        password,
      });
      setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, response.data.access_token);
      setItem(LOCAL_STORAGE_KEY.CURRENT_ID, response.data.user_id);
      setItem(LOCAL_STORAGE_KEY.POSITIONS, response.data.positions);
      return response.data;
    } catch (error) {
      const errorResponse = (error as IException).response;
      if (!errorResponse) throw error;
      return rejectWithValue(errorResponse.data);
    }
  },
);
