import { Button, Grid, IconButton, InputAdornment, Stack, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import './style.scss';
import { PATH } from 'constants/path';
import { IResetPasswordFormValues, IResetPasswordData } from '../interface';
import { resetPasswordAuth } from '../../forgotPassword/redux/action';
import { validateFieldNameRequired } from '../../../constants/rules';
import icons from '../../../constants/icons';
import ControllerTextFied from '../../../components/inputTexField';
import { PASSWORD_INCORRECT } from '../../../constants/constants.message';
import ForgotPasswordBase from '../../forgotPassword/forgotPasswordBase';

const ChangePassword = () => {
  const form = useForm<IResetPasswordFormValues>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });
  const { handleSubmit, setError } = form;
  const { NavigateNext, Visibility, VisibilityOff } = icons;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState<boolean>(false);

  const { token } = useParams();

  const onSubmit = handleSubmit(async (data: IResetPasswordFormValues) => {
    if (data.newPassword !== data.confirmPassword) {
      return setError('confirmPassword', {
        type: 'custom',
        message: PASSWORD_INCORRECT,
      });
    }
    const res = await dispatch(
      resetPasswordAuth({ token: token, newPassword: data.newPassword } as IResetPasswordData),
    );
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(PATH.RESET_PASSWORD_SUCCESS);
    }
  });

  return (
    <ForgotPasswordBase>
      <Grid item className="change-password-information">
        <Stack alignItems="left" justifyContent="center" spacing={1}>
          <Typography variant="h1" fontSize="36px" fontWeight="700">
            New password
          </Typography>
          <Typography variant="body1" fontSize="14px">
            Now you can change your password
          </Typography>
        </Stack>
        <Box className="change-password-form">
          <FormProvider {...form}>
            <form onSubmit={onSubmit}>
              <ControllerTextFied
                name="newPassword"
                rules={validateFieldNameRequired('New Password')}
                label="New Password"
                size="small"
                className="inputTextfield"
                type={isPassword ? 'text' : 'password'}
                InputProps={{
                  className: 'change-password-input-props',
                  endAdornment: (
                    <InputAdornment className="change-password-icon-input" position="end">
                      <IconButton onClick={() => setIsPassword(!isPassword)}>
                        {isPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
              <ControllerTextFied
                name="confirmPassword"
                rules={validateFieldNameRequired('Confirm New Password')}
                label="Confirm New Password"
                size="small"
                className="inputTextfield"
                type={isConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  className: 'change-password-input-props',
                  endAdornment: (
                    <InputAdornment className="change-password-icon-input" position="end">
                      <IconButton onClick={() => setIsConfirmPassword(!isConfirmPassword)}>
                        {isConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />

              <Button type="submit" fullWidth variant="contained" className="button-submit">
                Confirm
                <NavigateNext />
              </Button>
            </form>
          </FormProvider>
        </Box>
      </Grid>
    </ForgotPasswordBase>
  );
};

export default ChangePassword;
