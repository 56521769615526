export const PATH = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset_password',
  CONFIRM_EMAIL: '/reset_password/confirm_email',
  RESET_PASSWORD_TOKEN: '/reset_password/:token',
  RESET_PASSWORD_SUCCESS: '/reset_password/success',
  RESET_PASSWORD_FAILED: '/reset_password/failed',
  HOME: '/',
  SESSION_EXPIRED: '/session_expired',
  DASHBOARD: '/dashboard',
  EMPLOYEES: '/employees',
  PROJECT: '/projects',
  PROFILE: '/profile',
  CONTACT: '/contact',
  CALENDAR: '/leave/my_calendar',
  APPROVALDS_LEAVE: '/leave/for_approvals',
  TIMESHEET: '/time_tracking/my_timesheet',
  APPROVALDS_TIMETRACKING: '/time_tracking/for_approvals',
  REPORTS: '/time_tracking/reports',
  EMPLOYEE_VIEW: '/employee/view/:id',
  PROJECT_VIEW: 'project/view/:id',
  EMPLOYEE_CREATE: '/employee/create',
  EMPLOYEE_UPDATE: '/employee/update/:id',
  PROJECT_CREATE: '/project/create',
  PROJECT_UPDATE: '/project/update/:id',
  CHANGE_PASSWORD: '/change_password',
};
