import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { Fragment } from 'react';
interface DialogProps {
  dialogAction: JSX.Element;
  dialogBody: JSX.Element;
  title: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}
const DialogComponent = (props: DialogProps) => {
  const { title, isOpen, setOpen, dialogAction, dialogBody } = props;
  return (
    <Dialog onClose={() => setOpen(false)} open={isOpen} maxWidth={'sm'} fullWidth={true}>
      <Fragment>
        <DialogTitle sx={style.titleDialog}>{title}</DialogTitle>
        <DialogContent
        // dividers
        >
          <Box sx={{ width: '100%' }}>{dialogBody}</Box>
        </DialogContent>
        <DialogActions>{dialogAction}</DialogActions>
      </Fragment>
    </Dialog>
  );
};
const style = {
  titleDialog: {
    border: '1px solid #ECEEF6',
    transform: 'rotate(-0.11deg)',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '18px',
  },
};
export default DialogComponent;
